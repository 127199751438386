import { Stack, Typography } from '@mui/material';
import { classes } from '../../../../settings/theme.js';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';
import useAuth from '../../../../hooks/useAuth.js';
import IndividualProfileField from '../../individual/IndividualProfileField.jsx';
import AddressField from '../../../address/AddressField.jsx';
import PhoneField from '../../PhoneField .jsx';

const PersonalDetailsForm = () => {
  // Translation
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.account.SignUpComplete.PersonalDetailsForm',
  });

  const { auth } = useAuth();

  const { values, errors, touched } = useFormikContext();

  return (
    <Stack spacing={4} style={classes.root} sx={{ paddingBottom: '30px' }}>
      {/* Address */}
      <AddressField
        fieldTitle={t('address.title')}
        fieldDescription={t('address.description')}
        labelID={values?.individual?.name}
        phoneFieldID="individual.phone"
        fieldID="individual.address"
        autocompleteID="individual.address"
        transNS="fields"
        transPrefix="AddressField"
        required={true}
      />
      {/* Phone */}
      <PhoneField
        fieldTitle={t('phone.title')}
        fieldDescription={t('phone.description')}
        fieldID="individual.phone"
        transNS="fields"
        transPrefix="account.individual.PhoneField"
        required={true}
      />
      {/* Professional Profile */}
      <IndividualProfileField
        fieldTitle={t('profile.title')}
        fieldDescription={t('profile.description')}
        required={
          auth.user_info.account_type === 'influencer' ||
          auth.user_info.account_type === 'salesrep'
        }
        disabled={values.individual?.linkedin?.id}
        showLinkedInButton={!values.individual?.linkedin?.id}
      />
    </Stack>
  );
};

export default PersonalDetailsForm;
