import { useContext, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import { Formik, useFormik, useFormikContext } from 'formik';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import { FormControl } from '@mui/base';
import { LoadingButton } from '@mui/lab';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import { ProfileIntroductionContext } from '../../context/providers/ProfileIntroContextProvider';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import useResponseHandling from '../../hooks/useResponseHandler';
import { formatMuiRichTextEditor } from '../../helpers/muiRichtTextEditor';

const validationSchema = Yup.object().shape({
  applications: Yup.array().min(1, i18n.t('validation.industry_min')),
  product_types: Yup.array().min(1, i18n.t('validation.product_min')),
  name: Yup.string().required(i18n.t('validation.name.required')),
  keywords: Yup.array().min(1, i18n.t('validation.keyword_min')),
  intro: Yup.string().required(i18n.t('validation.intro.text_required')),
});

const GenerateProfileIntroDialog = ({
  mode = '', // Takes individual or company
}) => {
  // Get Parent Values
  const {
    values: parentValues,
    setFieldValue: setParentFieldValues,
    setFieldTouched: setParentFieldtouched,
  } = useFormikContext();

  //   Get Parent Context
  const { introDialog, setIntroDialog } = useContext(
    ProfileIntroductionContext,
  );
  const theme = useTheme();
  const { t } = useTranslation('dialogs', {
    keyPrefix: 'GenerateProfileIntroDialog',
  });
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const axios = useAxiosPrivate();
  const [fetchIntro, setFetchIntro] = useState(false);
  const errRef = useRef(null);

  const initialValues = {
    _id: parentValues?._id,
    applications: parentValues?.applications,
    brands: parentValues?.brands,
    product_types: parentValues?.product_types,
    name: parentValues?.name || parentValues?.individual?.full_name,
    intro: '',
    keywords: [],
  };

  // Initial Formik
  const formik = useFormik({
    initialValues,
    initialTouched: {},
    values: {},
  });

  const getIntroText = async (values) => {
    if (!mode) {
      return window.alert(
        'Mode is not Defined, which must be of value company or individual',
      );
    }

    setFetchIntro(true);

    let industry = [];
    let product = [];
    let brand = [];
    let keyword = [];

    // Format Industries
    let applications = values?.applications;
    if (applications?.length > 0) {
      applications.map((application) => {
        industry.push(application.industry_name);
        return null;
      });
      industry = industry.join();
    }

    // Format Products
    let products = values?.product_types;
    if (products?.length > 0) {
      products.map((element) => {
        product.push(element.group_name);
        return null;
      });
      product = product.join();
    }

    // Format Brands
    let brands = values?.brands || [];
    if (brands?.length > 0) {
      brands.map((b) => {
        brand.push(b.brand);
        return null;
      });
      brand = brand?.join();
    }

    // Format keywords
    keyword = values.keywords.join();
    let messages = [];

    if (mode === 'individual') {
      messages = [
        {
          role: 'assistant',
          content: `Produce a profile description for ${values.name} using the voice of Tony Robbins in short using 150 words in three paragraphs writing in the "I" form. Include the following keywords ${keyword}`,
        },
      ];
    } else if (mode === 'company') {
      messages.push({
        role: 'assistant',
        content: `Produce a profile description for ${values.name} using the voice of Tony Robbins in short using 150 words in three paragraphs writing in the "we" form. Include the following keywords: ${keyword}`,
      });

      messages.push({
        role: 'assistant',
        content: `Search the internet for more information about the company ${values.name}.`,
      });

      if (brand.length > 0) {
        messages.push({
          role: 'assistant',
          content: `Mention the following brand(s) of the company: ${brand}`,
        });
      }
    }

    if (industry.length > 0) {
      messages.push({
        role: 'assistant',
        content: `Emphasize the expertise within the following industry/industries: ${industry}`,
      });
    }

    if (product.length > 0) {
      messages.push({
        role: 'assistant',
        content: `Emphasize the expertise within the following product categories: ${product}`,
      });
    }

    const payload = {
      messages,
      temperature: 0.2,
    };

    const url = '/api/chatgpt/get-sentence';

    try {
      const response = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      const { data, status } = response;

      if (status === 200) {
        const [messageData] = data.data;
        const message = messageData?.message?.content;

        return {
          statusCode: response.statusCode,
          status: response.status,
          data: message,
        };
      } else {
        handleRegularResponse({
          open: true,
          status: response.status,
          statusCode: response.statusCode,
          message: response.message,
        });
      }
    } catch (error) {
      handleErrorResponse(error);
      errRef.current?.focus();
      console.error('error', error);
    } finally {
      setFetchIntro(false);
    }
  };

  // Save to Profile
  const submitForm = (intro) => {
    if (!mode || !intro) {
      return;
    }

    const rteMessage = formatMuiRichTextEditor(intro);
    setParentFieldValues('intro', rteMessage);
    setParentFieldValues('intro_rte', rteMessage);

    setTimeout(() => {
      setParentFieldtouched('intro', true, true);
    });
    formik.resetForm();
  };

  const handleGetIntroText = async (values) => {
    const { data } = await getIntroText(values);
    formik.setFieldValue('intro', data, false);
    setIntroDialog(false);
    submitForm(data);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => submitForm(values)}
      validateOnChange={true}
      enableReinitialize={true}
      validateOnMount={true}
      validateOnBlur={true}
      initialErrors={{ keywords: true }}
    >
      {(formik) => {
        const {
          values,
          setFieldValue,
          errors,
          touched,
          handleBlur,
          isValid,
          resetForm,
          isSubmitting,
        } = formik;
        return (
          <FormControl>
            <Dialog maxWidth="md" open={introDialog}>
              <DialogTitle>
                {t('title', {
                  type: values.name,
                })}
              </DialogTitle>
              <DialogContent>
                <Stack spacing={1} sx={{ paddingBottom: '10px' }}>
                  <FormikFieldWrapper
                    fieldDescription={t('description', {
                      type: values.name,
                    })}
                  >
                    <Autocomplete
                      id="keywords"
                      name="keywords"
                      required
                      disabled={fetchIntro ? true : false}
                      options={[]}
                      freeSolo
                      multiple
                      className="form-select-field"
                      onBlur={handleBlur}
                      onChange={(e, options) => {
                        setFieldValue('keywords', options);
                      }}
                      sx={{ paddingBottom: '2rem' }}
                      clearOnBlur={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          margin="dense"
                          value={values?.keywords}
                          label={t('form.keywords.label')}
                          type="text"
                          variant="standard"
                          placeholder={t('form.keywords.placeholder')}
                          error={
                            errors?.keywords && touched?.keywords ? true : false
                          }
                          helperText={
                            errors?.keywords && touched?.keywords
                              ? errors?.keywords
                              : null
                          }
                        />
                      )}
                    />
                  </FormikFieldWrapper>
                  <FormikFieldWrapper
                    fieldTitle={t('form.intro.title', { type: values.name })}
                    fieldDescription={t('form.intro.description', {
                      type: values.name,
                    })}
                    transition={values?.intro ? true : false}
                  >
                    <TextField
                      multiline
                      disabled
                      minRows={3}
                      maxRows={6}
                      inputProps={{ style: { resize: 'vertical' } }}
                      id="intro"
                      name="intro"
                      aria-invalid={errors.intro ? 'true' : 'false'}
                      aria-describedby="uidnote"
                      variant="standard"
                      label={t('form.intro.label', {
                        type: values?.name,
                      })}
                      type="text"
                      placeholder={t('form.intro.placeholder', {
                        type: values?.name,
                      })}
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values?.intro}
                      error={errors.intro && touched.intro ? true : false}
                      helperText={
                        errors.intro && touched.intro ? errors.intro : null
                      }
                    />
                  </FormikFieldWrapper>
                </Stack>
                {/* <Typography variant="h1">Parent Values:</Typography>
                {JSON.stringify(parentValues)} */}

                {/* <Typography variant="h1">Initial Values:</Typography>
                {JSON.stringify(initialValues)} */}

                {/* <Typography variant="h1">Values:</Typography>
                {JSON.stringify(values)} */}

                {/* <Typography variant="h1">Touched:</Typography>
                {JSON.stringify(touched)} */}

                {/* <Typography variant="h1">Errors:</Typography>
                {JSON.stringify(errors)} */}

                {/* <Typography variant="h1">Valid:</Typography>
                {JSON.stringify(isValid)} */}
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  variant="contained"
                  color="error"
                  onClick={() => setIntroDialog(false)}
                >
                  {t('buttons.cancel')}
                </LoadingButton>
                {/* Generate Intro */}
                <LoadingButton
                  variant="contained"
                  disabled={
                    errors?.keywords || fetchIntro || isSubmitting
                      ? true
                      : false
                  }
                  endIcon={
                    <AutoFixHighIcon
                      sx={{
                        color:
                          errors?.keywords || fetchIntro || isSubmitting
                            ? theme.palette.action.disabled
                            : theme.palette.info.contrastText,
                      }}
                    />
                  }
                  loading={fetchIntro || isSubmitting}
                  loadingPosition="end"
                  color="info"
                  onClick={() => handleGetIntroText(values)}
                >
                  {t('buttons.getIntro')}
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </FormControl>
        );
      }}
    </Formik>
  );
};

export default GenerateProfileIntroDialog;
