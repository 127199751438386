import { useState, useEffect, useRef } from 'react';
import useAuth from '../../../../hooks/useAuth.js';
import { useAnalyticsEventTracker } from '../../../../hooks/useAnalyticsTracker.jsx';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../hooks/axios/useAxios.js';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Stack, FormControl, Grid } from '@mui/material';
import { classes } from '../../../../settings/theme.js';
import useResponseHandling from '../../../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../../../hooks/useProcessingHandler.js';
// Get Cookie Settings for Signing
import useUser from '../../../../hooks/useUser.js';
// Translator
import { useTranslation } from 'react-i18next';
import EmailField from '../../EmailField.jsx';
import EmailOTPField from '../../EmailOTPfield.jsx';
import MobileOTPField from '../../MobileOTPField.jsx';

function AccountConfirmForm() {
  const { gaEventTracker } = useAnalyticsEventTracker();
  const { setAuth } = useAuth();
  const { user } = useUser();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();
  const navigate = useNavigate();
  const [userAction, setUserAction] = useState('');
  const [userID, setUserID] = useState('');
  const [userOTP, setUserOTP] = useState('');
  const [mobile, setMobile] = useState('');
  const [fullName, setFullName] = useState('');
  const [encryptedOTP, setEncryptedOTP] = useState('');
  const [showEmailField, setShowEmailField] = useState(true);
  const [showRequestOTP, setShowRequestOTP] = useState(false);
  const { t } = useTranslation();

  // Setup Initial Values for Form Validation
  const initialValues = {
    email: userID,
    userOTP: userOTP,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email invalid',
      ),
    userOTP: Yup.string()
      .required(t('validation.otp.required'))
      .min(4, t('validation.short'))
      .max(4, t('validation.long')),
  });

  // Set Up From refs
  const errRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userAction = params.get('userAction');
    const userID = params.get('userID');
    const userOTPEncoded = params.get('userOTP');
    const mobileEncoded = params.get('mobile');
    const fullName = params.get('fullName');

    // Decoding the phone number
    const mobile = decodeURIComponent(mobileEncoded);
    const userOTP = userOTPEncoded && decodeURIComponent(userOTPEncoded);

    if (userID) {
      setUserID(userID);
    }

    if (userOTP) {
      if (userAction === 'userLogin') {
        setEncryptedOTP(userOTP);
      } else {
        setUserOTP(userOTP);
      }
    }

    if (userAction) {
      setUserAction(userAction);
      if (userAction === 'userLogin') {
        setShowEmailField(false);
        setFullName(fullName);
        setMobile(mobile);
        setShowRequestOTP(true);
      }
    }
  }, []);

  // Handle form submission process
  async function submitForm(values, formik) {
    const { email, userOTP } = values;
    let url;
    let payload;

    // Get Cookie Settings
    const authSettings = user?.cookieSettings?.auth;

    //create new user account
    if (userAction === 'accConfirm') {
      // User is Confirming the Account
      url = `/api/users/account/create/confirm`;
      payload = {
        email,
        otp: Number(userOTP),
        host_url: window.location.host,
        cookies: authSettings,
      };
    } else if (userAction === 'userChange') {
      // User is Changing Email/UserName
      url = `/api/users/account/change/email/confirm?userDashboard=true`;
      payload = {
        email,
        otp: Number(userOTP),
        host_url: window.location.host,
        cookies: authSettings,
      };
    } else if (userAction === 'userLogin') {
      // User Confirms Login
      url = `/api/verify/account/login/confirm`;
      payload = {
        email,
        otp: Number(userOTP),
        encryptedOTP,
        mobile_number: mobile,
        full_name: fullName,
      };
    }

    try {
      setIsLoading({
        status: true,
        type: 'spinner',
        text: t('form.message.confirm_acct'),
      });
      const response = await axios.put(url, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        withCredentials: true,
      });

      const { data, status } = response;

      if (status === 200) {
        gaEventTracker({
          category: 'Account Creation',
          action: 'Event',
          label: 'New Account Confirmed',
        });
        // Set User Auth State
        setAuth({
          auth_info: data.auth_info,
          user_info: data.user_info,
        });
        navigate(data.navigate_to, { replace: true });
      } else if (status === 240) {
        // Handle other regular Responses
        formik.resetForm();
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      } else {
        // Handle other regular Responses
        formik.resetForm();
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
        errRef.current?.focus();
        if (userAction === 'userLogin') {
          setEncryptedOTP(data.otp);

          // Replace the URL Search Params in the Browser Window
          const params = new URLSearchParams(window.location.search);
          params.delete('userOTP');
          params.append('userOTP', data.otp);

          // Construct the updated URL with the modified parameters
          const updatedUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`;

          // Replace the URL in the browser's address bar
          window.history.replaceState({}, '', updatedUrl);
        }
      }
    } catch (error) {
      handleErrorResponse(error);
      errRef.current?.focus();
      console.error('error', error);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
    }
  }

  async function requestNewOTP(formik) {
    const url = `/api/verify/account/login/request`;
    const payload = {
      userID: userID,
      userOTP: encryptedOTP,
    };

    try {
      setIsLoading({
        status: true,
        type: 'spinner',
        text: t('form.message.confirm_acct'),
      });
      const response = await axios.put(url, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        withCredentials: true,
      });

      const { data, status } = response;

      if (status === 200) {
        // Handle other regular Responses
        formik.resetForm();
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
        errRef.current?.focus();

        setEncryptedOTP(data.otp);

        // Replace the URL Search Params in the Browser Window
        const params = new URLSearchParams(window.location.search);
        params.delete('userOTP');

        const newUserOTP = data.otp;
        params.append('userOTP', newUserOTP);

        // Construct the updated URL with the modified parameters
        const updatedUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`;

        // Replace the URL in the browser's address bar
        window.history.replaceState({}, '', updatedUrl);
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (error) {
      handleErrorResponse(error);
      errRef.current?.focus();
      console.error('error', error);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, formik) => submitForm(values, formik)}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {(formik) => {
        const { handleSubmit, isValid } = formik;
        return (
          <Form className="form-horizontal" role="form" onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                sx={{ paddingBottom: '50px' }}
                direction="column"
              >
                <Stack spacing={4} style={classes.root}>
                  {/* Email */}
                  <EmailField
                    required
                    fieldID="email"
                    transition={showEmailField}
                    transNS="fields"
                    transPrefix="email"
                  />
                  {/* OTP Field */}
                  {showEmailField ? (
                    <EmailOTPField required fieldID="userOTP" type="email" />
                  ) : (
                    <MobileOTPField fieldID="userOTP" required={true} />
                  )}
                </Stack>

                {/* CTA  */}
                <Grid
                  container
                  spacing={1}
                  direction={{ xs: 'column', sm: 'row' }}
                  style={classes.root}
                  sx={{ paddingBottom: '50px' }}
                >
                  {/* Request New OTP */}

                  {showRequestOTP && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="warning"
                        type="button"
                        onClick={() => requestNewOTP(formik)}
                      >
                        {t('buttons.new_otp')}
                      </Button>
                    </Grid>
                  )}

                  {/* Confirm OTP */}
                  <Grid item sx={{ marginLeft: 'auto' }}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!isValid}
                    >
                      {t('buttons.confirm')}
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
}

export { AccountConfirmForm };
