import { createContext, useMemo, useState, useContext, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, responsiveFontSizes, useMediaQuery } from '@mui/material';
import { darkTheme, lightTheme } from '../../settings/theme';
import useWindowDimensions from '../../hooks/useWindowDimension';

const IntuThemeContext = createContext({});

export const IntuThemeProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');
  const [userThemeColors, setUserThemeColors] = useState({
    primary: '#333333',
    secondary: '#A8C957',
    tertiary: '#ededed',
  });

  const { width } = useWindowDimensions();
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (width > 767) {
      setMobileView(false);
    } else if (width < 767) {
      setMobileView(true);
    }
  }, [width]);

  useEffect(() => {
    setMode(prefersDarkMode ? 'dark' : 'light');
  }, [prefersDarkMode]);

  const theme = useMemo(() => {
    const baseTheme = mode === 'dark' ? darkTheme : lightTheme;
    const customTheme = createTheme({
      ...baseTheme,
      components: {
        ...baseTheme.components,
        IntuAppBar: {
          ...baseTheme.components.MuiAppBar,
          styleOverrides: {
            ...baseTheme.components.MuiAppBar.styleOverrides,
            root: {
              ...baseTheme.components.MuiAppBar.styleOverrides.root,
              backgroundColor: userThemeColors?.primary,
              boxShadow: `0px 1px 12px ${userThemeColors?.secondary}`,
            },
          },
        },
      },
    });

    return responsiveFontSizes(customTheme);
  }, [mode, userThemeColors]);

  return (
    <IntuThemeContext.Provider
      value={{
        mode,
        setMode,
        userThemeColors,
        setUserThemeColors,
        mobileView,
        setMobileView,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </IntuThemeContext.Provider>
  );
};

export const useIntuTheme = () => useContext(IntuThemeContext);

export default IntuThemeContext;
