import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useResponseHandling from '../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import {
  formatMuiRichTextEditor,
  getMuiRichTextEditorContent,
} from '../../helpers/muiRichtTextEditor';
import { searchUsers } from '../../routes/usersRoutes';
import { searchBrands } from '../../routes/brandRoutes';
import { searchApplications } from '../../routes/applicationsRoutes';
import { searchProductTypes } from '../../routes/productsRoutes';
import { useProcessingHandler } from '../../hooks/useProcessingHandler';
import { BrandsContext } from './BrandsProvider';
import { useTranslation } from 'react-i18next';
import { ProductTypeContext } from './ProductTypeContextProvider';
import { ApplicationsContext } from './ApplicationsContextProvider';
import i18n from '../../i18n';
import AuthContext from './AuthProvider';

export const CompanyProfileContext = createContext();

const initialCompanyProfileValues = {
  _id: '',
  name: '',
  email: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: {
      label: 'United States',
      dialCode: '+1',
      code: 'US',
    },
    postcode: '',
    coordinates: { longitude: '', latitude: '' },
  },
  colors: {
    primary: '',
    secondary: '',
  },
  phone: {
    value: '',
    info: {
      countryCallingCode: null,
      countryCode: null,
      nationalNumber: null,
      numberType: null,
      numberValue: null,
      reason: null,
    },
  },
  type: '',
  brands: [],
  website: '',
  applications: [],
  product_types: [],
  commission_schedule: {},
  tos: '',
  tow: '',
  intro: '',
  intro_rte: getMuiRichTextEditorContent(' '),
  tos_rte: getMuiRichTextEditorContent(' '),
  tow_rte: getMuiRichTextEditorContent(' '),
  employees: [],
  admins: [],
  isAdmin: false,
  profile_picture: '',
  profile_picture_view_url: '',
  profile_completion: 0,
  missing_fields: [],
  initial_setup: false,
};

export const CompanyProfileProvider = ({ children }) => {
  // Get Contaxt
  const { auth } = useContext(AuthContext);

  const errRef = useRef(null);

  // Translator
  const { t } = useTranslation();

  // Subscribe to Context
  const { newBrandsList, addNewBrandsToDatabase } = useContext(BrandsContext);
  const { newProductTypeList, addNewProductTypesToDatabase } =
    useContext(ProductTypeContext);
  const { newApplicationList, addNewApplicationsToDatabase } =
    useContext(ApplicationsContext);

  // SetUp Axios
  const axios = useAxiosPrivate();

  // Set States
  const [newCompany, setNewCompany] = useState(true);
  const [companyClaimer, setCompanyClaimer] = useState({
    company_name: '',
    company_id: '',
    relationship: '',
  });

  // Employee Details (When Adding, Editing or Removing Employees)
  const [employeeDetails, setEmployeeDetails] = useState({
    _id: '',
    name: '',
    email: '',
    role: '',
  });

  // List of Companies
  const [companyOptions, setCompanyOptions] = useState([]);

  // Company Profile Intormations
  const [companyProfile, setCompanyProfile] = useState(
    initialCompanyProfileValues,
  );

  const [newCompanyDialog, setNewCompanyDialog] = useState(false);

  // Application Dialog States

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();

  //Get User Informations
  async function getEmployeesInformation(companyEmployees) {
    const controller = new AbortController();
    const { signal } = controller;

    const userIDs = companyEmployees.map((employee) => employee._id);
    const userInfo = [];
    const payload = {
      key: '_id',
      values: userIDs,
    };

    try {
      const { data, status } = await searchUsers(payload);
      if (status === 'success') {
        for (let i = 0; i < companyEmployees.length; i++) {
          const companyEmployee = companyEmployees[i];

          const user = data.find(
            (element) => element._id === companyEmployee._id,
          );

          if (user) {
            userInfo.push({
              ...companyEmployee,
              name: `${user.first_name} ${user.last_name}`,
              email: user.email,
            });
          }
        }
      }

      return userInfo;
    } catch (err) {
      handleErrorResponse(err);
      controller.abort();
    } finally {
      controller.abort(signal);
    }
  }

  // Get Company Profile Completion Process
  async function getCompanyProfileProgress() {
    const controller = new AbortController();
    const { signal } = controller;
    try {
      const url = '/api/companies/company/completion-status';
      const request = await axios.get(url, { signal });

      const response = request?.data;

      if (response) {
        return response;
      }
    } catch (err) {
      handleErrorResponse(err);
      return err;
    } finally {
      controller.abort(signal);
    }
  }

  // Search Brands in database
  async function handleGetBrands(brandsById) {
    // Guard Clause
    if (brandsById?.length === 0) {
      return;
    }

    let companyBrands = [];
    try {
      const payload = {
        key: '_id',
        values: brandsById,
      };
      const { data, status } = await searchBrands(payload);
      if (status === 'success') {
        companyBrands = data;
      }
    } catch (err) {
      handleErrorResponse(err);
    }

    return companyBrands;
  }

  // Search Product Types
  async function handleGetProductTypes(productTypesById) {
    // Guard Clause
    if (productTypesById?.length === 0) {
      return;
    }

    let companyProductTypes = [];
    try {
      const payload = {
        key: '_id',
        values: productTypesById,
      };
      const { data, status } = await searchProductTypes(payload);
      if (status === 'success') {
        companyProductTypes = data;
      }
    } catch (err) {
      handleErrorResponse(err);
    }

    return companyProductTypes;
  }

  // Search Applications
  async function handleGetApplications(applicationsById) {
    // Guard Clause
    if (applicationsById?.length === 0) {
      return;
    }

    let companyApplications = [];
    try {
      const payload = {
        key: '_id',
        values: applicationsById,
      };
      const { status, data } = await searchApplications(payload);
      if (status === 'success') {
        companyApplications = data;
      }
    } catch (err) {
      handleErrorResponse(err);
    }

    return companyApplications;
  }

  // Handle Get Company Info
  async function getCompanyProfile(id) {
    // Guard Clause
    if (!id && !auth.user_info && !auth.auth_info) {
      return;
    }

    const url = `/api/companies/company/details/${id}`;
    try {
      const response = await axios.get(url, {
        headers: { 'Content-Type': 'application/json' },
      });
      const { data, status } = response;
      const info = data.data;

      if (status === 200) {
        // Format Image
        let image = '';
        if (info?.profile_picture?.mimetype) {
          image = `data:${info.profile_picture.mimetype};base64,${info.profile_picture.buffer.toString('base64')}`;
        }

        // Get Employee Details
        let companyEmployees = [];
        let companyAdmins = [];
        let companyAdminsInfo = [];
        if (info?.employees?.length > 0) {
          companyEmployees = await getEmployeesInformation(info.employees);

          // Filter Admins
          companyAdmins = info.employees.filter(
            (employee) => employee.role === 'admin',
          );
        }

        if (companyAdmins.length > 0) {
          companyAdminsInfo = await getEmployeesInformation(companyAdmins);
        }

        let companyData = {
          ...companyProfile,
          _id: info._id,
          name: info?.name,
          email: info?.email,
          address: info?.address,
          phone: info?.phone,
          type: info?.type,
          brands:
            info?.brands?.length > 0 ? await handleGetBrands(info.brands) : [],
          website: info?.website,
          colors: info?.colors,
          applications:
            (info?.applications.length > 0 &&
              (await handleGetApplications(info.applications))) ||
            [],
          product_types:
            (info?.product_types.length > 0 &&
              (await handleGetProductTypes(info.product_types))) ||
            [],
          intro: info?.intro,
          intro_rte: info?.intro || formatMuiRichTextEditor(' '),
          tow: info?.tow,
          tow_rte: info?.tow || formatMuiRichTextEditor(' '),
          tos: info?.tos,
          tos_rte: info?.tos || formatMuiRichTextEditor(' '),
          commission_schedule: info?.commission_schedule,
          employees: companyEmployees,
          admins: companyAdminsInfo,
          profile_picture: info?.profile_picture,
          profile_picture_view_url: image,
          isAdmin: false,
          initial_setup: info.initial_setup,
        };

        if (companyAdmins?.length > 0) {
          companyAdmins.forEach(async (employee) => {
            if (employee._id === auth.user_info?._id) {
              companyData.isAdmin = true;

              const profilecompletionProgress =
                await getCompanyProfileProgress();
              const { data, statusCode } = profilecompletionProgress;
              if (statusCode === 200) {
                const percentage = data.percentage;
                const missingFields = data.missingFields;
                if (percentage) {
                  companyData.profile_completion = parseFloat(percentage + '%');
                }
                companyData.missing_fields = missingFields;
              }
            }
          });
        }

        setCompanyProfile(companyData);
        return companyData;
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  }

  // Get User Company Info
  useEffect(() => {
    setIsLoading({ status: true, type: 'skeleton', text: '' });

    try {
      async function handlegetCompanyProfile() {
        await getCompanyProfile(auth.user_info.company);
      }

      if (auth?.user_info?.company) {
        handlegetCompanyProfile();
      } else {
        setCompanyProfile(initialCompanyProfileValues);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading({ status: false, type: 'skeleton', text: '' });
    }
  }, [auth?.user_info?.company, companyProfile._id, i18n.language]);

  // Handle Company Update
  async function updateCompanyProfile(values) {
    setIsLoading({
      status: true,
      type: 'skeleton',
      text: t('form.message.update_company_details'),
    });

    try {
      // Add New Products to Database
      let productTypesByID = [];
      if (values?.product_types.every((item) => typeof item !== 'string')) {
        if (values?.product_types?.length > 0) {
          productTypesByID = values?.product_types
            .filter((product) => product.type_id)
            .map((product) => product.type_id);

          if (newProductTypeList.length > 0) {
            const newProductData = await addNewProductTypesToDatabase();
            if (newProductData?.length > 0) {
              const newProductTypesById = newProductData.map(
                (product) => product.type_id,
              );

              productTypesByID = [...productTypesByID, ...newProductTypesById];
            }
          }
        }
      } else {
        productTypesByID = values?.product_types;
      }

      // Add New Brands to the Database
      let brandsByID = [];
      if (values?.brands?.every((item) => typeof item !== 'string')) {
        if (values?.brands?.length > 0) {
          brandsByID = values?.brands
            .filter((brand) => brand._id)
            .map((brand) => brand._id);

          if (newBrandsList.length > 0) {
            const newBrandData = await addNewBrandsToDatabase(values);
            if (newBrandData?.length > 0) {
              const newBrandsById = newBrandData.map((brand) => brand._id);

              brandsByID = [...brandsByID, ...newBrandsById];
            }
          }
        }
      } else {
        brandsByID = values?.brands;
      }

      // Add newly added application id's to product values
      let applicationsByID = [];
      if (values?.applications.every((item) => typeof item !== 'string')) {
        if (values?.applications.length > 0) {
          applicationsByID = values?.applications
            .filter((application) => application.application_id)
            .map((application) => application.application_id);

          // Add New Applications to the Database
          if (newApplicationList.length > 0) {
            const newApplicationData = await addNewApplicationsToDatabase();
            if (newApplicationData?.length > 0) {
              const newApplicationsById = newApplicationData.map(
                (application) => application.application_id,
              );

              applicationsByID = [...applicationsByID, ...newApplicationsById];
            }
          }
        }
      } else {
        applicationsByID = values?.applications;
      }

      // Format Employees for Database
      let employeesByID = [];
      if (values?.employees.every((item) => typeof item !== 'string')) {
        if (values?.employees?.length > 0) {
          employeesByID = values?.employees.map((employee) => {
            return {
              _id: employee._id,
              role: employee.role,
            };
          });
        }
      } else {
        employeesByID = values?.employees;
      }

      // Update Company Account
      const url = '/api/companies/company/update';

      const payload = {
        _id: values._id,
        name: values.name,
        email: values.email,
        website: values.website,
        employees: employeesByID,
        brands: brandsByID,
        applications: applicationsByID,
        product_types: productTypesByID,
        address: values.address,
        phone: values.phone,
        intro: values.intro,
        tos: values.tos,
        tow: values.tow,
        commission_schedule: values.commission_schedule,
        notification: true,
        colors: values.colors,
      };

      try {
        const request = await axios.put(url, JSON.stringify(payload), {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const response = request?.data;

        if (request.status === 200) {
          const updatedCompanyData = response.data;
          await getCompanyProfile(updatedCompanyData._id);
        }
        handleRegularResponse({
          open: true,
          status: response.status,
          message: response.message,
        });
      } catch (err) {
        handleErrorResponse(err);
        errRef.current?.focus();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading({
        status: false,
        type: 'skeleton',
        text: '',
      });
    }
  }

  return (
    <CompanyProfileContext.Provider
      value={{
        newCompany,
        setNewCompany,
        companyClaimer,
        setCompanyClaimer,
        companyOptions,
        setCompanyOptions,
        companyProfile,
        setCompanyProfile,
        getCompanyProfile,
        updateCompanyProfile,
        employeeDetails,
        setEmployeeDetails,
        newCompanyDialog,
        setNewCompanyDialog,
      }}
    >
      {children}
    </CompanyProfileContext.Provider>
  );
};
