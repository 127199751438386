import { useEffect, useState, useMemo } from 'react';
import { Autocomplete, InputLabel, TextField } from '@mui/material';
import {
  addSingleProductType,
  getProductTypes,
} from '../../routes/productsRoutes';
import { NewOption } from './NewAttributeAutocomplete';
import NewProductTypeDialog from './NewProductTypeDialog';
import { v4 as uuid } from 'uuid';

const NewProductTypeAutocomplete = ({
  product,
  handleNewProductType,
  handleDeleteProductType,
}) => {
  const [options, setOptions] = useState([]);
  const [isNewProductTypeDialogOpen, setIsNewProductTypeDialogOpen] =
    useState(false);

  useEffect(() => {
    getProductTypeOptions();
  }, []);

  const getProductTypeOptions = async () => {
    const { data, statusCode } = await getProductTypes(true);
    if (statusCode === 200) {
      const options = data.map((option) => {
        return {
          label: option.type_name,
          value: option.type_id,
        };
      });
      setOptions(options);
    }
  };

  const handleConfirm = async ({
    productFamily,
    productGroup,
    newProductType,
  }) => {
    setIsNewProductTypeDialogOpen(false);
    handleNewProductType(newProductType);

    setOptions([
      ...options,
      {
        label: newProductType,
        value: uuid(),
      },
    ]);

    await addSingleProductType({
      productFamily: productFamily.family_id,
      productGroup: productGroup.group_id,
      newProductType,
    });
  };

  const handleAutocompleteChange = (event, newValue, reason, details) => {
    if (reason === 'clear') {
      handleDeleteProductType([]);
      return;
    }

    if (reason === 'removeOption') {
      const deletedValue = details.option;
      handleDeleteProductType(deletedValue);
      return;
    }

    handleNewProductType(newValue[newValue.length - 1].label);
  };

  const selectedProductTypes = useMemo(() => {
    return options.filter((option) =>
      product.product_types.includes(option.label),
    );
  }, [product.product_types, options]);

  const OptionsList = (props) => {
    return (
      <ul {...props}>
        {props.children}
        <li>
          <NewOption
            handleClick={() => setIsNewProductTypeDialogOpen(true)}
            label="Product Type"
          />
        </li>
      </ul>
    );
  };

  return (
    <>
      <InputLabel id={'new-product-type'} sx={{ fontSize: '1.2rem' }}>
        Product Type
      </InputLabel>
      <Autocomplete
        multiple
        disablePortal
        value={selectedProductTypes}
        id="new-product-type-autocomplete"
        options={options}
        selectOnFocus
        handleHomeEndKeys
        name="product_types"
        onChange={handleAutocompleteChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Select a Product Type or enter a new one and hit enter"
            name="product_types"
          />
        )}
        noOptionsText={
          <NewOption
            handleClick={() => setIsNewProductTypeDialogOpen(true)}
            label="Product Type"
          />
        }
        ListboxComponent={OptionsList}
        sx={{ mb: 2 }}
      />
      <NewProductTypeDialog
        mode="New"
        isOpen={isNewProductTypeDialogOpen}
        handleConfirm={handleConfirm}
        handleClose={() => setIsNewProductTypeDialogOpen(false)}
      />
    </>
  );
};

export default NewProductTypeAutocomplete;
