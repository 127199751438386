import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { useAxiosPrivateData } from '../../hooks/axios/useAxiosPrivate';

const SearchCompanyCommission = ({ setProduct }) => {
  const axiosData = useAxiosPrivateData();
  const [companies, setCompanies] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    getAllCompanies();
  }, []);

  const getAllCompanies = async () => {
    try {
      const { data, status } = await axiosData.get(`/api/companies`);
      if (status === 200) {
        setCompanies(data.data);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const companyOptions = useMemo(() => {
    if (!companies) return [];
    return companies.map((company) => company.name);
  }, [companies]);

  const handleAutocompleteChange = (e, value) => {
    const company = companies.find((company) => company.name === value);
    setSelectedCompany(company);
  };

  const handleSetCommission = () => {
    // Add ids because datagrid rows require it
    const generatedCommissions =
      selectedCompany.commission_schedule.commission.map((c, i) => {
        return {
          ...c,
          id: i,
        };
      });

    const commission_schedule = {
      commission: generatedCommissions,
      type: selectedCompany.commission_schedule.type,
    };

    setProduct((prevState) => {
      return {
        ...prevState,
        commission_schedule,
      };
    });
  };

  return (
    <Box mb={4} display="flex" flexDirection="column">
      <Typography mb={2}>
        Choose a company to pull a commission schedule from
      </Typography>
      <Autocomplete
        disablePortal
        options={companyOptions}
        selectOnFocus
        handleHomeEndKeys
        name="search_company_commission"
        onChange={handleAutocompleteChange}
        renderInput={(params) => <TextField {...params} />}
        label="Choose an option"
      />
      <Button
        variant="contained"
        disabled={!selectedCompany}
        onClick={handleSetCommission}
        sx={{ width: '20%', mt: 2, alignSelf: 'flex-end' }}
      >
        Set Commission
      </Button>
    </Box>
  );
};

export default SearchCompanyCommission;
