import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import parse from 'html-react-parser';
import { Video } from '../../../plugins/BackgroundVideoPlayer';
import { LeadContext } from '../../../context/providers/leads/LeadContextProvider.jsx';
import { ButtonHoverGradiant } from '../../../components/buttons/ButtonHoverGradiant';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';
import useIntuTheme from '../../../hooks/useIntuTheme.js';

const IntroManufacturers = () => {
  const { leadInfo } = useContext(LeadContext);
  const { mobileView } = useIntuTheme();

  // Theme
  const theme = useTheme();

  // Navigate
  const navigate = useNavigate();

  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.howItWorks.lead_context',
  });

  //   Responsiveness
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  // JoinNow Button
  const JoinNow = () => {
    return (
      <ButtonHoverGradiant
        label={i18n.t('join_now', { ns: 'buttons' })}
        onClick={() => navigate('/account/signup')}
      />
    );
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent={{ xs: 'flex-start', md: 'space-around' }}
        direction={{ xs: 'column', md: 'row' }}
        sx={{ padding: '50px 20px 0 20px' }}
      >
        <Grid item xs={12}>
          {mobileView ? (
            <Typography variant="h2" className="green-text" align="center">
              {leadInfo?.headline_title
                ? leadInfo?.headline_title
                : t('manu.intro.title')}
            </Typography>
          ) : (
            <Typography variant="h2" className="green-text">
              {leadInfo?.headline_title
                ? leadInfo?.headline_title
                : t('manu.intro.title')}
            </Typography>
          )}
        </Grid>

        {/* Intro Text */}
        <Grid container item xs={12} md={5}>
          <Typography variant="body1">
            {parse(leadInfo.headline_body)}
          </Typography>
        </Grid>
        {/* Intro Video */}
        {small ? (
          ''
        ) : (
          <Grid item xs={12} md={5}>
            <Video
              blur={0}
              videoSource={
                'https://in2tec.nyc3.cdn.digitaloceanspaces.com/videos/mfg_explainer_en.mp4'
              }
            />
          </Grid>
        )}
      </Grid>
      {/* Intro Video for Mobile */}
      {small ? (
        <Grid item xs={12} md={5} mt={5}>
          <Video
            blur={0}
            videoSource={
              'https://in2tec.nyc3.cdn.digitaloceanspaces.com/videos/mfg_explainer_en.mp4'
            }
          />
        </Grid>
      ) : (
        ''
      )}
      <Grid item container justifyContent="center">
        <JoinNow />
      </Grid>
    </>
  );
};

export { IntroManufacturers };
