import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const EditCommissionDialog = ({
  isOpen,
  handleConfirm,
  handleClose,
  selectedCommission,
}) => {
  const { t } = useTranslation();
  const [newCommission, setNewComission] = useState({
    max_amount: null,
    commission: null,
  });

  useEffect(() => {
    setNewComission({ ...selectedCommission });
  }, [selectedCommission]);

  const handleInputChange = (e) => {
    setNewComission((prevState) => {
      return {
        ...prevState,
        [e.target.name]: Number(e.target.value),
      };
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{t('components.commission.edit')}</DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <FormControl fullWidth>
          <TextField
            type="number"
            label="Enter a max amount"
            variant="outlined"
            size="small"
            name="max_amount"
            value={
              newCommission.max_amount === 0 || !newCommission.max_amount
                ? ''
                : newCommission.max_amount
            }
            onChange={handleInputChange}
            error={newCommission.max_amount < 0}
            helperText={
              newCommission.max_amount < 0
                ? 'Value must be be greater than 0'
                : ''
            }
            sx={{ mb: 2 }}
          />
          <TextField
            type="number"
            label="Enter a commission rate"
            variant="outlined"
            size="small"
            name="commission"
            value={
              newCommission.commission === 0 || !newCommission.commission
                ? ''
                : newCommission.commission
            }
            onChange={handleInputChange}
            error={
              newCommission.commission < 0 || newCommission.commission > 100
            }
            helperText={'Value must be between 0 and 100'}
            sx={{ mb: 2 }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions
        style={{ paddingBottom: '16px', justifyContent: 'space-evenly' }}
      >
        <Button onClick={handleClose} color="secondary" variant="contained">
          {t('buttons.close')}
        </Button>
        <Button
          onClick={() => handleConfirm(newCommission)}
          color="primary"
          type="submit"
          disabled={
            !newCommission.max_amount ||
            !newCommission.commission ||
            newCommission.commission > 100 ||
            newCommission.commission < 0
          }
          variant="contained"
        >
          {t('buttons.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCommissionDialog;
