import { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const upperCaseWord = (word) => `${word[0].toUpperCase()}${word.slice(1)}`;

const formatKey = (key) => {
  return key
    .split('_')
    .map((word) => upperCaseWord(word))
    .join(' ');
};

const NewAttributeDialog = ({
  isOpen,
  handleConfirm,
  handleClose,
  selectedAttributeType,
  selectedAttribute,
  productAttributeOptions,
}) => {
  const { t } = useTranslation();
  const [newAttribute, setNewAttribute] = useState(
    selectedAttribute.attributeValue.attribute,
  );

  const attributeTypes = useMemo(() => {
    if (!productAttributeOptions.length) return new Set();
    const attributeTypeSet = new Set();
    productAttributeOptions.forEach((option) => {
      attributeTypeSet.add(upperCaseWord(option.attribute_type));
    });
    return attributeTypeSet;
  }, [productAttributeOptions]);

  const [newAttributeType, setNewAttributeType] = useState(
    selectedAttribute.attributeValue.attribute_type,
  );
  const [newValue, setNewValue] = useState(
    selectedAttribute.attributeValue.value,
  );

  const onConfirmClick = () => {
    handleConfirm({
      attribute_type: newAttributeType,
      attribute: newAttribute,
      value: newValue,
    });
  };

  const handleCloseClick = () => {
    setNewValue('');
    handleClose();
  };

  // TODO check measurement value to provide units and save as object array
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>New Attribute</DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <FormControl sx={{ minWidth: 180 }}>
          <InputLabel id="attribute-type-select">Attribute Type</InputLabel>
          <Select
            label="Attribute Type"
            labelId="attribute-type-select"
            defaultValue={newAttributeType}
            value={newAttributeType}
            onChange={(e) => setNewAttributeType(e.target.value)}
            name="unit"
            sx={{ mb: 2 }}
          >
            {attributeTypes.map((type) => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label={`New Attribute`}
            variant="outlined"
            size="small"
            name="attribute"
            value={formatKey(newAttribute)}
            onChange={(e) => setNewAttribute(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label={`New Attribute Value`}
            variant="outlined"
            size="small"
            name="attributeValue"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            sx={{ mb: 2 }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions
        style={{ paddingBottom: '16px', justifyContent: 'space-evenly' }}
      >
        <Button
          onClick={handleCloseClick}
          color="secondary"
          variant="contained"
        >
          {t('buttons.close')}
        </Button>
        <Button
          onClick={onConfirmClick}
          color="primary"
          type="submit"
          disabled={false}
          variant="contained"
        >
          {t('buttons.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewAttributeDialog;
