import { Grid, Typography, List, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n.js';

const ScrollingText1 = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.home.MfgHome.ScrollingText1',
  });
  const params = new URLSearchParams(window.location.search);
  const cName = params.get('cName');
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Typography variant="h2">{t('title')}</Typography>
      </Grid>
      <Grid item container sx={{ width: '80%' }} justifyContent="center">
        <List sx={{ listStyleType: 'disc', marginLeft: '15px' }}>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={t('listitem1', {
                companyName: cName ? cName : i18n.t('your', { ns: 'common' }),
              })}
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography variant="body1">{t('listitem2')}</Typography>
            <ListItemText primary={t('listitem2')} />
          </ListItem>
          <List sx={{ listStyleType: 'decimal', paddingLeft: '35px' }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primary={t('listitem3')} />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={t('listitem4', {
                  companyName: cName
                    ? cName
                    : i18n.t('your', { ns: 'common' }) +
                      ' ' +
                      i18n.format(
                        i18n.t('company', { ns: 'types' }),
                        'lowercase',
                      ),
                })}
              />
            </ListItem>
          </List>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText
              primary={t('listitem5', {
                companyName: cName
                  ? cName + "'s"
                  : i18n.t('your', { ns: 'common' }),
              })}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

const ScrollingText2 = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.home.MfgHome.ScrollingText2',
  });
  const params = new URLSearchParams(window.location.search);
  const cName = params.get('cName');
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Typography variant="h2" textAlign="center">
          {t('title')}
        </Typography>
      </Grid>
      <Grid item container sx={{ width: '80%' }} justifyContent="center">
        <Grid item>
          <Typography variant="h6">
            {t('listitem1', {
              companyName: cName ? cName : i18n.t('your', { ns: 'common' }),
            })}
          </Typography>
        </Grid>
        <Grid item>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primary={t('listitem2')} />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primary={t('listitem3')} />
            </ListItem>
            <List sx={{ listStyleType: 'decimal', paddingLeft: '35px' }}>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary={t('listitem4')} />
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <ListItemText primary={t('listitem5')} />
              </ListItem>
            </List>
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ScrollingText3 = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.home.MfgHome.ScrollingText3',
  });
  const params = new URLSearchParams(window.location.search);
  const cName = params.get('cName');
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Typography variant="h2">{t('title')}</Typography>
      </Grid>
      <Grid item container sx={{ width: '80%' }}>
        <Grid item>
          <Typography variant="h6">
            {t('subtitle', {
              companyName: cName ? cName : i18n.t('your', { ns: 'common' }),
            })}
          </Typography>
        </Grid>
        <Grid item>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primary={t('listitem1')} />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primary={t('listitem2')} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ScrollingText4 = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.home.MfgHome.ScrollingText4',
  });
  const params = new URLSearchParams(window.location.search);
  const cName = params.get('cName');
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Typography variant="h2">{t('title')}</Typography>
      </Grid>
      <Grid item container sx={{ width: '80%' }} justifyContent="center">
        <Typography variant="h6">
          {t('subtitle', {
            companyName: cName ? cName : i18n.t('your', { ns: 'common' }),
          })}
        </Typography>
      </Grid>
      <Grid item container sx={{ width: '80%' }} justifyContent="center">
        <List sx={{ listStyleType: 'disc', marginLeft: '1rem' }}>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem1')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem2')} />
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText primary={t('listitem3')} />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export const ScrollingText = [
  <ScrollingText1 />,
  <ScrollingText2 />,
  <ScrollingText3 />,
  <ScrollingText4 />,
];
