import React, { useEffect } from 'react';

// HTML Parser
import ReactHtmlParser from 'react-html-parser';

// AM5
import * as am5 from '@amcharts/amcharts5';
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy';
import am5Themes_IntuTheme from './theme';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';

const TreeChartContext = ({
  chartId, // Unique Chart ID (required)
  description, // Chart Description (Optional)
  descriptionColor, // Color of the description text (optional)
  title, // Title (optional)
  titleColor, // Title Color (optional)
  titleBackgroundColor,
  data,
  labelColor = '#fff',
}) => {
  useEffect(() => {
    // Guard Clause
    if (!chartId) {
      return;
    }

    // Create root element
    let root = am5.Root.new(chartId);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root),
      am5Themes_IntuTheme.new(root),
    ]);

    // Create wrapper container
    let container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
        showTooltipOn: 'click',
      }),
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
    let series = container.children.push(
      am5hierarchy.Tree.new(root, {
        singleBranchOnly: false,
        sCollapsed: false,
        disabledField: true,
        topDepth: 0,
        downDepth: 1,
        paddingTop: 100,
        paddingBottom: 100,
        initialDepth: 10,
        valueField: 'value',
        categoryField: 'name',
        childDataField: 'children',
      }),
    );

    //
    series.labels.template.setAll({
      fontSize: 12,
      // fill: am5.color('#333333'),
      fill: am5.color(labelColor),
      text: '{category}',
    });

    // Add an icon to node
    series.nodes.template.setup = function (target) {
      // Allow for custom image
      target.events.on('dataitemchanged', function (ev) {
        target.children.push(
          am5.Picture.new(root, {
            width: ev.target.dataItem.dataContext.circleSize,
            height: ev.target.dataItem.dataContext.circleSize,
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            src: ev.target.dataItem.dataContext?.image,
          }),
        );
      });

      // Set up labels
      series.labels.template.setAll({
        fill: am5.color(labelColor),
        y: 50,
        oversizedBehavior: 'none',
      });

      series.circles.template.adapters.add('radius', function (id, target) {
        // Check if the data context has an image
        if (target.dataItem.dataContext?.circleSize) {
          return target.dataItem.dataContext.circleSize;
        } else {
          return 30;
        }
      });

      // Make nodes draggable
      series.nodes.template.adapters.add('draggable', function (id, target) {
        // Check if the data context has an image
        if (target.dataItem.dataContext?.draggable) {
          return target.dataItem.dataContext.draggable;
        } else {
          return false;
        }
      });

      // Allow for custom color
      series.circles.template.adapters.add('fill', function (fill, target) {
        // Check if the data context has an image
        if (target.dataItem.dataContext?.color) {
          return target.dataItem.dataContext?.color;
        }
        return fill;
      });
    };

    series.data.setAll([data]);
    series.set('selectedDataItem', series.dataItems[0]);

    // Make stuff animate on load
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);

  return (
    // Chart Component
    <>
      {/* Title */}
      <Grid
        item
        xs={12}
        container
        direction="column"
        alignItems="center"
        alignContent="center"
        sx={{
          backgroundColor: titleBackgroundColor,
          marginTop: '60px',
          marginBottom: '-20px',
        }}
      >
        <Grid item textAlign="center">
          <Typography variant="h6" color={titleColor ? titleColor : null}>
            {ReactHtmlParser(title)}
          </Typography>
        </Grid>
      </Grid>

      {/* Description */}
      <Grid item xs={12} textAlign="center">
        <Typography
          variant="body"
          color={descriptionColor ? descriptionColor : null}
        >
          {ReactHtmlParser(description)}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        id={chartId}
        style={{
          width: '100%',
          minHeight: '800px',
        }}
      />
    </>
  );
};

export { TreeChartContext };
