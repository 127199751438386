import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate.js';
import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsTracker.jsx';

// Global Functions
import { toTitleCase } from '../../helpers/toTitleCase.js';

// Import context
import useAuth from '../../hooks/useAuth.js';

// Import Country List
import countryList from '../../data/CountryData.json';

// Formik
import { Formik, Form, useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';

// MUI Compoents
import {
  GridToolbar,
  GridRow,
  GridColumnHeaders,
} from '@mui/x-data-grid-premium';

import {
  Typography,
  Skeleton,
  Box,
  Stack,
  FormControl,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { classes } from '../../settings/theme.js';

// Date & TimePicker and timzone Support
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import moment from 'moment-timezone';

// Icons
import SendIcon from '@mui/icons-material/Send';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PeopleIcon from '@mui/icons-material/People';
import TrafficIcon from '@mui/icons-material/Traffic';

// Response & Loading Handlers
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import { createContext } from 'react';
import { useContext } from 'react';
import LocationsMap from '../../components/maps/LocationsMap.jsx';
import useUser from '../../hooks/useUser.js';

// Translator
import { useTranslation } from 'react-i18next';
import { getIntutecEmployees } from '../../routes/employeeRoutes.js';
import { t } from 'i18next';
import MapLocationByAddressProvider from '../../context/providers/maps/MapLocationByAddressProvider.jsx';
import { geoCodeAddress } from '../../routes/mapRoutes.js';

// Context
const EventContext = createContext({});
const MapContext = createContext({});

// LOCATION MAP
const MapLocation = () => {
  // Formik Values
  const { values } = useFormikContext();

  //SetUp Axios
  const axios = useAxiosPrivate();

  // Translator
  const { t } = useTranslation();

  // Handle Map Rendering
  const { mapRedered, setMapRendered } = useContext(MapContext);

  let [locations, setLocations] = useState([
    {
      name: 'Location Name',
      content: 'Location Content',
      coordinates: { latitude: '', longitude: '' },
    },
  ]);

  // Map Existing Locations
  useEffect(() => {
    let location = {
      name: values.name,
      street: values.address.line1,
      postcode: values.address.postcode,
      state: values.address.state,
      country: values.address.country.label,
      content: values.remarks,
    };

    async function getMapLocations() {
      await mapLocationOnMap(location);
    }
    getMapLocations();

    const setMap = setTimeout(() => {
      setMapRendered(true);
    }, 600);
  }, [values.address]);

  async function mapLocationOnMap(param) {
    // Set Updated state to false
    setMapRendered(false);

    // Additional Parameters
    const name = param?.name || '';
    const content = param?.content || '';

    const getLocationData = await geoCodeAddress(param);

    if (getLocationData.statusCode === 200) {
      // Update Map Locations
      let newLocations = [
        {
          name: name,
          content: content,
          coordinates: {
            longitude: getLocationData.location.lng,
            latitude: getLocationData.location.lat,
          },
        },
      ];
      setLocations(newLocations);
      return getLocationData;
    }
  }

  return (
    <>
      {mapRedered ? (
        <LocationsMap initialZoom={15} label={true} locations={locations} />
      ) : (
        <Typography variant="body" color="white" align="center">
          {t('form.message.load_location')}
        </Typography>
      )}
    </>
  );
};

// Participants
const Participants = () => {
  // Get Context
  const { participantsList, mobileView, MemoizedRow, MemoizedColumnHeaders } =
    useContext(EventContext);

  // Associate Activity Table
  const paticipantsColumns = [
    {
      field: 'created',
      headerName: 'Registered',
      editable: false,
      minWidth: 50,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      editable: false,
      minWidth: 50,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      editable: false,
      minWidth: 50,
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 0.3,
      minWidth: 100,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      editable: false,
      flex: 0.3,
      minWidth: 100,
      valueGetter: (params) => params.row.phone.value || '',
    },
    // {
    //   field: "edit",
    //   headerName: "Edit",
    //   sortable: false,
    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       let activityContent = params.row
    //       setActivityDialog({
    //         openDialog: true,
    //         leadID: values._id,
    //         activityDialogTitle: 'Edit Activity',
    //         activityDialogText: 'Make your changes!',
    //         activityPrimaryButtonText: 'Update',
    //         activitySecondaryButtonText: "Close",
    //         activityDialogAction: 'update',
    //         activityLog: values.associate_activity,
    //         activityContent
    //       })
    //     };

    //     return (
    //       <Button
    //         variant="contained"
    //         color="tertiary"
    //         onClick={onClick}
    //       >Edit
    //       </Button>
    //     )
    //   }
    // }
  ];

  return (
    <>
      <Typography variant="h5">Participants</Typography>

      {/* Associate Activities Columns */}
      <Box>
        <DataGrid
          isCellEditable={(params) => false}
          getRowId={(row) => row._id}
          rows={participantsList}
          columns={paticipantsColumns}
          components={{
            Row: MemoizedRow,
            ColumnHeaders: MemoizedColumnHeaders,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          sx={{
            '&::-webkit-scrollbar': {
              width: 20,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'orange',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'red',
              borderRadius: 2,
            },
          }}
          pageSizeOptions={[5, 10, 100]}
          checkboxSelection={!mobileView}
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </>
  );
};

const TimeZonePicker = () => {
  // Get context
  const {
    values,
    setValues,
    touched,
    setTouched,
    errors,
    setErrors,
    setFieldValue,
    setFieldTouched,
    handleBlur,
  } = useFormikContext();

  // Translator
  const { t } = useTranslation();

  const handleChange = (event, value) => {
    setValues({
      ...values,
      timezone: value,
    });
    setTimeout(
      () =>
        setTouched({
          ...touched,
          timezone: true,
        }),
      100,
    );
  };

  const timezones = moment.tz.names();

  return (
    <Stack>
      {/* Event Date & Time*/}
      <Typography variant="h5">
        {t('form.date_time.label', { type: t('types.events') })}
      </Typography>
      <DateTimePicker
        required
        id="date"
        name="date"
        label={t('form.date_time.label', { type: t('types.events') })}
        placeholder={t('form.date_time.placeholder', {
          type: t('types.events'),
        })}
        value={dayjs(values?.date)}
        onBlur={handleBlur}
        onChange={(date, e) => {
          setFieldValue('date', date);
          setFieldTouched('date', true, true);
        }}
        slotProps={{
          textField: {
            margin: 'dense',
            variant: 'standard',
            error: errors?.date && touched.date ? true : false,
            helperText: errors?.date && touched?.date ? errors.date : null,
          },
        }}
        minDate={dayjs()}
      />

      {/* Timezone */}
      <Autocomplete
        required
        id="timezone"
        name="timezone"
        value={values?.timezone}
        onChange={handleChange}
        onBlur={handleBlur}
        options={timezones}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('form.timezone.label', { type: t('types.events') })}
            placeholder={t('form.timezone.placeholder', {
              type: t('types.events'),
            })}
            margin="dense"
            type="text"
            variant="standard"
            error={errors?.timezone && touched.timezone ? true : false}
            helperText={
              errors?.timezone && touched?.timezone ? errors.timezone : null
            }
          />
        )}
      />
    </Stack>
  );
};

// Event Form
const EventForm = () => {
  //SetUp Axios
  const axios = useAxiosPrivate();

  // Use Context
  const {
    blankEventInfo,
    eventInfo,
    setEventInfo,
    eventDialog,
    setEventDialog,
    handleErrorResponse,
    isLoading,
    employeeOptions,
    handleRegularResponse,
    getEventsList,
    participantsList,
    setParticipantsList,
    getUserInfo,
  } = useContext(EventContext);
  const { user } = useUser();
  const { auth } = useAuth();

  // Translator
  const { t } = useTranslation();

  // Mapping Address State
  const [mapAddress, setMapAddress] = useState({
    name: '',
    street: '',
    postcode: '',
    state: '',
    country: '',
  });

  // Country List
  const countryOptions = countryList;

  // Addres Search & Mapping
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressSearch, setAddressSearch] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    postcode: '',
    country: { label: 'United States', dialCode: '+1', code: 'US' },
    coordinates: { latitude: '', longitude: '' },
  });
  const [mapRedered, setMapRendered] = useState(false);

  let isLoadingAddress = false;
  const addressSearchRef = useRef();

  // Set Up Form refs
  const errRef = useRef();

  // Initial Formik
  const formik = useFormik({
    initialValues: eventInfo,
    initialTouched: {},
    values: {},
  });

  // Initialize initial Values
  formik.initialValues = {
    ...eventInfo,
  };

  // Validation
  var eventValidationScheme = Yup.object();
  eventValidationScheme = Yup.object().shape({
    name: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .required(t('validation.event.name_required')),
    date: Yup.date().required(t('validation.event.date_required')),
    timezone: Yup.string().required(t('validation.timezone')),
    type: Yup.string().required(t('validation.event.type_required')),
    address: Yup.object({
      line1: Yup.string()
        .min(5, t('validation.short'))
        .required(t('validation.address.line1_required')),
      city: Yup.string()
        .min(3, t('validation.short'))
        .required(t('validation.address.city_required')),
      state: Yup.string()
        .min(2, t('validation.short'))
        .required(t('validation.address.state_required')),
      postcode: Yup.string()
        .min(3, t('validation.short'))
        .required(t('validation.address.postcode_required')),
      country: Yup.object({
        label: Yup.string().required(t('validation.country.required')),
        dialCode: Yup.number(t('validation.country.dial_code.invalid'))
          .required(t('validation.country.dial_code.required'))
          .typeError(t('validation.country.dial_code.error')),
        code: Yup.string()
          .required(t('validation.country.code.required'))
          .min(2, t('validation.country.code.min')),
      }),
    }),
    capacity: Yup.number().integer().min(1).required(),
    associate: Yup.object({
      name: Yup.string().required(t('validation.event.associate_required')),
    }),
    status: Yup.string().when('eventDialogOpen', {
      is: true,
      then: () =>
        Yup.string()
          .label(t('form.status.status'))
          .required(t('validation.status_required')),
    }),
  });

  // Get Updated Coordinates
  async function getUpdatedCoordinates(param) {
    try {
      const getLocationData = await geoCodeAddress(param);

      return getLocationData;
    } catch (err) {
      handleErrorResponse(err);
    }
  }

  // Handle form submission process
  async function submitForm(action, values) {
    try {
      let url;
      let payload = {
        date: new Date(values?.date),
        name: values?.name,
        type: values?.type,
        address: values?.address,
        remarks: values?.remarks,
        capacity: values?.capacity,
        associate: values.associate._id,
        timezone: values?.timezone,
        status: values?.status,
      };
      let request;

      if (action === 'add') {
        // Add Event
        url = '/api/events/add';
        request = await axios.post(url, JSON.stringify(payload), {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
      } else if (action === 'update') {
        // Update Event
        url = '/api/events/update';
        payload = {
          ...payload,
          _id: values._id,
        };

        request = await axios.put(url, JSON.stringify(payload), {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
      }

      const response = request?.data;

      if (request.status === 200 || request.status === 201) {
        const eventDetails = response.data;

        // get user Info baed on associate id
        const getAssociateInfo = await getUserInfo(eventDetails.associate);

        const finalEventDetails = {
          ...eventDetails,
          associate: getAssociateInfo,
        };

        // Update Event List
        getEventsList();

        // Update User Info
        setEventInfo({
          ...eventInfo,
          ...finalEventDetails,
        });
        setEventDialog({
          open: true,
          dialogTitle: 'Edit Event',
          dialogText: '',
          primaryButtonText: 'Update',
          secondaryButtonText: 'Close',
          dialogAction: 'update',
        });

        return response;
      }
      handleRegularResponse({
        open: true,
        status: response.status,
        message: response.message,
      });
    } catch (err) {
      handleErrorResponse(err);
      errRef.current?.focus();
    }
  }

  return (
    <Formik
      values={formik.values}
      initialValues={formik.initialValues}
      errors={formik.errors}
      validationSchema={eventValidationScheme}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {(formik) => {
        const {
          values,
          setValues,
          setTouched,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          setSubmitting,
          errors,
          touched,
          handleBlur,
          handleChange,
          resetForm,
        } = formik;
        return (
          <MapContext.Provider value={{ mapRedered, setMapRendered }}>
            <Form role="form">
              <FormControl fullWidth>
                {/* Add/Edit Contact dialog */}
                <Dialog maxWidth="md" fullWidth open={eventDialog.open}>
                  <DialogTitle>
                    <Grid
                      container
                      spacing={{ xs: 12 }}
                      justifyContent="center"
                      direction={{ xs: 'column', sm: 'column', md: 'row' }}
                    >
                      <Grid
                        item
                        container
                        xs={7}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        {eventDialog.dialogTitle}
                      </Grid>
                      <Grid
                        item
                        container
                        direction="row"
                        xs={5}
                        spacing={1}
                        alignItems="center"
                        justifyContent="flex-end"
                        sx={{
                          fontSize: '1.0rem',
                          color: 'var(--color-highlight)',
                        }}
                      >
                        <Grid item xs={7}>
                          {values?.created && `Created ${values?.created}`}
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    {isLoading?.status === true &&
                    isLoading?.type === 'skeleton' ? (
                      // Skeleton
                      <Stack
                        spacing={2}
                        style={classes.root}
                        sx={{ paddingBottom: '10px' }}
                      >
                        <DialogContentText
                          sx={{ color: 'var(--color-text-error-message)' }}
                        >
                          <Skeleton width="30%" height="30px">
                            <Typography>.</Typography>
                          </Skeleton>
                        </DialogContentText>

                        {/* Date */}
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="30px"
                        />

                        {/* Associate */}
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="30px"
                        />

                        {/* Event Date */}
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="30px"
                        />

                        {/* Event Type */}
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="30px"
                        />

                        {/* Event Name */}
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="30px"
                        />

                        {/* Event Location */}
                        <>
                          <Typography variant="h4">
                            {t('form.event.location')}
                          </Typography>
                          <Stack spacing={4} style={classes.root}>
                            {/* // Address Fields after user typed in his adddress */}
                            <>
                              {/* Line 1 */}
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="30px"
                              />

                              {/* Line 2 */}
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="30px"
                              />

                              <Stack
                                spacing={1}
                                direction={{ xs: 'column', sm: 'row' }}
                                style={classes.root}
                              >
                                {/* City  */}
                                <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height="30px"
                                />

                                {/* State */}
                                <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height="30px"
                                />

                                {/* Postal Code */}
                                <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height="30px"
                                />
                              </Stack>

                              {/* Country */}
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="30px"
                              />
                            </>
                          </Stack>

                          {/* Locations Map */}
                          {values?.address?.coordinates?.longitude &&
                            values?.address?.coordinates?.latitude && (
                              <MapLocationByAddressProvider>
                                <MapLocation />
                              </MapLocationByAddressProvider>
                            )}
                        </>

                        {/* Event Capacity */}
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="30px"
                        />

                        {/* Remarks */}
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="30px"
                        />

                        {/* Status */}
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="30px"
                        />
                      </Stack>
                    ) : (
                      //  Form
                      <>
                        <Stack
                          spacing={2}
                          style={classes.root}
                          sx={{ paddingBottom: '10px' }}
                        >
                          <DialogContentText
                            sx={{ color: 'var(--color-text-error-message)' }}
                          >
                            {eventDialog.dialogText}
                          </DialogContentText>

                          {/* Timezone */}
                          <TimeZonePicker />

                          <Typography variant="h5">
                            {t('form.event.details')}
                          </Typography>
                          {/* Associate */}
                          <Autocomplete
                            id="associate"
                            options={employeeOptions?.sort(
                              (a, b) => -b.name.localeCompare(a.name),
                            )}
                            getOptionLabel={(option) =>
                              (option?.name &&
                                option?.name + ' (' + option?.email + ')') ||
                              ''
                            }
                            isOptionEqualToValue={(option, value) => {
                              if (!value?.email) {
                                return true;
                              }
                              return option.email === value.email;
                            }}
                            className="form-select-field"
                            onBlur={handleBlur}
                            clearOnBlur={false}
                            value={values?.associate || null}
                            onChange={(e, options, reason, element) => {
                              if (reason === 'selectOption') {
                                setFieldValue('associate', element.option);
                              } else if (reason === 'clear') {
                                setFieldValue('associate', {
                                  name: '',
                                  email: '',
                                  _id: '',
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                margin="dense"
                                label={t('form.associate')}
                                type="text"
                                variant="standard"
                                error={
                                  errors?.associate?.name && touched.associate
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors?.associate?.name && touched?.associate
                                    ? errors.associate?.name
                                    : null
                                }
                              />
                            )}
                          />

                          {/* Event Type */}
                          <TextField
                            select
                            type="text"
                            id="type"
                            labelid="event-type-label"
                            label={t('form.event.type.label')}
                            name="type"
                            variant="standard"
                            value={values?.type}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value);
                            }}
                            onBlur={handleBlur}
                            error={errors.type && touched.type ? true : false}
                            helperText={
                              errors.type && touched.type ? errors.type : null
                            }
                          >
                            <MenuItem key={0} value="">
                              {t('form.event.type.select')}
                            </MenuItem>
                            <MenuItem key={1} value="sales">
                              {t('form.event.type.sales')}
                            </MenuItem>
                            <MenuItem key={2} value="vendor">
                              {t('form.event.type.vendor')}
                            </MenuItem>
                          </TextField>

                          {/* Event Name */}
                          <TextField
                            required
                            id="name"
                            name="name"
                            className="form-select-field"
                            aria-invalid={errors.first_name ? 'true' : 'false'}
                            aria-describedby="uidnote"
                            variant="standard"
                            label={t('form.event.name')}
                            type="text"
                            placeholder={t('form.event.name')}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value);
                            }}
                            onBlur={handleBlur}
                            value={values?.name}
                            error={errors.name && touched.name ? true : false}
                            helperText={
                              errors.name && touched.name ? errors.name : null
                            }
                          />

                          {/* Event Location */}
                          <>
                            <Typography variant="h4">
                              {t('form.event.location')}
                            </Typography>
                            <Stack spacing={4} style={classes.root}>
                              {/* // Address Fields after user typed in his adddress */}
                              <>
                                {/* Line 1 */}
                                <Autocomplete
                                  freeSolo
                                  autoComplete={false}
                                  includeInputInList
                                  filterSelectedOptions
                                  disableClearable={false}
                                  loading={isLoadingAddress}
                                  loadingText={t('form.address.loading')}
                                  id="address"
                                  name="address"
                                  ref={addressSearchRef}
                                  value={values.address}
                                  className="form-select-field"
                                  options={addressOptions}
                                  getOptionLabel={(option) =>
                                    option?.label
                                      ? option.label
                                      : values.address?.line1
                                  }
                                  filterOptions={(x) => x}
                                  noOptionsText={t('form.address.no_option')}
                                  renderOption={(props, option) => {
                                    return (
                                      <li {...props}>
                                        <Grid container alignItems="center">
                                          <Grid
                                            item
                                            sx={{ display: 'flex', width: 44 }}
                                          >
                                            <LocationOnIcon
                                              sx={{ color: 'text.secondary' }}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            sx={{
                                              width: 'calc(100% - 44px)',
                                              wordWrap: 'break-word',
                                            }}
                                          >
                                            <Box
                                              key={option.id}
                                              component="span"
                                            >
                                              {option.label}
                                            </Box>
                                            <Typography
                                              variant="body2"
                                              color="text.secondary"
                                            >
                                              {option.label}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </li>
                                    );
                                  }}
                                  onChange={async (e, option, reason) => {
                                    if (reason === 'selectOption') {
                                      isLoadingAddress = true;
                                      let id = option.id;
                                      let sessionID = user.sessionID;
                                      let url = '/api/mapbox/retrieve-address';

                                      const controller = new AbortController();
                                      const signal = controller.signal;

                                      const payload = { id, sessionID };
                                      try {
                                        const request = await axios.post(
                                          url,
                                          JSON.stringify(payload),
                                          {
                                            signal,
                                            headers: {
                                              'Content-Type':
                                                'application/json',
                                            },
                                            withCredentials: true,
                                          },
                                        );

                                        const response = request?.data;

                                        if (request.status === 200) {
                                          // Set Address Fields
                                          let data = response.data;

                                          // Coordinates
                                          setFieldValue(
                                            'address.coordinates',
                                            data?.coordinates,
                                          );
                                          setTimeout(
                                            () =>
                                              setFieldTouched(
                                                'address.coordinates',
                                              ),
                                            100,
                                          );

                                          // Country
                                          const countryDetails =
                                            countryOptions.filter(
                                              (country) =>
                                                country.code ===
                                                data.countryCode,
                                            );
                                          let country = {
                                            label: data.country,
                                            dialCode:
                                              countryDetails[0].dialCode,
                                            code: countryDetails[0].code,
                                          };
                                          setFieldValue(
                                            'address.country',
                                            country,
                                          );
                                          setTimeout(
                                            () =>
                                              setFieldTouched(
                                                'address.country',
                                              ),
                                            100,
                                          );

                                          // state
                                          setFieldValue(
                                            'address.state',
                                            data.region,
                                          );
                                          setTimeout(
                                            () =>
                                              setFieldTouched(
                                                'company.address.state',
                                              ),
                                            100,
                                          );

                                          // City
                                          setFieldValue(
                                            'address.city',
                                            data.place,
                                          );
                                          setTimeout(
                                            () =>
                                              setFieldTouched('address.city'),
                                            100,
                                          );

                                          // Postcode
                                          setFieldValue(
                                            'address.postcode',
                                            data.postcode,
                                          );
                                          setTimeout(
                                            () =>
                                              setFieldTouched(
                                                'address.postcode',
                                              ),
                                            100,
                                          );

                                          // Address
                                          setFieldValue(
                                            'address.line1',
                                            data.address,
                                          );
                                          setTimeout(
                                            () =>
                                              setFieldTouched('address.line1'),
                                            100,
                                          );

                                          setAddressSearch(option);
                                          // setFieldValue("address_search", option);
                                          // setTimeout(() => setFieldTouched("address_search"), 100);

                                          setMapAddress({
                                            name: values.name,
                                            street: data.address,
                                            postcode: data.postcode,
                                            state: data.region,
                                            country: data.country,
                                          });
                                        } else {
                                          setFieldValue(
                                            'address.line1',
                                            option,
                                          );
                                        }
                                      } catch (err) {
                                        handleErrorResponse(err);
                                      } finally {
                                        isLoadingAddress = false;
                                      }
                                    } else {
                                      // User hit return key

                                      // Set Country
                                      const countryDetails =
                                        countryOptions.filter(
                                          (country) =>
                                            country.code ===
                                            user?.location.country,
                                        );

                                      setValues({
                                        ...values,
                                        address: {
                                          line1: addressSearch,
                                          city: user?.location?.city,
                                          postcode: user?.location?.postcode,
                                          state: user?.location?.state,
                                          country: {
                                            label: countryDetails[0].label,
                                            dialCode:
                                              countryDetails[0].dialCode,
                                            code: user?.location?.country,
                                          },
                                        },
                                      });

                                      setTimeout(
                                        () =>
                                          setTouched({
                                            ...touched,
                                            address: true,
                                          }),
                                        100,
                                      );
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      autoComplete="off"
                                      onChange={async (e) => {
                                        let input = e.target.value;
                                        setAddressSearch(input);
                                        setFieldValue('address.line1', input);

                                        if (input.length > 5) {
                                          isLoadingAddress = true;
                                          let addressSearchFunction =
                                            setTimeout(async () => {
                                              let searchTextEncoded =
                                                encodeURIComponent(input);
                                              let position = user.location;
                                              let sessionID = user.sessionID;
                                              let url =
                                                '/api/mapbox/suggest-address';

                                              const controller =
                                                new AbortController();
                                              const signal = controller.signal;

                                              const payload = {
                                                searchText: searchTextEncoded,
                                                position,
                                                sessionID,
                                              };
                                              try {
                                                const request =
                                                  await axios.post(
                                                    url,
                                                    JSON.stringify(payload),
                                                    {
                                                      signal,
                                                      headers: {
                                                        'Content-Type':
                                                          'application/json',
                                                      },
                                                      withCredentials: true,
                                                    },
                                                  );

                                                const response = request?.data;

                                                if (request.status === 200) {
                                                  isLoadingAddress = false;
                                                  setAddressOptions(response);
                                                  // setFieldValue("address_options_company", response)
                                                }
                                              } catch (err) {
                                                isLoadingAddress = false;
                                                handleErrorResponse(err);
                                              } finally {
                                                clearTimeout(
                                                  addressSearchFunction,
                                                );
                                              }
                                            }, 100);
                                        }
                                      }}
                                      variant="standard"
                                      label={t('form.address.address')}
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'text',
                                        endAdornment: (
                                          <React.Fragment>
                                            {isLoadingAddress ? (
                                              <CircularProgress
                                                color="inherit"
                                                size={20}
                                              />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                      value={values?.address?.line1 || ''}
                                      error={
                                        touched?.address?.line1 &&
                                        errors?.address?.line1
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        touched?.address?.line1 &&
                                        errors?.address?.line1
                                          ? errors.address?.line1
                                          : null
                                      }
                                    />
                                  )}
                                />

                                {/* Line 2 */}
                                <TextField
                                  id="address.line2"
                                  name="address.line2"
                                  className="form-select-field"
                                  variant="standard"
                                  label={t('form.address.apt')}
                                  type="text"
                                  value={values?.address?.line2}
                                  onChange={(e) => {
                                    setFieldValue(
                                      e.target.name,
                                      e.target.value,
                                    );
                                  }}
                                  onBlur={handleBlur}
                                />

                                <Stack
                                  spacing={1}
                                  direction={{ xs: 'column', sm: 'row' }}
                                  style={classes.root}
                                >
                                  {/* City  */}
                                  <TextField
                                    id="address.city"
                                    name="address.city"
                                    autoComplete="off"
                                    required
                                    style={classes.root}
                                    className="form-select-field"
                                    variant="standard"
                                    label={t('form.address.city')}
                                    type="text"
                                    placeholder={t('form.address.city')}
                                    onChange={(e) => {
                                      setFieldValue(
                                        e.target.name,
                                        e.target.value,
                                      );
                                    }}
                                    onBlur={async (e) => {
                                      setFieldTouched(
                                        e.target.name,
                                        true,
                                        true,
                                      );

                                      // Map New Address
                                      let newAddress = {
                                        name: values?.name,
                                        street: values?.address.line1,
                                        postcode: values?.address.postcode,
                                        state: values?.address.state,
                                        country: values?.address.country.label,
                                      };
                                      setMapAddress(newAddress);

                                      // Update Coordinates
                                      const coordinates =
                                        await getUpdatedCoordinates(newAddress);
                                      if (coordinates.status === 'success') {
                                        setFieldValue('address.coordinates', {
                                          longitude: coordinates.location.lng,
                                          latitude: coordinates.location.lat,
                                        });
                                      }
                                    }}
                                    value={values?.address?.city}
                                    error={
                                      touched?.address?.city &&
                                      errors?.address?.city
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      touched?.address?.city &&
                                      errors?.address?.city
                                        ? errors.address?.city
                                        : null
                                    }
                                  />
                                  {/* State */}
                                  <TextField
                                    autoComplete="off"
                                    id="address.state"
                                    name="address.state"
                                    className="form-select-field"
                                    required
                                    variant="standard"
                                    label={t('form.address.state')}
                                    type="text"
                                    placeholder={t(
                                      'form.address.state_placeholder',
                                    )}
                                    onChange={(e) => {
                                      setFieldValue(
                                        e.target.name,
                                        e.target.value,
                                      );
                                    }}
                                    onBlur={async (e) => {
                                      setFieldTouched(
                                        e.target.name,
                                        true,
                                        true,
                                      );

                                      // Map New Address
                                      let newAddress = {
                                        name: values?.name,
                                        street: values?.address.line1,
                                        postcode: values?.address.postcode,
                                        state: values?.address.state,
                                        country: values?.address.country.label,
                                      };
                                      setMapAddress(newAddress);

                                      // Update Coordinates
                                      const coordinates =
                                        await getUpdatedCoordinates(newAddress);
                                      if (coordinates.status === 'success') {
                                        setFieldValue('address.coordinates', {
                                          longitude: coordinates.location.lng,
                                          latitude: coordinates.location.lat,
                                        });
                                      }
                                    }}
                                    value={values?.address?.state}
                                    error={
                                      touched?.address?.state &&
                                      errors?.address?.state
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      touched.address?.state &&
                                      errors.address?.state
                                        ? errors.address?.state
                                        : null
                                    }
                                  />
                                  {/* Postal Code */}
                                  <TextField
                                    required
                                    autoComplete="off"
                                    id="address.postcode"
                                    name="address.postcode"
                                    className="form-select-field"
                                    variant="standard"
                                    label={t('form.address.postcode')}
                                    type="text"
                                    placeholder={t('form.address.postcode')}
                                    onChange={(e) => {
                                      setFieldValue(
                                        e.target.name,
                                        e.target.value,
                                      );
                                    }}
                                    onBlur={async (e) => {
                                      setFieldTouched(
                                        e.target.name,
                                        true,
                                        true,
                                      );

                                      // Map New Address
                                      let newAddress = {
                                        name: values?.name,
                                        street: values?.address.line1,
                                        postcode: values?.address.postcode,
                                        state: values?.address.state,
                                        country: values?.address.country.label,
                                      };
                                      setMapAddress(newAddress);

                                      // Update Coordinates
                                      const coordinates =
                                        await getUpdatedCoordinates(newAddress);
                                      if (coordinates.status === 'success') {
                                        setFieldValue('address.coordinates', {
                                          longitude: coordinates.location.lng,
                                          latitude: coordinates.location.lat,
                                        });
                                      }
                                    }}
                                    value={values?.address?.postcode}
                                    error={
                                      touched?.address?.postcode &&
                                      errors?.address?.postcode
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      touched?.address?.postcode &&
                                      errors?.address?.postcode
                                        ? errors.address?.postcode
                                        : null
                                    }
                                  />
                                </Stack>

                                {/* Country */}
                                <Autocomplete
                                  required
                                  autoComplete={false}
                                  id="address.country"
                                  name="address.country"
                                  autoSelect={true}
                                  disableClearable
                                  options={countryOptions}
                                  getOptionLabel={(option) =>
                                    option?.label || ''
                                  }
                                  onChange={async (e, options) => {
                                    setFieldValue('address.country', options);
                                  }}
                                  onBlur={async (e) => {
                                    setFieldTouched(
                                      'address.country',
                                      true,
                                      true,
                                    );

                                    // Map New Address
                                    let newAddress = {
                                      name: values?.name,
                                      street: values?.address.line1,
                                      postcode: values?.address.postcode,
                                      state: values?.address.state,
                                      country: values?.address.country.label,
                                    };
                                    setMapAddress(newAddress);

                                    // Update Coordinates
                                    const coordinates =
                                      await getUpdatedCoordinates(newAddress);
                                    if (coordinates.status === 'success') {
                                      setFieldValue('address.coordinates', {
                                        longitude: coordinates.location.lng,
                                        latitude: coordinates.location.lat,
                                      });
                                    }
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option?.code === value?.code
                                  }
                                  defaultValue={countryOptions[230]}
                                  // defaultValue={countryDetails}
                                  value={values?.address?.country}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        '& > img': { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                      />
                                      {option?.label} ({option?.dialCode}) (
                                      {option?.code})
                                    </Box>
                                  )}
                                  label={t('form.address.country')}
                                  className="form-select-field"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      label={t('form.address.country_select')}
                                      inputProps={{
                                        ...params.inputProps,
                                      }}
                                      error={
                                        touched?.address?.country?.label &&
                                        errors?.address?.country?.label
                                          ? true
                                          : touched?.address?.country?.code &&
                                              errors?.address?.country?.code
                                            ? true
                                            : false
                                      }
                                      helperText={
                                        touched?.address?.country?.label &&
                                        errors?.address?.country?.label
                                          ? errors.address?.country.label
                                          : touched?.address?.country?.code &&
                                              errors?.address?.country?.code
                                            ? errors.address?.country.code
                                            : null
                                      }
                                    />
                                  )}
                                />
                              </>
                            </Stack>

                            {/* Locations Map */}
                            {values?.address?.coordinates?.longitude &&
                              values?.address?.coordinates?.latitude && (
                                <MapLocationByAddressProvider>
                                  <MapLocation />
                                </MapLocationByAddressProvider>
                              )}
                          </>

                          {/* Event Capacity */}
                          <TextField
                            required
                            id="capacity"
                            name="capacity"
                            label={t('form.event.capacity')}
                            className="form-select-field"
                            aria-describedby="uidnote"
                            variant="standard"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="number"
                            value={values.capacity}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />

                          {/* Remarks */}
                          <TextField
                            multiline
                            rows={4}
                            id="remarks"
                            name="remarks"
                            className="form-select-field"
                            aria-describedby="uidnote"
                            variant="standard"
                            label={t('form.event.remarks')}
                            type="text"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.remarks}
                            inputProps={{ style: { resize: 'vertical' } }}
                          />

                          {/* Status */}
                          <TextField
                            select
                            type="text"
                            id="status"
                            label={t('form.event.status')}
                            name="status"
                            variant="standard"
                            value={values?.status}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value);
                            }}
                            error={
                              errors?.status && touched?.status ? true : false
                            }
                            helperText={
                              errors?.status && touched?.status
                                ? errors?.status
                                : null
                            }
                          >
                            <MenuItem key={0} value="">
                              {t('form.status.select')}
                            </MenuItem>
                            <MenuItem key={1} value="pending">
                              {t('form.status.pending')}
                            </MenuItem>
                            <MenuItem key={2} value="active">
                              {t('form.status.active')}
                            </MenuItem>
                            <MenuItem key={3} value="archived">
                              {t('form.status.archived')}
                            </MenuItem>
                          </TextField>
                        </Stack>

                        {participantsList.length > 0 && <Participants />}
                      </>
                    )}

                    {/* <Typography variant="h1">Initial Values:</Typography>
                    {JSON.stringify(formik.initialValues)}

                    <Typography variant="h1">Values:</Typography>
                    {JSON.stringify(formik.values)}

                    <Typography variant="h1">Event Info:</Typography>
                    {JSON.stringify(eventInfo)}

                    <Typography variant="h1">Touched:</Typography>
                    {JSON.stringify(formik.touched)}

                    <Typography variant="h1">Errors:</Typography>
                    {JSON.stringify(formik.errors)}

                    <Typography variant="h1">Valid:</Typography>
                    {JSON.stringify(formik.isValid)}

                    <Typography variant="h1">Toggle State</Typography>
                    {JSON.stringify(eventDialog)} */}
                  </DialogContent>

                  <DialogActions>
                    {/* Close */}
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        resetForm();
                        setEventInfo(blankEventInfo);
                        setParticipantsList([]);
                        setEventDialog({
                          open: false,
                          dialogTitle: '',
                          dialogText: '',
                          primaryButtonText: '',
                          secondaryButtonText: '',
                          dialogAction: '',
                        });
                      }}
                    >
                      {eventDialog.secondaryButtonText}
                    </Button>

                    {/* Add/Update Button */}
                    <LoadingButton
                      variant="contained"
                      size="medium"
                      endIcon={<SendIcon />}
                      loading={isSubmitting}
                      loadingPosition="end"
                      color="primary"
                      disabled={
                        Object.keys(touched).length === 0 ||
                        Object.keys(errors).length > 0
                      }
                      type="submit"
                      onClick={async (e) => {
                        let action = eventDialog.dialogAction;
                        setSubmitting(true);

                        await submitForm(action, values)
                          .then(() => {
                            setSubmitting(false);
                          })
                          .catch((err) => {
                            console.log(err);
                            setSubmitting(false);
                          });
                      }}
                    >
                      {eventDialog.primaryButtonText}
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
              </FormControl>
            </Form>
          </MapContext.Provider>
        );
      }}
    </Formik>
  );
};

// Event Component
function EventsList(props) {
  // State & Context
  const { auth } = useAuth();

  const [eventsList, setEventsList] = useState();
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const effectRun = useRef(false);

  //   form Info
  const formTitle = props.formTitle;
  const formText = props.formText;

  // Google Event Tracker
  const { gaEventTracker } = useAnalyticsEventTracker();

  // Axios
  const axios = useAxiosPrivate();

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { LoadingSpinner, isLoading, setIsLoading } = useProcessingHandler();

  // Event Info
  const blankEventInfo = {
    _id: '',
    date: '',
    type: '',
    name: '',
    associate: {
      _id: '',
      name: '',
      email: '',
    },
    timezone: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postcode: '',
      country: { label: 'United States', dialCode: '+1', code: 'US' },
      coordinates: { latitude: '', longitude: '' },
    },
    remarks: '',
    capacity: '',
    status: '',
    created: '',
  };
  const [eventInfo, setEventInfo] = useState(blankEventInfo);
  const [eventDialog, setEventDialog] = useState({
    open: false,
    dialogTitle: '',
    dialogText: '',
    primaryButtonText: '',
    secondaryButtonText: '',
    dialogAction: '',
  });

  // Participants List
  const [participantsList, setParticipantsList] = useState([]);

  // Event List
  const eventListColumns = [
    {
      field: 'date',
      headerName: 'Event Date',
      editable: false,
      minWidth: 80,
      flex: 0.2,
      valueFormatter: (params) => moment(params?.value).format('MM/DD/YYYY'),
    },
    {
      field: 'name',
      headerName: 'Event Name',
      editable: false,
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: 'type_label',
      headerName: 'Type',
      editable: false,
    },
    {
      field: 'city',
      headerName: 'City',
      flex: 0.2,
      editable: false,
      valueGetter: (params) => params.row.address?.city || '',
    },
    {
      field: 'country',
      headerName: 'Country',
      flex: 0.25,
      editable: false,
      valueGetter: (params) => params.row.address?.country.label || '',
    },
    {
      field: 'participants',
      renderHeader: () => <ReceiptIcon fontSize="small" />,
      type: 'number',
      flex: 0.15,
      editable: false,
    },
    {
      field: 'capacity',
      renderHeader: () => <PeopleIcon fontSize="small" />,
      type: 'number',
      flex: 0.15,
      editable: false,
    },
    {
      field: 'status',
      renderHeader: () => <TrafficIcon fontSize="small" />,
      flex: 0.2,
      editable: false,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const controller = new AbortController();
          const signal = controller.signal;

          // Get List of Participants
          try {
            setIsLoading({
              status: true,
              type: 'spinner',
              text: 'Getting Event Details...',
            });
            let url = '/api/events/details';
            let payload = { event_id: params.row._id };

            const request = await axios.post(url, JSON.stringify(payload), {
              signal,
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true,
            });
            const response = request.data;

            if (request.status === 200) {
              const eventData = response.data;
              const eventDetails = eventData.event_details;
              const participantsList = eventData.participants_list;

              // get user Info baed on associate id
              const getAssociateInfo = await getUserInfo(
                eventDetails.associate,
              );

              const finalEventDetails = {
                ...eventDetails,
                associate: getAssociateInfo,
              };

              // Set Participants
              setParticipantsList(participantsList);

              // Set Event Info
              setEventInfo({
                ...blankEventInfo,
                ...finalEventDetails,
              });
            }
          } catch (err) {
            if (err.name === 'CanceledError') return;
            console.log(err);
          } finally {
            setIsLoading({ status: false, type: '', text: '' });
          }

          setTimeout(() => {
            setEventDialog({
              open: true,
              dialogTitle: 'Edit Event',
              dialogText: '',
              primaryButtonText: 'Update',
              secondaryButtonText: 'Close',
              dialogAction: 'update',
            });
          }, 100);
        };

        return (
          <Button variant="contained" color="tertiary" onClick={onClick}>
            Edit
          </Button>
        );
      },
    },
  ];

  // Get User Info
  async function getUserInfo(id) {
    const url = '/api/users/search';

    const controller = new AbortController();
    const signal = controller.signal;

    let payload = {
      key: '_id',
      values: id,
    };

    try {
      const request = await axios.post(url, JSON.stringify(payload), {
        signal,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      const response = request?.data;

      if (request.status === 200) {
        const [userData] = response.data;

        return {
          _id: userData._id,
          name: `${userData.first_name} ${userData.last_name}`,
          email: userData.email,
        };
      }
    } catch (error) {
      console.log(error);
    }
  }

  const MemoizedRow = React.memo(GridRow);
  const MemoizedColumnHeaders = React.memo(GridColumnHeaders);

  const getEventsList = async () => {
    setIsLoading({ status: true, type: 'skeleton', text: '' });

    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const url = '/api/events/list';
      const payload = {};
      // const request = await axios.post(url, { signal });
      const request = await axios.get(url, JSON.stringify(payload), {
        signal,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      const response = request.data;

      if (request.status === 200) {
        let eventListRaw = response.data;

        if (response.data.length > 0) {
          let eventListNice = [];

          eventListRaw.map((event) => {
            eventListNice.push({
              _id: event?._id,
              availability: event?.availability || 0,
              participants: event?.participants || 0,
              created: event?.created,
              address: event?.address,
              name: event?.name,
              remarks: event?.remarks,
              type_label: toTitleCase(event?.type),
              type: event?.type,
              status: toTitleCase(event?.status),
              date: event?.date,
              capacity: event?.capacity,
            });
            return null;
          });
          setEventsList(eventListNice);
        }
      }
    } catch (err) {
      if (err.name === 'CanceledError') return;
      console.log(err);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
    }
  };

  // Viewport
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 767) {
        setMobileView(false);
      } else if (window.innerWidth < 767) {
        setMobileView(true);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
  });

  // Pre-Load Info
  useEffect(() => {
    let isMounted = true;
    setIsLoading({ status: true, type: 'skeleton', text: '' });

    const controller = new AbortController();
    const signal = controller.signal;

    // Get Employees from Database
    async function handleGetEmployees() {
      try {
        const payload = {
          key: '_id',
          value: auth.user_info._id,
        };

        const employeesList = await getIntutecEmployees(payload);
        if (employeesList.status === 'success') {
          setEmployeeOptions(employeesList.data);
        }
      } catch (err) {
        handleErrorResponse(err);
      }
    }

    const PreLoadForm = async () => {
      await getEventsList();
      await handleGetEmployees();
    };
    PreLoadForm();

    return () => {
      isMounted = false;
      setIsLoading({ status: false, type: '', text: '' });
    };
  }, []);

  return (
    <>
      <EventContext.Provider
        value={{
          blankEventInfo,
          eventInfo,
          setEventInfo,
          eventDialog,
          setEventDialog,
          participantsList,
          setParticipantsList,
          handleErrorResponse,
          handleRegularResponse,
          isLoading,
          setIsLoading,
          employeeOptions,
          setEmployeeOptions,
          mobileView,
          MemoizedRow,
          MemoizedColumnHeaders,
          getEventsList,
          gaEventTracker,
          getUserInfo,
        }}
      >
        <Grid
          container
          className="content"
          sx={{ marginBottom: '8rem' }}
          spacing={5}
        >
          <Grid item className="title content-centered" xs={12}>
            <Typography variant="h1">{formTitle}</Typography>
            <Typography variant="body1">{formText}</Typography>
          </Grid>
          <Grid item className="content" xs={12}>
            {/* Skeleton List */}
            {isLoading?.status === true && isLoading?.type === 'skeleton' ? (
              <Stack direction="column" spacing={-1}>
                <Stack spacing={1} direction="row" height={50}>
                  <Typography width="25%">{t('form.first_name')}</Typography>
                  <Typography width="25%">{t('form.last_name')}</Typography>
                  <Typography width="25%">
                    {t('form.company.company')}
                  </Typography>
                </Stack>
                <Stack spacing={1} direction="row" height={50}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                </Stack>
                <Stack spacing={1} direction="row" height={50}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                </Stack>
                <Stack spacing={1} direction="row" height={50}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                </Stack>
                <Stack spacing={1} direction="row" height={50}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                </Stack>
                <Stack spacing={1} direction="row" height={50}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                </Stack>
                <Stack spacing={1} direction="row" height={50}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                </Stack>
                <Stack spacing={1} direction="row" height={50}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="25%"
                  />
                </Stack>
              </Stack>
            ) : !eventsList?.length ? (
              <Grid item className="title content-centered" xs={12}>
                <Typography variant="h5">
                  No contact requests to display...Check back later!
                </Typography>
              </Grid>
            ) : (
              <Box sx={{ height: { xs: '100%', md: '70%' }, width: '100%' }}>
                <DataGrid
                  isCellEditable={(params) => false}
                  getRowId={(row) => row._id}
                  rows={eventsList}
                  columns={eventListColumns}
                  columnVisibilityModel={{
                    // Hide columns status and traderName, the other columns will remain visible
                    state: !mobileView,
                    city: !mobileView,
                    country: !mobileView,
                    type_label: !mobileView,
                    status: !mobileView,
                    participants: !mobileView,
                    capacity: !mobileView,
                    availability: !mobileView,
                  }}
                  components={{
                    Row: MemoizedRow,
                    ColumnHeaders: MemoizedColumnHeaders,
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 50,
                      },
                    },
                    sorting: {
                      sortModel: [
                        {
                          field: 'date',
                          sort: 'asc',
                        },
                      ],
                    },
                  }}
                  sx={{
                    '&::-webkit-scrollbar': {
                      width: 20,
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'orange',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'red',
                      borderRadius: 2,
                    },
                  }}
                  pageSizeOptions={[50, 100]}
                  checkboxSelection={!mobileView}
                  disableRowSelectionOnClick
                  slots={{ toolbar: GridToolbar }}
                />
              </Box>
            )}

            {/* Event Form */}
            <EventForm />

            {/* CTA */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
            >
              <Grid
                container
                item
                xs={4}
                justifyContent="center"
                alignItems="center"
                sx={{ paddingRight: '20px' }}
              >
                <Stack
                  spacing={1}
                  direction={{ xs: 'column', sm: 'row' }}
                  style={classes.root}
                  sx={{ paddingBottom: '50px' }}
                >
                  {/* Refresh Events */}
                  <Button
                    variant="contained"
                    type="submit"
                    color="info"
                    onClick={() => getEventsList()}
                    sx={{ minWidth: 200, maxHeight: 40 }}
                  >
                    {t('actions.refresh', { type: t('types.event_list') })}
                  </Button>

                  {/* Add New Event */}
                  <Button
                    variant="contained"
                    type="submit"
                    endIcon={<RocketLaunchIcon />}
                    onClick={() => {
                      setEventDialog({
                        open: true,
                        type: 'lead',
                        title: 'New Event',
                        text: 'Enter all Event Details!',
                        primaryButtonText: 'Add',
                        secondaryButtonText: 'Close',
                        dialogAction: 'add',
                      });

                      setEventInfo({
                        ...blankEventInfo,
                        ...eventInfo,
                        associate: {
                          _id: auth?.user_info?._id,
                          name: auth?.user_info?.full_name,
                          email: auth?.user_info?.email,
                        },
                        status: 'pending',
                      });
                    }}
                    sx={{ minWidth: 150, maxHeight: 40 }}
                  >
                    {t('types.new', { type: t('types.event') })}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </EventContext.Provider>
    </>
  );
}

export { EventsList };
