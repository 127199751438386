import { useContext, useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { classes } from '../../../settings/theme.js';
import { FormControl, Grid, Slider, Stack } from '@mui/material';
import { Typography } from '@mui/material';
import PercentIcon from '@mui/icons-material/Percent';
import { Formik, useFormik } from 'formik';
import StyledInput from '../../../mui/styledInput.js';
import { LeadContext } from '../../../context/providers/leads/LeadContextProvider.jsx';
import { useTranslation } from 'react-i18next';
import { formatValueAsStringWithSign } from '../../../helpers/formatValueAsStringWithSign.js';

const RevenueCalculator = () => {
  const { revenueCommission } = useContext(LeadContext);

  const marketSize = 11860000000;

  const { t } = useTranslation('translation', {
    keyPrefix: 'context.general.investor',
  });

  // Initial Values
  const formik = useFormik({
    initialValues: {},
    initialTouched: {},
    values: {},
  });

  const [initialRevenue, setInitialRevenue] = useState({
    revenue: 0,
    commission_order_percent: 0,
    commissions_per_order_percent: 0,
  });

  formik.initialValues = {
    ...revenueCommission,
  };

  const marketShareValueMarks = [
    {
      value: 1,
      label: '1 %',
    },
    {
      value: 10,
      label: '10 %',
    },
    {
      value: 25,
      label: '20 %',
    },
    {
      value: 50,
      label: '50 %',
    },
    {
      value: 75,
      label: '75 %',
    },
  ];

  const calculateRevenue = (value, values) => {
    let newMarketShare = value;
    let commissionOrderPercent = values?.commission_order_percent;

    // Revenue
    let targetRevenue = (newMarketShare * marketSize) / 100;
    let revenue = Math.round(commissionOrderPercent * targetRevenue);

    return {
      revenue: revenue,
      market_share: newMarketShare,
      commission_order_percent: commissionOrderPercent,
      commissions_per_order_percent: (commissionOrderPercent * 100).toFixed(2),
    };
  };

  useEffect(() => {
    const result = calculateRevenue(
      formik.initialValues.market_share,
      formik.initialValues,
    );
    setInitialRevenue(result);
    setTimeout(() => {
      formik.setTouched({
        revenue: true,
        market_share: true,
        commission_order_percent: true,
        commissions_per_order_percent: true,
      });
    }, 1000);
  }, [revenueCommission]);

  return (
    <Grid
      container
      alignItems={{ xs: 'center', md: 'flex-start' }}
      alignContent={{ xs: 'center' }}
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 'auto' }}
      spacing={2}
    >
      <Grid item container xs={12}>
        <Formik
          values={formik.values}
          initialValues={initialRevenue}
          initialTouched={formik.initialTouched}
          errors={formik.errors}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => {
            const { values, setFieldValue, setValues } = formik;
            return (
              <FormControl fullWidth>
                {/* Revenue Calculator */}
                <Stack
                  spacing={5}
                  style={classes.root}
                  sx={{ paddingBottom: '10px' }}
                >
                  {/* Market Share Slider */}
                  <Typography id="product-value-commission" gutterBottom>
                    {t('commission.calculator.market_share_slider')}
                  </Typography>
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ mb: 1 }}
                    alignItems="center"
                  >
                    <PercentIcon fontSize="small" />
                    <Slider
                      aria-label={t(
                        'commission.calculator.market_share_slider_label',
                      )}
                      aria-labelledby="product-value-commission"
                      getAriaValueText={(value) =>
                        formatValueAsStringWithSign(value, '%')
                      }
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) =>
                        formatValueAsStringWithSign(value, '%')
                      }
                      size="medium"
                      onChange={(e, value) =>
                        setFieldValue('market_share', value)
                      }
                      onChangeCommitted={(e, value) => {
                        const result = calculateRevenue(value, values);
                        setValues({
                          ...values,
                          ...result,
                        });
                      }}
                      value={values.market_share}
                      step={1}
                      min={1}
                      max={100}
                      marks={marketShareValueMarks}
                    />
                    <PercentIcon fontSize="large" />
                  </Stack>
                  <Typography>
                    {t('commission.calculator.market_share_statement')}
                  </Typography>
                  {/* Revenue */}
                  <Stack
                    spacing={2}
                    style={classes.root}
                    sx={{ paddingBottom: '50px' }}
                    direction="row"
                  >
                    {/* Commission per Order % */}
                    <StyledInput
                      disabled
                      id="commission_order_percent"
                      name="commission_order_percent"
                      autoComplete="off"
                      className="form-select-field"
                      aria-describedby="uidnote"
                      variant="outlined"
                      label={t(
                        'commission.calculator.revenue_commission_order_label',
                      )}
                      type="text"
                      // defaultValue={values.commissions_per_order_percent}
                      value={values.commissions_per_order_percent}
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                    />
                    {/* Commission per Order in US$ */}
                    <StyledInput
                      disabled
                      id="revenue"
                      name="revenue"
                      autoComplete="off"
                      className="form-select-field"
                      aria-describedby="uidnote"
                      variant="outlined"
                      label={t(
                        'commission.calculator.revenue_commission_order_us_label',
                      )}
                      // defaultValue={revenueCommission.revenue}
                      value={values.revenue.toLocaleString()}
                      type="text"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      endAdornment={
                        <InputAdornment position="end">US$</InputAdornment>
                      }
                    />
                  </Stack>
                </Stack>
              </FormControl>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export { RevenueCalculator };
