import * as Yup from 'yup';

export const createNewProductValidationSchema = (t) => {
  return Yup.object({
    product_name: Yup.string()
      .min(2, t('short'))
      .max(50, t('long'))
      .required(t('product.name_required')),
    mpn: Yup.string()
      .min(2, t('short'))
      .max(50, t('long'))
      .required(t('product.mpn_required')),
    list_price: Yup.number()
      .positive('Price must be a positive number')
      .required(t('product.price_required')),
    // minimum_price: Yup.number().positive('Price must be a positive number'),
    //   .required(t('product.price_required')),
    currency: Yup.string().required('Required'),
    //   product_types: Yup.array()
    //     .of(Yup.string().required('String is required'))
    //     .min(1, t('product.min')),
    //   product_attributes: Yup.array()
    //     .of(Yup.string().required('String is required'))
    //     .min(1, t('product.min')),
    //   product_applications: Yup.array()
    //     .of(Yup.string().required('String is required'))
    //     .min(1, t('product.app_min')),
  });
};
