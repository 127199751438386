import { useMemo } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Typography,
} from '@mui/material';

const formatAttributeType = (attributeType) => {
  return `${attributeType[0].toUpperCase()}${attributeType.slice(1)}`;
};

const AttributeTypeBox = ({
  options,
  handleAttributeTypeSelect,
  selectedAttributeType,
}) => {
  const theme = useTheme();

  const attributeTypes = useMemo(() => {
    if (!options.length) return new Set();
    const attributeTypeSet = new Set();
    options.forEach((option) => {
      attributeTypeSet.add(option.attribute_type);
    });
    return attributeTypeSet;
  }, [options]);

  const handleListItemClick = (e, attributeType) => {
    e.stopPropagation();
    handleAttributeTypeSelect(attributeType);
  };

  return (
    <Box flex={1}>
      <Typography variant="h6" textTransform="none" p={0}>
        Attribute Type
      </Typography>
      <List>
        {attributeTypes.map((attributeType) => {
          const isSelected = selectedAttributeType === attributeType;
          return (
            <ListItem
              key={attributeType}
              component="button"
              onMouseDown={(e) => handleListItemClick(e, attributeType)}
              sx={{
                background: isSelected
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
                border: 'unset',
                borderRadius: '10px',
                boxShadow: theme.shadows[6],
                margin: '10px 0',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                padding: '4px 0',
                transform: isSelected ? 'translateY(0px)' : 'translateY(-2px)',
                transition: 'all 0.15s ease-in-out',
                '&:hover': {
                  background: theme.palette.secondary.main,
                  transform: isSelected
                    ? 'translateY(0px)'
                    : 'translateY(-4px)',
                },
                '&:active': {
                  background: theme.palette.secondary.main,
                  transform: 'translateY(0px)',
                },
              }}
            >
              <ListItemText primary={formatAttributeType(attributeType)} />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default AttributeTypeBox;
