import React, { useContext } from 'react';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Skeleton,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../../helpers/getNestedValuesIn';
import FormikFieldWrapper from '../../../formik/FormikFieldWrapper';

const AccountStatusField = ({
  fieldTitle, // field Title
  fieldDescription, // Field Description
  fieldID = 'account_status', // ID of the Field
  transition = true, // Fade in = true and Fade Out = false
  required = false, // Whethher the field is required or not
  disabled = false, // Whether the Field is disabled
}) => {
  const { t } = useTranslation('fields', { keyPrefix: 'AccountStatusField' });

  const theme = useTheme();

  // Context
  const { values, setFieldValue, touched, errors, handleBlur } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <FormControl disabled={disabled}>
        <Select
          required={required}
          disabled={disabled}
          type="text"
          id={fieldID}
          name={fieldID}
          variant="standard"
          value={fieldValue}
          label={t('label')}
          onBlur={handleBlur}
          onChange={(e) => {
            setFieldValue(e.target.name, e.target.value);
          }}
          error={fieldError && fieldTouched ? true : false}
        >
          <MenuItem key={0} value="">
            {t('select')}
          </MenuItem>
          <MenuItem key={1} value="pending">
            {t('pending')}
          </MenuItem>
          <MenuItem key={2} value="active">
            {t('active')}
          </MenuItem>
          <MenuItem key={3} value="suspended">
            {t('suspended')}
          </MenuItem>
        </Select>
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {errors[fieldID] && touched[fieldID] && errors[fieldID]}
        </FormHelperText>
      </FormControl>
    </FormikFieldWrapper>
  );
};

export default AccountStatusField;
