import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  InputLabel,
  TextField,
} from '@mui/material';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import AddIcon from '@mui/icons-material/Add';
import axios from '../../hooks/axios/axios';
import NewAttributeDialog from './NewAttributeDialog';
import { v4 as uuidv4 } from 'uuid';

const isObject = (value) => {
  return Object.prototype.toString.call(value) === '[object Object]';
};

export const NewOption = ({ handleClick, label }) => {
  return (
    <Button
      variant="outlined"
      fullWidth
      startIcon={<AddIcon />}
      onClick={handleClick}
      sx={{ mt: 2 }}
    >
      New {label}
    </Button>
  );
};

const NewAttributeAutocomplete = ({
  setIsUploadDocumentDialogOpen,
  handleNewAttribute,
}) => {
  const [options, setOptions] = useState([]);
  const [isNewAttributeDialogOpen, setIsNewAttributeDialogOpen] =
    useState(false);

  useEffect(() => {
    getAttributesOptions();
  }, []);

  const getAttributesOptions = async () => {
    try {
      const response = await axios.get('/api/product-attributes');
      const { data, status } = response;
      if (status === 200) {
        let options = [];
        console.log('data pre', data.data);
        for (const item of data.data) {
          if (Array.isArray(item.value)) {
            // console.log('item', item);
            let outputLabel = '';
            for (const innerItem of item.value) {
              // console.log('inner', innerItem);
              if (typeof innerItem === 'string') {
                options.push({
                  label: item.attribute,
                  value: innerItem,
                });
              } else {
                const key = Object.keys(innerItem)[0];
                const value = innerItem[key];
                outputLabel += `${key} ${value}, `;
              }
            }

            options.push({
              label: item.attribute,
              value: outputLabel,
            });
          } else {
            options.push({
              label: item.attribute,
              value: item.value,
            });
          }
        }
        setOptions(options);
      }
    } catch (error) {
      console.error(error, 'error');
    }
  };

  const handleConfirm = (newAttribute) => {
    setIsNewAttributeDialogOpen(false);
    handleNewAttribute(newAttribute);
  };

  const handleAutocompleteChange = (e, newValue) => {
    handleNewAttribute(e, newValue[0].label);
  };

  return (
    <>
      <Box display="flex" alignItems="flex-end" mb={2}>
        <InputLabel id={'new-product-attributes'} sx={{ fontSize: '1.2rem' }}>
          Attributes
        </InputLabel>
        <Button
          variant="contained"
          startIcon={<DocumentScannerIcon />}
          sx={{ ml: 2, textTransform: 'none' }}
          onClick={() => setIsUploadDocumentDialogOpen(true)}
        >
          Fetch Attributes from Datasheet
        </Button>
      </Box>
      <Autocomplete
        multiple
        disablePortal
        id="new-product-attributes-autocomplete"
        options={options}
        renderOption={(props, option, state) => {
          if (state.index === options.length - 1) {
            return (
              <NewOption
                key={uuidv4()}
                handleClick={() => setIsNewAttributeDialogOpen(true)}
                label="Attribute"
              />
            );
          }
          return (
            <li {...props} key={uuidv4()}>
              {option.label} - {option.value}
            </li>
          );
        }}
        selectOnFocus
        handleHomeEndKeys
        name="product_attributes"
        onChange={handleAutocompleteChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Select a Product Attribute or enter a new one and hit enter"
            name="product_attributes"
          />
        )}
        sx={{ mb: 2 }}
      />
      <NewAttributeDialog
        isOpen={isNewAttributeDialogOpen}
        handleConfirm={handleConfirm}
        handleClose={() => setIsNewAttributeDialogOpen(false)}
      />
    </>
  );
};
export default NewAttributeAutocomplete;
