import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Typography,
  TextField,
} from '@mui/material';

export const formatAttribute = (attribute) => {
  if (attribute.includes('_')) {
    return attribute
      .split('_')
      .map((str) => `${str[0].toUpperCase()}${str.slice(1)}`)
      .join(' ');
  }

  return `${attribute[0].toUpperCase()}${attribute.slice(1)}`;
};

const AttributeBox = ({
  options,
  handleAttributeSelect,
  selectedAttribute,
  selectedAttributeType,
  selectedAttributes,
}) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');

  const handleClick = (attribute) => {
    handleAttributeSelect(attribute);
    setSearchValue('');
  };

  const attributes = useMemo(() => {
    if (!options.length || !selectedAttributeType) return new Set();
    const attributeSet = new Set();
    options.forEach((option) => {
      if (option.attribute_type === selectedAttributeType) {
        attributeSet.add(option.attribute);
      }
    });
    return attributeSet;
  }, [options, selectedAttributeType]);

  const searchedValues = useMemo(() => {
    if (!searchValue.length) return attributes;
    return attributes.filter((a) => a.includes(searchValue));
  }, [attributes, searchValue]);

  const handleNewAttribute = () => {
    handleAttributeSelect(searchValue, true);
  };

  useEffect(() => {
    setSearchValue('');
  }, [selectedAttributeType, selectedAttributes]);

  const handleSearchInputKeyDown = (e) => {
    if (e.keyCode === 13 && !searchedValues.size) {
      handleNewAttribute();
    } else if (e.keyCode === 13) {
      handleAttributeSelect(searchedValues.values().next().value);
    }
  };

  return (
    <Box flex={1}>
      {attributes.size ? (
        <Typography variant="h6" textTransform="none" p={0} mb={2}>
          Attribute
        </Typography>
      ) : null}

      {selectedAttributeType ? (
        <TextField
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleSearchInputKeyDown}
          placeholder="Search for an attribute"
          tabIndex={0}
        />
      ) : null}
      <List>
        {searchedValues.map((attribute) => {
          const isSelected = selectedAttribute === attribute;
          // console.log('isSelected', isSelected, selectedAttribute, attribute);
          return (
            <ListItem
              key={attribute}
              component="button"
              tabIndex={-1}
              onMouseDown={() => handleClick(attribute)}
              sx={{
                background: isSelected
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
                border: 'unset',
                borderRadius: '10px',
                boxShadow: theme.shadows[6],
                margin: '10px 0',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                padding: '4px 0',
                transform: isSelected ? 'translateY(0px)' : 'translateY(-2px)',
                transition: 'all 0.15s ease-in-out',
                '&:hover': {
                  background: theme.palette.secondary.main,
                  transform: isSelected
                    ? 'translateY(0px)'
                    : 'translateY(-4px)',
                },
                '&:active': {
                  background: theme.palette.secondary.main,
                  transform: 'translateY(0px)',
                },
              }}
            >
              <ListItemText primary={formatAttribute(attribute)} />
            </ListItem>
          );
        })}
        {searchValue.length && !searchedValues.size ? (
          <ListItem
            component="button"
            onClick={handleNewAttribute}
            sx={{
              // background: isSelected
              //   ? theme.palette.secondary.main
              //   : theme.palette.primary.main,
              background: theme.palette.primary.main,
              border: 'unset',
              borderRadius: '10px',
              boxShadow: theme.shadows[6],
              margin: '10px 0',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              padding: '4px 0',
              transform: 'translateY(-2px)',
              transition: 'all 0.15s ease-in-out',
              // '&:hover': {
              //   background: theme.palette.secondary.main,
              //   transform: isSelected
              //     ? 'translateY(0px)'
              //     : 'translateY(-4px)',
              // },
              '&:active': {
                background: theme.palette.secondary.main,
                transform: 'translateY(0px)',
              },
            }}
          >
            <ListItemText primary={'Add New Value'} />
          </ListItem>
        ) : null}
      </List>
    </Box>
  );
};

export default AttributeBox;
