import { useContext, useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ApplicationsContext } from '../../../context/providers/ApplicationsContextProvider.jsx';
import { ProductTypeContext } from '../../providers/ProductTypeContextProvider.jsx';
import { CompanyProfileContext } from '../../providers/CompanyProfileProvider.jsx';
import { BrandsContext } from '../../providers/BrandsProvider.jsx';
import CompanyNameField from '../../../components/account/company/CompanyNameField.jsx';
import CompanyBrandsField from '../../../components/account/company/CompanyBrandsField.jsx';
import EmailField from '../../../components/account/EmailField.jsx';
import WebsiteField from '../../../components/account/WebsiteField.jsx';
import PhoneField from '../../../components/account/PhoneField .jsx';
import AddressField from '../../../components/address/AddressField.jsx';
import useUser from '../../../hooks/useUser.js';
import useAuth from '../../../hooks/useAuth.js';
import ApplicationsField from '../../../components/applications/ApplicationsField.jsx';
import ProductTypeField from '../../../components/product-type/ProductTypeField.jsx';
import i18n from 'i18next';
import { TermsOfServiceAgreement } from '../../../components/account/tos-ppa-ssa/TermsOfServiceAccept.jsx';
import PrivacyPolicyAgreement from '../../../components/account/tos-ppa-ssa/PrivacyPolicyAccept.jsx';
import StripeServiceAgreement from '../../../components/account/tos-ppa-ssa/StripeServiceAccept.jsx';
import { PrivacyContent } from '../../../components/privacy/PrivacyContent.jsx';
import { TermsOfUseContent } from '../../../components/terms/TermsOfUseContent.jsx';

import ConsentDialog from '../../../components/account/tos-ppa-ssa/ConsentDialog.jsx';
import { TermsOfServiceContext } from '../../providers/TermsOfServicecontextProvider.jsx';

const NewCompanyForm = () => {
  // Translation
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.company.NewCompanyForm',
  });

  const { setNewApplicationList } = useContext(ApplicationsContext);
  const { setNewProductTypeList } = useContext(ProductTypeContext);
  const { newCompany, setNewCompany, setCompanyProfile } = useContext(
    CompanyProfileContext,
  );
  const { setNewBrandsList } = useContext(BrandsContext);

  const {
    initialValues,
    values,
    errors,
    touched,
    resetForm,
    validateForm,
    isValid,
  } = useFormikContext();

  const { auth } = useAuth();
  const { user } = useUser();

  const { dialogType } = useContext(TermsOfServiceContext);

  const { t: transFields } = useTranslation('fields');
  const { t: transTypes } = useTranslation('types');

  const [titleIndustries, setTitleIndustries] = useState('');
  const [titleProducts, setTitleProducts] = useState('');
  const [descriptionIndustries, setDescriptionIndustries] = useState('');
  const [descriptionProducts, setDescriptionProducts] = useState('');

  const handleClear = () => {
    // Clear Child Foem
    resetForm(initialValues);
    setTimeout(() => validateForm(), 300);

    // Clear Parent Form Values
    setNewCompany(true);
    setNewApplicationList([]);
    setNewProductTypeList([]);
    setNewBrandsList([]);
    setCompanyProfile({});
  };

  useEffect(() => {
    // Set Form Fabels
    let userLabel;
    if (auth.user_info.account_type === 'manufacturer') {
      // User Label
      userLabel = t('role.manufacturer');

      // Form subtitled
      setTitleIndustries(t('applications.manufacturer.title'));
      setTitleProducts(t('products.manufacturer.description'));
    } else if (auth.user_info.account_type === 'salesrep') {
      // User Label
      userLabel = t('global.label.salesrep');

      // Form subtitled
      setTitleIndustries(t('applications.salesrep.title'));
      setTitleProducts(t('products.salesrep.title'));
    } else if (auth.user_info.account_type === 'influencer') {
      // User Label
      userLabel = t('global.label.influencer');

      // Form subtitled
      setTitleIndustries(t('applications.influencer.title'));
      setTitleProducts(t('products.influencer.description'));
    }

    setDescriptionIndustries(
      transFields('global.info.multiSelect', {
        type: transTypes('applications'),
      }),
    );

    setDescriptionProducts(
      transFields('global.info.multiSelect', {
        type: transTypes('products'),
      }),
    );
  }, [auth?.user_info?.account_type, user.location, i18n.language]);

  return (
    <>
      <Stack spacing={4} sx={{ paddingBottom: '50px' }}>
        {(newCompany === false || values?.isSubsidiary) && (
          <>
            <Button variant="outlined" color="error" onClick={handleClear}>
              {i18n.t('buttons.clear')}
            </Button>
          </>
        )}

        {/* Company Name */}
        <CompanyNameField
          fieldTitle={t('company.title')}
          fieldDescription={t('company.description')}
          fieldID="name"
          transition
          required
          showAdmins={true}
          transNS="fields"
          transPrefix="account.company.CompanyNameField"
        />

        {/* Company Brands */}
        <CompanyBrandsField
          fieldTitle={t('brands.title')}
          fieldDescription={t('brands.description')}
          fieldID="brands"
          // disabled={values.isSubsidiary}
          subsidiaryIDField="isSubsidiary"
          parentFieldID="parent_id"
          claimerName="name"
          claimerID="_id"
          disabled={!values.name || values.isSubsidiary}
          required={
            auth?.user_info?.account_type === 'manufacturer' &&
            (newCompany === true || values?.isSubsidiary === false)
              ? true
              : false
          }
        />

        {/* Address Field */}
        <AddressField
          fieldTitle={t('address.title')}
          fieldDescription={t('address.description')}
          labelID={values?.name}
          autocompleteID="address"
          fieldID="address"
          transNS="fields"
          phoneFieldID="phone"
          transPrefix="AddressField"
          required
          disabled={!newCompany}
        />

        {/* Website Field */}
        <WebsiteField
          fieldTitle={t('website.title')}
          fieldDescription={t('website.description')}
          transition={
            (!errors?.company?.address?.line1 &&
              !errors?.company?.address?.city &&
              !errors?.company?.address?.postcode &&
              !errors?.company?.address?.state &&
              !errors?.company?.address?.country.code) ||
            touched?.company?.website
              ? true
              : false
          }
          disabled={newCompany === false}
          required
          fieldID="website"
          transNS="fields"
          transPrefix="account.company.WebsiteField"
        />

        {/* Company Email */}
        <EmailField
          fieldTitle={t('email.title')}
          fieldDescription={t('email.description')}
          fieldID="email"
          transNS="fields"
          transPrefix="account.company.EmailField"
          required
          disabled={newCompany === false}
        />

        {/* Company Phone */}
        <PhoneField
          fieldTitle={t('phone.title')}
          fieldDescription={t('phone.description')}
          fieldID="phone"
          required
          disabled={!newCompany}
          transNS="fields"
          transPrefix="account.company.PhoneField"
        />

        {/* Applications Field */}
        <ApplicationsField
          fieldTitle={titleIndustries}
          fieldDescription={descriptionIndustries}
          required
        />

        {/* Product Types Field */}
        <ProductTypeField
          fieldTitle={titleProducts}
          fieldDescription={descriptionProducts}
          required
        />

        {/* Terms of Service */}
        <TermsOfServiceAgreement
          fieldTitle={t('tos.title')}
          fieldDescription={t('tos.description')}
          required
        />

        {/* Privacy Policy */}
        <PrivacyPolicyAgreement
          fieldTitle={t('ppa.title')}
          fieldDescription={t('ppa.description')}
          required
        />

        {/* Stripe Service Agreemnt */}

        <StripeServiceAgreement
          fieldTitle={t('ssa.title')}
          countryID="address.country"
          fieldDescription={t('ssa.description')}
          required
        />
      </Stack>

      <ConsentDialog>
        {dialogType === 'ppa' && <PrivacyContent />}
        {dialogType === 'tos' && <TermsOfUseContent />}
      </ConsentDialog>

      <Typography variant="h4">Account Type</Typography>
      <Typography variant="body1">
        {JSON.stringify(auth.user_info.account_type)}
      </Typography>

      <Typography variant="h4">New Company</Typography>
      <Typography variant="body1">{JSON.stringify(newCompany)}</Typography>

      <Typography variant="h4">Subsidiary</Typography>
      <Typography variant="body1">
        {JSON.stringify(values.isSubsidiary)}
      </Typography>

      <Typography variant="h4">Values</Typography>
      <Typography variant="body1">{JSON.stringify(values)}</Typography>
      <br />

      <Typography variant="h4">Errors</Typography>
      <Typography variant="body1">{JSON.stringify(errors)}</Typography>
      <br />

      <Typography variant="h4">Touched</Typography>
      <Typography variant="body1">{JSON.stringify(touched)}</Typography>
      <br />

      <Typography variant="h4">Is Valid</Typography>
      <Typography variant="body1">{JSON.stringify(isValid)}</Typography>
      <br />
    </>
  );
};

export { NewCompanyForm };
