import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import CampaignIcon from '@mui/icons-material/Campaign';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import FeedIcon from '@mui/icons-material/Feed';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { useTheme } from '@mui/styles';
import { NavBarDrawerContext } from '../../../context/providers/NavBarDrawerContextProvider';
import useAuth from '../../../hooks/useAuth';
import { motion } from 'framer-motion';
import { NavList, NavItem } from '../../../framer-motion/animations';
import { useTranslation } from 'react-i18next';

const UserNavItems = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { auth } = useAuth();
  const { handleDrawerClose } = useContext(NavBarDrawerContext);

  const { t } = useTranslation('buttons', {
    keyPrefix: 'navigation',
  });

  const roleNavItems = [
    {
      index: 1,
      key: 'SalesTeam',
      label: t('sales_team'),
      icon: <PeopleIcon />,
      target: () => handleNavigate('SalesTeam'),
      visible: true,
    },
    {
      index: 2,
      key: 'Campaigns',
      label: t('campaigns'),
      icon: <CampaignIcon />,
      target: () => handleNavigate('Campaigns'),
      visible: true,
    },
    {
      index: 3,
      key: 'Leads',
      label: t('leads'),
      icon: <LocalMallIcon />,
      target: () => handleNavigate('Leads'),
      visible: true,
    },
    {
      index: 4,
      key: 'Posts',
      label: t('posts'),
      icon: <FeedIcon />,
      target: () => handleNavigate('Posts'),
      event: 'Posts',
      visible: 'drawer',
    },
    {
      index: 5,
      key: 'Products',
      label: t('products'),
      icon: <PrecisionManufacturingIcon />,
      target: () => handleNavigate('Products'),
      visible: auth?.user_info?.account_type === 'manufacturer',
    },
  ];

  function handleNavigate(target) {
    if (target === 'SalesTeam') {
      return navigate('./company/salesteam');
    } else if (target === 'Campaigns') {
      return navigate('./company/campaigns');
    } else if (target === 'Products') {
      return navigate('./manage-products/list');
    } else if (target === 'Leads') {
      return navigate('./company/leads');
    } else if (target === 'Posts') {
      return navigate('./user/posts');
    }
  }

  return (
    auth?.user_info?.account_complete &&
    roleNavItems.map(
      (menuItem) =>
        menuItem.visible && (
          <motion.div
            variants={NavList}
            initial="hidden"
            animate="visible"
            key={menuItem.key}
          >
            <ListItem key={menuItem.key} sx={{ display: 'block' }}>
              <motion.div variants={NavItem} whileHover={{ scale: 1.1 }}>
                <ListItemButton
                  sx={{
                    height: '2rem',
                    justifyContent: 'initial',
                    px: 2.5,
                  }}
                  onClick={() => {
                    menuItem.target();
                    handleDrawerClose();
                  }}
                >
                  <ListItemIcon sx={theme.components.DrawerListItem}>
                    {menuItem.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={<>{menuItem.label}</>}
                    disableTypography
                  />
                </ListItemButton>
              </motion.div>
            </ListItem>
          </motion.div>
        ),
    )
  );
};

export default UserNavItems;
