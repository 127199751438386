import { useContext } from 'react';
import mathiasKrostewitz from '../../../assets/how-it-works/mk.png';
import philOh from '../../../assets/how-it-works/po.png';
import edgarCastillo from '../../../assets/how-it-works/ez.png';
import vision from '../../../assets/find.png';
import mission from '../../../assets/accelerate.png';
import commission from '../../../assets/Reward.png';
import AdCampaigns from '../../../assets/how-it-works/AdCampaigns.png';
import Reporting from '../../../assets/how-it-works/Reporting.png';
import ReactHtmlParser from 'react-html-parser';
import videoIntro from '../../../assets/videos/Investor-Video.mp4';
import { Avatar, Box, Grid, useTheme } from '@mui/material';
import { Typography } from '@mui/material';
import { Video } from '../../../plugins/BackgroundVideoPlayer.jsx';
import { PieChartContext } from '../../../charts/pieChart.js';
import { ForceDirectedTreeContext } from '../../../charts/forceDirectedTree.js';
import { MapChartContext } from '../../../charts/mapChart.js';
import { TreeChartContext } from '../../../charts/treeChart.js';
import { LeadContext } from '../../../context/providers/leads/LeadContextProvider.jsx';
import { useTranslation, Trans } from 'react-i18next';
import i18n from 'i18next';
import { InvestorCommissionCalculator } from '../calculators/CommissionCalculator.jsx';
import { InvestorHeaderContent } from '../headers/InvestorHeaderContent.jsx';
import * as am5 from '@amcharts/amcharts5';
import { RevenueCalculator } from '../calculators/RevenueCalculator.jsx';
import useIntuTheme from '../../../hooks/useIntuTheme.js';

// Colors for Data
const intuLightPurple = '#e8d46f';
const intuOrange = '#d76446';
const intuLightBlue = '#6ea4d4';
const intuDarkGreen = '#A8C957';
const intuDarkGrey = '#333333';
const intuMiddleGrey = '#6F7978';

const InvestorContent = () => {
  const { leadInfo } = useContext(LeadContext);
  const { mobileView } = useIntuTheme();
  const theme = useTheme();
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.general.investor.lead_context',
  });

  const marketSize = 11860000000;

  function processCompetitorSalesData(competitorSalesData, marketSize) {
    let totalDetailedRevenue = 0;
    let dataChildren = [];

    competitorSalesData.forEach((channelData) => {
      let channelInfo = Object.values(channelData)[0];
      let children = [];
      let channelRevenue = 0;

      if (channelInfo.type === 'estimate' || channelInfo.type === 'detailed') {
        if (channelInfo.type === 'detailed') {
          channelInfo.data.forEach((competitorData) => {
            let revenue = competitorData.revenue;
            totalDetailedRevenue += revenue;
            let marketShare =
              Math.round((revenue / marketSize) * 100 * 1e2) / 1e2;
            competitorData.revenue = marketShare;
            let activeCountries = competitorData.activeCountries.join(' / ');

            let formattedRevenue = formatRevenue(revenue);
            children.push({
              name: competitorData.name,
              image: competitorData.image,
              value: Math.round(marketShare),
              // context: `Revenue: ~${formattedRevenue} | Market Share: ~${marketShare}% | Active Countries: ${competitorData.activeCountries.join(" / ")}`,
              context: i18n.t(
                'context.general.investor.lead_context.detailed_competitor_sales_data_context',
                {
                  formattedRevenue: formattedRevenue,
                  marketShare: marketShare,
                  activeCountries: activeCountries,
                },
              ),
              color: '#ededed',
            });
            channelRevenue += revenue;
          });
        } else if (channelInfo.type === 'estimate') {
          channelRevenue = Math.round(
            (channelInfo.percentage * marketSize) / 100,
          );
          totalDetailedRevenue += channelRevenue;
        }

        let formattedChannelRevenue = formatRevenue(channelRevenue);
        // let channelMarketShare = ((channelRevenue / marketSize) * 100).toFixed(2);
        let channelMarketShare =
          Math.round((channelRevenue / marketSize) * 100 * 1e2) / 1e2;
        channelInfo.value = channelMarketShare;

        dataChildren.push({
          ...channelInfo,
          // context: `Total Revenue: ~${formattedChannelRevenue} | Market Share: ~${channelMarketShare}%`,
          context: i18n.t(
            'context.general.investor.lead_context.estimate_competitor_sales_data_context',
            {
              formattedChannelRevenue: formattedChannelRevenue,
              channelMarketShare: channelMarketShare,
            },
          ),
          value: Math.round(channelMarketShare),
          color: channelInfo.color,
          children: children,
        });
      }
    });

    // Calculating the "remainder" type after all the "detailed" and "estimate" types are processed
    competitorSalesData.forEach((channelData) => {
      let channelInfo = Object.values(channelData)[0];
      if (channelInfo.type === 'remainder') {
        channelInfo.revenue = marketSize - totalDetailedRevenue;
        let formattedChannelRevenue = formatRevenue(channelInfo.revenue);
        let channelMarketShare =
          Math.round((channelInfo.revenue / marketSize) * 100 * 1e2) / 1e2;
        dataChildren.push({
          ...channelInfo,
          // context: `Total Revenue: ~${formattedChannelRevenue} | Market Share: ~${channelMarketShare}%`,
          context: i18n.t(
            'context.general.investor.lead_context.estimate_competitor_sales_data_context',
            {
              formattedChannelRevenue: formattedChannelRevenue,
              channelMarketShare: channelMarketShare,
            },
          ),
          value: Math.round(channelMarketShare),
          color: channelInfo.color,
          children: [],
        });

        // Add remainder Revenue to Total
        totalDetailedRevenue += channelInfo.revenue;
      }
    });

    // let overallMarketShare = ((totalDetailedRevenue / marketSize) * 100).toFixed(2);
    let overallMarketShare =
      Math.round((totalDetailedRevenue / marketSize) * 100 * 1e2) / 1e2;
    let formattedTotalRevenue = formatRevenue(totalDetailedRevenue);

    return {
      id: 'marketPlayers',
      description: i18n.t(
        'context.general.investor.lead_context.market_players_desc',
      ),
      data: [
        {
          name: i18n.t(
            'context.general.investor.lead_context.market_players_data_name',
          ),
          value: 100,
          context: i18n.t(
            'context.general.investor.lead_context.market_players_data_context',
            {
              formattedTotalRevenue: formattedTotalRevenue,
              overallMarketShare: overallMarketShare,
            },
          ),
          children: dataChildren,
        },
      ],
    };
  }

  function formatRevenue(revenue) {
    let revenueBillions = revenue / 1000000000;
    if (revenueBillions >= 1) {
      return `${revenueBillions.toFixed(1)} Billion`;
    } else {
      return `${(revenue / 1000000).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} Mio.`;
    }
  }

  // Market Players Chart
  let competitorSalesData = [
    {
      eCommerce: {
        type: 'detailed',
        name: i18n.t(
          'context.general.investor.lead_context.players_chart.ecommerce_name',
        ),
        context: i18n.t(
          'context.general.investor.lead_context.players_chart.ecommerce_context',
        ),
        color: '#6F7978',
        data: [
          {
            name: 'Automation Direct',
            revenue: 181000000,
            activeCountries: ['US', 'CA', 'MX'],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/Automation_Direct.svg',
          },
          {
            name: 'Allied',
            revenue: 282500000,
            activeCountries: ['US'],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/Allied_Logo.svg',
          },
          {
            name: 'Automation24',
            revenue: 5400000,
            activeCountries: ['DE'],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/Automation24-Logo.svg',
          },
          {
            name: 'McMaster Carr',
            revenue: 662000000,
            activeCountries: ['US', 'CA', 'MX'],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/McMaster-Carr-Logo.svg',
          },
          {
            name: 'Others',
            revenue: 400000000,
            activeCountries: ['US', 'CA', 'MX'],
            image: '',
          },
        ],
      },
    },
    {
      onlineDirectories: {
        type: 'detailed',
        name: i18n.t(
          'context.general.investor.lead_context.players_chart.online_directories_name',
        ),
        color: '#B8A5C9',
        draggable: true,
        data: [
          {
            name: 'Thomas Net',
            revenue: 282500000,
            activeCountries: ['US'],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/Thomas_Net_Logo.svg',
          },
          {
            name: 'Direct Industry',
            revenue: 18600000,
            activeCountries: [
              i18n.t(
                'context.general.investor.lead_context.players_chart.direct_industry_active_countries',
              ),
            ],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/Direct_Industry_Logo.svg',
          },
          {
            name: 'Radwell',
            revenue: 286000000,
            activeCountries: ['US, DE'],
            image:
              'https://in2tec.nyc3.digitaloceanspaces.com/investors/Radwell-Logo.svg',
          },
        ],
      },
    },
    {
      offlinedistributors: {
        type: 'estimate',
        name: i18n.t(
          'context.general.investor.lead_context.players_chart.offline_distributors_name',
        ),
        color: '#5779C9',
        percentage: 45,
      },
    },
    {
      direct: {
        type: 'remainder',
        name: i18n.t(
          'context.general.investor.lead_context.players_chart.direct_name',
        ),
        color: '#7A579C',
      },
    },
  ];
  const marketPlayers = processCompetitorSalesData(
    competitorSalesData,
    marketSize,
  );

  // Chart Data
  const marketSizeChart = {
    id: 'marketSizeChart',
    description: i18n.t(
      'context.general.investor.lead_context.market_size_chart.description',
    ),
    data: [
      {
        name: i18n.t(
          'context.general.investor.lead_context.market_size_chart.north_america',
        ),
        code: ['northAmerica'],
        latitude: 39.563353,
        longitude: -99.316406,
        color: '#A8C957',
        marketSize: 2.04,
        year: 2022,
        marketSizeUnit: 'Bill. $',
        value: 50,
        CAGR: 8.4,
        data: [
          'AG',
          'BS',
          'BB',
          'BZ',
          'CA',
          'CR',
          'CU',
          'DM',
          'DO',
          'SV',
          'GD',
          'GT',
          'HT',
          'HN',
          'JM',
          'MX',
          'NI',
          'PA',
          'KN',
          'LC',
          'VC',
          'TT',
          'US',
        ],
      },
      {
        name: i18n.t(
          'context.general.investor.lead_context.market_size_chart.south_america',
        ),
        code: ['southAmerica'],
        latitude: -17.995042363908354,
        longitude: -59.05840629780355,
        color: '#6ea4d4',
        marketSize: 0.877,
        year: 2022,
        marketSizeUnit: 'Bill. $',
        value: 50,
        CAGR: 7.4,
        data: [
          'AR',
          'BO',
          'BR',
          'CL',
          'CO',
          'CR',
          'CU',
          'DO',
          'EC',
          'SV',
          'GT',
          'HN',
          'MX',
          'NI',
          'PA',
          'PY',
          'PE',
          'PR',
          'UY',
          'VE',
          'BZ',
          'GY',
          'SR',
        ],
      },
      {
        name: i18n.t(
          'context.general.investor.lead_context.market_size_chart.asia',
        ),
        code: ['asia', 'oceania'],
        color: '#e8d46f',
        latitude: 47.212106,
        longitude: 103.183594,
        marketSize: 4.698,
        year: 2022,
        marketSizeUnit: 'Bill. $',
        value: 50,
        CAGR: 10.8,
        data: [
          'AF',
          'AU',
          'BD',
          'BN',
          'BT',
          'KH',
          'CN',
          'TL',
          'FJ',
          'IN',
          'ID',
          'JP',
          'KI',
          'KP',
          'KR',
          'LA',
          'MY',
          'MV',
          'MH',
          'FM',
          'MM',
          'NR',
          'NP',
          'NZ',
          'PK',
          'PG',
          'PH',
          'PW',
          'WS',
          'SB',
          'LK',
          'TH',
          'TO',
          'TV',
          'VN',
          'VU',
          'TM',
          'UZ',
          'TJ',
          'KG',
          'MN',
        ],
      },
      {
        name: i18n.t(
          'context.general.investor.lead_context.market_size_chart.africa',
        ),
        code: ['africa'],
        color: '#ededed',
        latitude: 11.081385,
        longitude: 21.621094,
        year: 2022,
        marketSize: 0.379,
        value: 50,
        marketSizeUnit: 'Bill. $',
        CAGR: 3.3,
        data: [
          'DZ',
          'AO',
          'BJ',
          'BW',
          'BF',
          'BI',
          'CV',
          'CM',
          'CF',
          'TD',
          'KM',
          'CD',
          'CG',
          'DJ',
          'EG',
          'GQ',
          'ER',
          'SZ',
          'ET',
          'GA',
          'GM',
          'GH',
          'GN',
          'GW',
          'CI',
          'KE',
          'LS',
          'LR',
          'LY',
          'MG',
          'MW',
          'ML',
          'MR',
          'MU',
          'YT',
          'MA',
          'MZ',
          'NA',
          'NE',
          'NG',
          'RE',
          'RW',
          'ST',
          'SN',
          'SC',
          'SL',
          'SO',
          'ZA',
          'SS',
          'SD',
          'TZ',
          'TG',
          'TN',
          'UG',
          'EH',
          'ZM',
          'ZW',
          'BH',
          'CY',
          'IR',
          'IQ',
          'IL',
          'JO',
          'KW',
          'LB',
          'OM',
          'PS',
          'QA',
          'SA',
          'SY',
          'TR',
          'AE',
          'YE',
        ],
      },
      {
        name: i18n.t(
          'context.general.investor.lead_context.market_size_chart.europe',
        ),
        code: ['europe'],
        color: '#d76446',
        latitude: 50.896104,
        longitude: 19.160156,
        marketSize: 3.867,
        year: 2022,
        marketSizeUnit: 'Bill. $',
        value: 50,
        CAGR: 9.8,
        data: [
          'AL',
          'AD',
          'AM',
          'AT',
          'AZ',
          'BY',
          'BE',
          'BA',
          'BG',
          'HR',
          'CY',
          'CZ',
          'DK',
          'EE',
          'FI',
          'FR',
          'GE',
          'DE',
          'GR',
          'HU',
          'IS',
          'IE',
          'IT',
          'KZ',
          'XK',
          'LV',
          'LI',
          'LT',
          'LU',
          'MT',
          'MD',
          'MC',
          'ME',
          'NL',
          'MK',
          'NO',
          'PL',
          'PT',
          'RO',
          'RU',
          'SM',
          'RS',
          'SK',
          'SI',
          'ES',
          'SE',
          'CH',
          'TR',
          'UA',
          'GB',
          'VA',
        ],
      },
    ],
  };

  // Traditional Sales Channels
  let traditionalChannelDrillDown = {
    id: 'traditionalSalesChannelDrillDown',
    title: i18n.t('context.general.investor.lead_context.trad_sales_title'),
    titleColor: intuDarkGrey,
    titleBackgroundColor: intuOrange,
    data: {
      name: i18n.t('context.general.investor.lead_context.manufacturer'),
      circleSize: 40,
      image:
        'https://in2tec.nyc3.digitaloceanspaces.com/investors/Manufacturer-Icon.svg',
      children: [
        {
          name: i18n.t(
            'context.general.investor.lead_context.manufacturer_rep',
          ),
          circleSize: 40,
          image:
            'https://in2tec.nyc3.digitaloceanspaces.com/investors/Manufacturer-Rep.svg',
          children: [
            {
              name: i18n.t('context.general.investor.lead_context.distributor'),
              circleSize: 40,
              color: intuOrange,
              image:
                'https://in2tec.nyc3.digitaloceanspaces.com/investors/SubDistributor.svg',
              children: [
                {
                  name: i18n.t(
                    'context.general.investor.lead_context.sub_distributor',
                  ),
                  circleSize: 40,
                  color: intuLightPurple,
                  image:
                    'https://in2tec.nyc3.digitaloceanspaces.com/investors/SubDistributor.svg',
                  children: [
                    {
                      name: i18n.t(
                        'context.general.investor.lead_context.sales_rep',
                      ),
                      circleSize: 40,
                      color: intuLightBlue,
                      image:
                        'https://in2tec.nyc3.digitaloceanspaces.com/investors/Sales-Rep.svg',
                      children: [
                        {
                          name: mobileView
                            ? i18n.t(
                                'context.general.investor.lead_context.customer_mobile',
                              )
                            : i18n.t(
                                'context.general.investor.lead_context.customer',
                              ),
                          circleSize: 30,
                          color: intuLightBlue,
                          image:
                            'https://in2tec.nyc3.digitaloceanspaces.com/investors/Customer-Icon.svg',
                        },
                        {
                          name: mobileView
                            ? i18n.t(
                                'context.general.investor.lead_context.customer_mobile',
                              )
                            : i18n.t(
                                'context.general.investor.lead_context.customer',
                              ),
                          circleSize: 30,
                          color: intuLightBlue,
                          image:
                            'https://in2tec.nyc3.digitaloceanspaces.com/investors/Customer-Icon.svg',
                        },
                        {
                          name: mobileView
                            ? i18n.t(
                                'context.general.investor.lead_context.customer_mobile',
                              )
                            : i18n.t(
                                'context.general.investor.lead_context.customer',
                              ),
                          circleSize: 30,
                          color: intuLightBlue,
                          image:
                            'https://in2tec.nyc3.digitaloceanspaces.com/investors/Customer-Icon.svg',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: i18n.t('context.general.investor.lead_context.distributor'),
          circleSize: 40,
          color: intuOrange,
          image:
            'https://in2tec.nyc3.digitaloceanspaces.com/investors/SubDistributor.svg',
          children: [
            {
              name: i18n.t(
                'context.general.investor.lead_context.sub_distributor',
              ),
              circleSize: 40,
              color: intuLightPurple,
              image:
                'https://in2tec.nyc3.digitaloceanspaces.com/investors/SubDistributor.svg',
              children: [
                {
                  name: i18n.t(
                    'context.general.investor.lead_context.sales_rep',
                  ),
                  circleSize: 40,
                  color: intuLightBlue,
                  image:
                    'https://in2tec.nyc3.digitaloceanspaces.com/investors/Sales-Rep.svg',
                  children: [
                    {
                      name: mobileView
                        ? i18n.t(
                            'context.general.investor.lead_context.customer_mobile',
                          )
                        : i18n.t(
                            'context.general.investor.lead_context.customer',
                          ),
                      circleSize: 30,
                      color: intuLightBlue,
                      image:
                        'https://in2tec.nyc3.digitaloceanspaces.com/investors/Customer-Icon.svg',
                    },
                    {
                      name: mobileView
                        ? i18n.t(
                            'context.general.investor.lead_context.customer_mobile',
                          )
                        : i18n.t(
                            'context.general.investor.lead_context.customer',
                          ),
                      circleSize: 30,
                      color: intuLightBlue,
                      image:
                        'https://in2tec.nyc3.digitaloceanspaces.com/investors/Customer-Icon.svg',
                    },
                    {
                      name: mobileView
                        ? i18n.t(
                            'context.general.investor.lead_context.customer_mobile',
                          )
                        : i18n.t(
                            'context.general.investor.lead_context.customer',
                          ),
                      circleSize: 30,
                      color: intuLightBlue,
                      image:
                        'https://in2tec.nyc3.digitaloceanspaces.com/investors/Customer-Icon.svg',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  };

  // Our Sales Channel Sales Channels
  let ourSalesChannelDrillDown = {
    id: 'ourSalesChannelDrillDown',
    title: i18n.t('context.general.investor.lead_context.our_sales_title'),
    titleColor: intuDarkGrey,
    titleBackgroundColor: intuDarkGreen,
    data: {
      name: i18n.t('context.general.investor.lead_context.manufacturer'),
      circleSize: 40,
      image:
        'https://in2tec.nyc3.digitaloceanspaces.com/investors/Manufacturer-Icon.svg',
      children: [
        {
          name: 'IN2TEC',
          circleSize: 40,
          image:
            'https://in2tec.nyc3.digitaloceanspaces.com/investors/INTU-Logo-Square-Dark-Background.svg',
          color: '#333333',
          children: [
            {
              name: i18n.t('context.general.investor.lead_context.sales_rep'),
              circleSize: 40,
              color: intuLightBlue,
              image:
                'https://in2tec.nyc3.digitaloceanspaces.com/investors/Sales-Rep.svg',
              children: [
                {
                  name: mobileView
                    ? i18n.t(
                        'context.general.investor.lead_context.customer_mobile',
                      )
                    : i18n.t('context.general.investor.lead_context.customer'),
                  circleSize: 30,
                  color: intuLightBlue,
                  image:
                    'https://in2tec.nyc3.digitaloceanspaces.com/investors/Customer-Icon.svg',
                },
                {
                  name: mobileView
                    ? i18n.t(
                        'context.general.investor.lead_context.customer_mobile',
                      )
                    : i18n.t('context.general.investor.lead_context.customer'),
                  circleSize: 30,
                  color: intuLightBlue,
                  image:
                    'https://in2tec.nyc3.digitaloceanspaces.com/investors/Customer-Icon.svg',
                },
                {
                  name: mobileView
                    ? i18n.t(
                        'context.general.investor.lead_context.customer_mobile',
                      )
                    : i18n.t('context.general.investor.lead_context.customer'),
                  circleSize: 30,
                  color: intuLightBlue,
                  image:
                    'https://in2tec.nyc3.digitaloceanspaces.com/investors/Customer-Icon.svg',
                },
              ],
            },

            {
              name: i18n.t('context.general.investor.lead_context.influencer'),
              circleSize: 40,
              color: intuLightBlue,
              image:
                'https://in2tec.nyc3.digitaloceanspaces.com/investors/Sales-Rep.svg',
              children: [
                {
                  name: mobileView
                    ? i18n.t(
                        'context.general.investor.lead_context.customer_mobile',
                      )
                    : i18n.t('context.general.investor.lead_context.customer'),
                  circleSize: 30,
                  color: intuLightBlue,
                  image:
                    'https://in2tec.nyc3.digitaloceanspaces.com/investors/Customer-Icon.svg',
                },
                {
                  name: mobileView
                    ? i18n.t(
                        'context.general.investor.lead_context.customer_mobile',
                      )
                    : i18n.t('context.general.investor.lead_context.customer'),
                  circleSize: 30,
                  color: intuLightBlue,
                  image:
                    'https://in2tec.nyc3.digitaloceanspaces.com/investors/Customer-Icon.svg',
                },
                {
                  name: mobileView
                    ? i18n.t(
                        'context.general.investor.lead_context.customer_mobile',
                      )
                    : i18n.t('context.general.investor.lead_context.customer'),
                  circleSize: 30,
                  color: intuLightBlue,
                  image:
                    'https://in2tec.nyc3.digitaloceanspaces.com/investors/Customer-Icon.svg',
                },
              ],
            },
          ],
        },
      ],
    },
  };

  // Potential Revenue Streams
  const potentialRevenueStreams = {
    id: 'potentialRevenueStreams',
    descriptionColor: 'tertiary',
    data: [
      {
        value: 40,
        category: i18n.t(
          'context.general.investor.lead_context.potential_revenue.sales_commission',
        ),
        seriesColor: am5.color('#A8C957'),
      },
      {
        value: 25,
        category: i18n.t(
          'context.general.investor.lead_context.potential_revenue.advertising',
        ),
        seriesColor: am5.color('#A8C957'),
      },
      {
        value: 20,
        category: i18n.t(
          'context.general.investor.lead_context.potential_revenue.reporting',
        ),
        seriesColor: am5.color('#7c8082'),
      },
      {
        value: 15,
        category: i18n.t(
          'context.general.investor.lead_context.potential_revenue.other',
        ),
        seriesColor: am5.color('#7c8082'),
      },
    ],
  };

  return (
    <>
      <InvestorHeaderContent leadInfo={leadInfo} />
      <Grid>
        <Grid
          item
          container
          alignItems="flex-start"
          direction="column"
          className="intu__divider"
          sx={{ padding: '2rem 0 2rem 0', margin: '0 0 2rem 0' }}
        >
          <Grid
            container
            spacing={2}
            alignItems={{ md: 'flex-start' }}
            direction={{ xs: 'column', md: 'row' }}
            sx={{ padding: '0 3rem 0 3rem' }}
          >
            <Grid item xs={12}>
              {mobileView ? (
                <Typography variant="h2" className="green-text" align="center">
                  {leadInfo?.headline_title}
                </Typography>
              ) : (
                <Typography variant="h2" className="green-text">
                  {leadInfo?.headline_title}
                </Typography>
              )}
            </Grid>
            {/* Intro Text */}
            <Grid item xs={12} md={7}>
              <Typography variant="body">
                {ReactHtmlParser(leadInfo?.headline_body)}
              </Typography>
            </Grid>
            {/* Intro Video */}
            <Grid item xs={12} md={5}>
              <Video blur={0} videoSource={videoIntro} />
            </Grid>
          </Grid>
        </Grid>

        {/* Our Vision */}
        <Grid
          item
          container
          alignItems="flex-start"
          direction="column"
          className="intu__divider"
          sx={{ padding: '2rem 0 0 0', margin: '0 0 2rem 0' }}
        >
          <Grid
            container
            spacing={2}
            alignItems={{ md: 'flex-start' }}
            direction={{ xs: 'column', md: 'row' }}
            sx={{ padding: '0 3rem 0 3rem' }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h2"
                color="primary"
                textAlign={{ xs: 'center', md: 'left' }}
              >
                {t('our_vision')}
              </Typography>
            </Grid>

            {/* Text */}
            <Grid item xs={7} md={7}>
              <Typography
                variant="statement"
                textAlign={{ xs: 'center', md: 'left' }}
              >
                <Trans i18nKey="context.general.investor.lead_context.our_vision_text" />
              </Typography>
            </Grid>
            {/* Picture */}

            <Grid
              item
              xs={12}
              md={5}
              container
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              alignItems={{ xs: 'center', md: 'flex-start' }}
            >
              <Box
                component="img"
                sx={{
                  width: '80%',
                  height: '80%',
                  maxHeight: { xs: 250, md: 600 },
                  maxWidth: { xs: 250, md: 550 },
                }}
                alt={t('our_vision')}
                src={vision}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Our Mission */}
        <Grid
          item
          container
          alignItems="flex-start"
          direction="column"
          className="intu__divider"
          sx={{ padding: '2rem 0 0 0', margin: '0 0 2rem 0' }}
        >
          <Grid
            container
            spacing={2}
            alignItems={{ md: 'flex-start' }}
            direction={{ xs: 'column', md: 'row' }}
            sx={{ padding: '0 3rem 0 3rem' }}
          >
            <Grid item xs={12}>
              <Typography variant="h2" color="primary" align="center">
                {t('our_mission')}
              </Typography>
            </Grid>

            {/* Text */}
            <Grid item xs={7} md={7} textAlign={{ xs: 'center', md: 'left' }}>
              <Typography
                variant="statement"
                textAlign={{ xs: 'center', md: 'left' }}
              >
                {/* We <span style={{ fontWeight: "bold" }}>disrupt</span> traditional sales, distribution channels with a true <span style={{ fontWeight: "bold" }}>marketplace</span> for technology products.
                            <br /><br />

                            This <span style={{ fontWeight: "bold" }}>enables brands</span> to stay in <span style={{ fontWeight: "bold" }}>control</span> of their <span style={{ fontWeight: "bold" }}>brand</span>  storiesand ensure their unique selling points are communicated properly. We market their products and allow them <span style={{ fontWeight: "bold" }}>direct customer access</span> through direct marketing and sales.
                            <br /><br />

                            We welcome all <span style={{ fontWeight: "bold" }}>sales reps</span> to take on any brands and  <span style={{ fontWeight: "bold" }}>compensate</span> them through a clearly structured  <span style={{ fontWeight: "bold" }}>tiered commission</span> model.
                            <br /><br />

                            This <span style={{ fontWeight: "bold" }}>lowers</span> the cost of doing business and <span style={{ fontWeight: "bold" }}>improves customer experience</span>, resulting in more revenue and profit. */}
                <Trans>
                  We{' '}
                  <span>
                    <strong>disrupt</strong>
                  </span>{' '}
                  traditional sales, distribution channels with a true{' '}
                  <span>
                    <strong>marketplace</strong>
                  </span>{' '}
                  for technology products.
                  <br />
                  <br />
                  This{' '}
                  <span>
                    <strong>enables brands</strong>
                  </span>{' '}
                  to stay in{' '}
                  <span>
                    <strong>control</strong>
                  </span>{' '}
                  of their{' '}
                  <span>
                    <strong>brand</strong>
                  </span>{' '}
                  stories and ensure their unique selling points are
                  communicated properly. We market their products and allow them{' '}
                  <span>
                    <strong>direct customer access</strong>
                  </span>{' '}
                  through direct marketing and sales.
                  <br />
                  <br />
                  We welcome all{' '}
                  <span>
                    <strong>sales reps</strong>
                  </span>{' '}
                  to take on any brands and{' '}
                  <span>
                    <strong>compensate</strong>
                  </span>{' '}
                  them through a clearly structured{' '}
                  <span>
                    <strong>tiered commission</strong>
                  </span>{' '}
                  model.
                  <br />
                  <br />
                  This{' '}
                  <span>
                    <strong>lowers</strong>
                  </span>{' '}
                  the cost of doing business and{' '}
                  <span>
                    <strong>improves customer experience</strong>
                  </span>
                  , resulting in more revenue and profit.
                </Trans>
              </Typography>
            </Grid>
            {/* Picture */}
            <Grid
              item
              xs={12}
              md={5}
              container
              justifyContent="center"
              alignItems={{ xs: 'flex-end', md: 'flex-start' }}
            >
              <Box
                component="img"
                sx={{
                  height: '100%',
                  maxHeight: { xs: 250, md: 600 },
                  maxWidth: { xs: 250, md: 600 },
                }}
                alt={t('alt.our_vision')}
                src={mission}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* The Environment */}
        <Grid item container alignItems="flex-start" direction="column">
          <Grid
            container
            className="intu__divider"
            spacing={2}
            textAlign="center"
            alignItems={{ md: 'flex-start' }}
            direction={{ xs: 'column', md: 'row' }}
            sx={{ padding: '0 3rem 0 3rem' }}
          >
            <Grid item xs={12}>
              <Typography variant="h2" color="primary" align="center">
                {t('environment_h2')}
              </Typography>
              <br />
              <Typography variant="body" align="center">
                {t('environment_body')}
              </Typography>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={0} md={2}></Grid>
              <Grid item xs={12} md={8}>
                <MapChartContext
                  data={marketSizeChart.data}
                  chartId="marketSizeChart"
                  description={marketSizeChart.description}
                  mobileView={mobileView}
                />
              </Grid>
              <Grid item xs={0} md={2}></Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Players*/}
        <Grid
          item
          container
          alignItems="flex-start"
          direction="column"
          sx={{
            padding: { xs: '10px 0px 50px 0px', md: '50px 0px 50px 20px' },
          }}
        >
          <Grid
            container
            className="intu__divider"
            spacing={2}
            alignItems={{ md: 'flex-start' }}
            direction={{ xs: 'column', md: 'row' }}
            sx={{ padding: '0 2rem 0 2rem' }}
          >
            <Grid item xs={12}>
              <Typography variant="h2" color="primary" align="center">
                {t('players_h2')}
              </Typography>
            </Grid>
            {/* Chart */}
            <Grid item xs={12}>
              <ForceDirectedTreeContext
                description={marketPlayers.description}
                data={marketPlayers.data}
                chartId={marketPlayers.id}
                mobileView={mobileView}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Our vs. Current Channels*/}
        <Grid
          item
          container
          alignItems="flex-start"
          direction="column"
          className="intu__divider"
          sx={{
            margin: '2rem 0 2rem 0',
            padding: '0px 2rem 0 2rem',
          }}
        >
          <Grid
            container
            className="intu__divider"
            spacing={2}
            alignItems={{ md: 'flex-start' }}
            direction={{ xs: 'column', md: 'row' }}
            sx={{ padding: '0px 2rem 0 2rem' }}
          >
            <Grid item xs={12}>
              <Typography variant="h2" color="primary" align="center">
                {t('channel_h2')}
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography variant="body" align="center">
                <Trans i18nKey="context.general.investor.lead_context.channel_body" />
              </Typography>
            </Grid>
            {/* Current */}
            <Grid item container xs={12} md={6}>
              <TreeChartContext
                title={traditionalChannelDrillDown?.title}
                titleColor={traditionalChannelDrillDown?.titleColor}
                titleBackgroundColor={
                  traditionalChannelDrillDown?.titleBackgroundColor
                }
                description={traditionalChannelDrillDown?.description}
                data={traditionalChannelDrillDown?.data}
                chartId={traditionalChannelDrillDown?.id}
                labelColor={theme.typography.body1.color}
              />
            </Grid>
            <Grid item container xs={12} md={6}>
              <TreeChartContext
                title={ourSalesChannelDrillDown?.title}
                titleColor={ourSalesChannelDrillDown?.titleColor}
                titleBackgroundColor={
                  ourSalesChannelDrillDown?.titleBackgroundColor
                }
                description={ourSalesChannelDrillDown?.description}
                data={ourSalesChannelDrillDown?.data}
                chartId={ourSalesChannelDrillDown?.id}
                labelColor={theme.typography.body1.color}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Sales & Reward Model */}
        <Grid item container alignItems="flex-start" direction="column">
          <Grid
            container
            className="intu__divider"
            spacing={2}
            alignItems={{ md: 'flex-start' }}
            direction={{ xs: 'column', md: 'row' }}
          >
            <Grid item xs={12}>
              <Typography variant="h2" color="primary" align="center">
                {t('reward_model_h2')}
              </Typography>
            </Grid>

            {/* Sales Commission */}
            <Grid item xs={12}>
              {/* Subtitle */}
              <Grid item xs={12}>
                <Typography variant="h3" color="tertiary" align="center">
                  {t('sales_commission_h3')}
                </Typography>
              </Grid>
              {/* Description & Calculator */}
              <Grid
                item
                xs={12}
                container
                direction="column"
                spacing={2}
                sx={{ margin: '2rem 0 2rem 0', padding: '0 2rem 0 2rem' }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  direction="column"
                  textAlign="center"
                >
                  <Grid item>
                    <Typography variant="body" paragraph>
                      {t('sales_commission_body1')}
                    </Typography>

                    <Typography variant="body" paragraph>
                      {mobileView
                        ? i18n.t(
                            'context.general.investor.lead_context.sales_commission_body2_mobile',
                          )
                        : i18n.t(
                            'context.general.investor.lead_context.sales_commission_body2',
                          )}
                      .
                    </Typography>
                    <Typography variant="body" paragraph>
                      {t('sales_commission_body3')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Box
                        component="img"
                        sx={{
                          height: '30%',
                          maxHeight: { xs: 250, md: 350 },
                          maxWidth: { xs: 250, md: 350 },
                          display: 'block',
                          mx: 'auto', // Centers horizontally inside its container
                        }}
                        alt={t('alt.commission_calculator')}
                        src={commission}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Commission Calculator */}
                <Grid item xs={12} container>
                  <Grid item xs={1} md={2}></Grid>
                  <Grid item xs={10} md={8}>
                    <InvestorCommissionCalculator />
                  </Grid>
                  <Grid item xs={1} md={2}></Grid>
                </Grid>
              </Grid>

              {/* Advertising */}
              <Grid
                item
                xs={12}
                container
                direction="column"
                spacing={2}
                sx={{ marginTop: '2rem 0 2rem 0', padding: '0 2rem 0 2rem' }}
              >
                {/* Subtitle */}
                <Grid item xs={12}>
                  <Typography variant="h3" color="tertiary" align="center">
                    {t('advertising_h2')}
                  </Typography>
                </Grid>
                {/* Text */}
                <Grid item xs={12} container spacing={3} direction="column">
                  {/* Description */}
                  <Grid
                    item
                    xs={12}
                    container
                    direction="column"
                    spacing={2}
                    sx={{ marginTop: '20px' }}
                  >
                    <Grid
                      item
                      xs={12}
                      container
                      direction="column"
                      textAlign="center"
                    >
                      <Typography variant="body" paragraph>
                        {t('advertising_body1')}
                      </Typography>
                      <Typography variant="body" paragraph>
                        {t('advertising_body2')}
                      </Typography>
                      <Typography variant="body" paragraph>
                        {t('advertising_body3')}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* Advertising */}
                  <Grid item xs={12}>
                    <Box
                      component="img"
                      sx={{
                        width: '100%', // Takes full width of the container
                        height: 'auto',
                        maxWidth: { xs: 350, md: 600 }, // Won't exceed these maximum width values
                        maxHeight: { xs: 157, md: 270 }, // Won't exceed these maximum height values
                        display: 'block',
                        mx: 'auto', // Centers horizontally inside its container
                      }}
                      alt={t('alt.advertising')}
                      src={AdCampaigns}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Data & Reporting */}
              <Grid
                item
                xs={12}
                container
                direction="column"
                spacing={2}
                sx={{ margin: '2rem 0rem 2rem 0rem', padding: '0 2rem 0 2rem' }}
              >
                {/* Subtitle */}
                <Grid item xs={12}>
                  <Typography variant="h3" color="tertiary" align="center">
                    {t('data_report_h3')}
                  </Typography>
                </Grid>
                {/* Text */}
                <Grid item xs={12}>
                  {/* Description */}
                  <Grid
                    item
                    xs={12}
                    container
                    direction="column"
                    textAlign="center"
                  >
                    <Grid item>
                      <Typography variant="body" paragraph>
                        <Trans i18nKey="context.general.investor.lead_context.data_report_body" />
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* Reporting Dashboard*/}
                  <Grid item xs={12}>
                    <Box
                      component="img"
                      sx={{
                        width: '50%',
                        maxWidth: { xs: 350, md: 600 },
                        display: 'block',
                        mx: 'auto', // Centers horizontally inside its container
                      }}
                      alt={t('alt.reporting')}
                      src={Reporting}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Potential Revenue Streams */}
        <Grid
          item
          container
          alignItems="flex-start"
          direction={{ xs: 'column', md: 'row' }}
          sx={{
            margin: '2rem 0 2rem 0',
            padding: '0px 2rem 0 2rem',
          }}
        >
          <Grid
            container
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
            sx={{ padding: '0 2rme 0 2rem' }}
          >
            <Grid item xs={12}>
              <Typography variant="h2" color="primary" textAlign="center">
                {t('potential_revenue.h1')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/* Description & Calculator */}
              <Grid item xs={12} direction="column" container spacing={5}>
                <Grid
                  item
                  xs={12}
                  container
                  direction="column"
                  textAlign="center"
                >
                  <Grid item>
                    <Typography variant="body" paragraph>
                      <Trans i18nKey="context.general.investor.lead_context.potential_revenue.body" />
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={10}>
                      <PieChartContext
                        description={potentialRevenueStreams?.description}
                        data={potentialRevenueStreams?.data}
                        chartId={potentialRevenueStreams?.id}
                        mobileView={mobileView}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Commission Calculator */}
                <Grid item xs={12} container>
                  <Grid item xs={1} md={2}></Grid>
                  <Grid item xs={9} md={8}>
                    <RevenueCalculator />
                  </Grid>
                  <Grid item xs={1} md={2}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* About the Founders */}
        <Grid
          item
          container
          alignItems="flex-start"
          direction={{ xs: 'column', md: 'row' }}
          sx={{
            margin: '2rem 0 2rem 0',
            padding: '0px 2rem 0 2rem',
          }}
        >
          <Grid container spacing={2} direction={{ xs: 'column', md: 'row' }}>
            <Grid item xs={12}>
              <Typography variant="h2" color="primary" textAlign="center">
                {t('founders.h2')}
              </Typography>
            </Grid>
            {/* Team */}
            <Grid
              item
              container
              spacing={10}
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              xs={12}
            >
              {/* Mathias */}
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                xs={12}
                md={4}
              >
                {/* Title */}
                <Typography variant="h5">
                  <Trans i18nKey="context.general.investor.lead_context.founders.mathias_title" />
                </Typography>
                {/* Avatar */}
                <Grid item sx={{ paddingBottom: '20px' }}>
                  <Avatar
                    alt="Mathias Krostewitz"
                    src={mathiasKrostewitz}
                    sx={{
                      width: 300,
                      height: 300,
                      border: `0.1px solid ${intuMiddleGrey}`,
                    }}
                  />
                </Grid>
                {/* About */}
                <Grid item>
                  <Typography variant="body">
                    {/* The man with the vision and a passion for coding! For over a decade, he's been at the helm of some cool industrial automation projects, <br />
                                    steering small and medium businesses. But here's the twist: sensing the challenges these businesses face, <br /><br />
                                    Mat powered up his tech skills with a full-stack (MERN) course at the prestigious MIT. And voilà, IN2TEC was born – his brainchild to revolutionize the industry! */}
                    <Trans i18nKey="context.general.investor.lead_context.founders.mathias_about" />
                  </Typography>
                </Grid>
              </Grid>
              {/* Phil */}
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                xs={12}
                md={4}
              >
                {/* Title */}
                <Typography variant="h5">
                  <Trans i18nKey="context.general.investor.lead_context.founders.phil_title" />
                </Typography>
                {/* Avatar */}
                <Grid item sx={{ paddingBottom: '20px' }}>
                  <Avatar
                    alt="Phil Oh"
                    src={philOh}
                    sx={{
                      width: 300,
                      height: 300,
                      border: `0.1px solid ${intuMiddleGrey}`,
                    }}
                  />
                </Grid>
                {/* About */}
                <Grid item xs={12}>
                  <Typography variant="body">
                    <Trans i18nKey="context.general.investor.lead_context.founders.phil_about" />
                  </Typography>
                </Grid>
              </Grid>
              {/* Edgar */}
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                xs={12}
                md={4}
              >
                {/* Title */}
                <Typography variant="h5">
                  <Trans i18nKey="context.general.investor.lead_context.founders.edgar_title" />
                </Typography>
                {/* Avatar */}
                <Grid item sx={{ paddingBottom: '20px' }}>
                  <Avatar
                    alt="Edgar Castillo"
                    src={edgarCastillo}
                    sx={{
                      width: 300,
                      height: 300,
                      border: `0.1px solid ${intuMiddleGrey}`,
                    }}
                  />
                </Grid>
                {/* About */}
                <Grid item>
                  <Typography variant="body">
                    <Trans i18nKey="context.general.investor.lead_context.founders.edgar_about" />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ paddingBottom: '10rem' }}></Grid>
      </Grid>
    </>
  );
};

export { InvestorContent };
