import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  IconButton,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Close } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';

const UploadImageDialog = ({
  isOpen,
  handleConfirm,
  handleClose,
  isSaving,
}) => {
  const { t } = useTranslation();
  const [image, setImage] = useState(null);
  const [imageType, setImageType] = useState(null);

  const handleConfirmClick = () => {
    if (!image || !imageType) return null;
    handleConfirm(image, imageType);
    setImage(null);
    setImageType(null);
  };

  const handleCloseClick = () => {
    setImage(null);
    setImageType(null);
    handleClose();
  };

  const handleImageUploadClick = (e) => {
    const [file] = e.target.files;
    if (!file) return null;
    const fileType = file.name.substring(
      file.name.lastIndexOf('.') + 1,
      file.name.length,
    );
    setImage(file);
    setImageType(fileType);
    e.target.value = null;
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImageType(null);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ textTransform: 'none' }}>
        Upload Product Images (.stp, .png)
      </DialogTitle>
      <DialogContent style={{ paddingTop: '20px', display: 'flex' }}>
        <FormControl fullWidth>
          <input
            hidden
            accept=".STEP,.stp,.png,.gltf"
            id="product-new-image-upload"
            type="file"
            onChange={handleImageUploadClick}
          />
          <label htmlFor="product-new-image-upload">
            <Button
              component="span"
              variant="contained"
              startIcon={<FileUploadIcon />}
              // disabled={productImages.length > 10}
              disabled={isSaving}
              sx={{ textTransform: 'none', ml: 4 }}
            >
              {t('buttons.upload_img')}
            </Button>
          </label>
        </FormControl>
        {image ? (
          <Box display="flex">
            <Typography>{image.name}</Typography>
            <IconButton onClick={handleRemoveImage}>
              <Close color="error" />
            </IconButton>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions
        style={{ paddingBottom: '16px', justifyContent: 'space-evenly' }}
      >
        <Button
          onClick={handleCloseClick}
          color="secondary"
          variant="contained"
          sx={{ textTransform: 'none' }}
        >
          {t('buttons.close')}
        </Button>
        <LoadingButton
          loading={isSaving}
          loadingPosition="end"
          endIcon={<SendIcon />}
          onClick={handleConfirmClick}
          color="primary"
          type="submit"
          variant="contained"
          disabled={!image || !imageType}
          sx={{ textTransform: 'none' }}
        >
          {t('buttons.confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UploadImageDialog;
