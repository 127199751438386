import { useState, useRef, useEffect, createContext, useContext } from 'react';
import useUser from '../../hooks/useUser.js';
import { useAnalyticsPageViewTracker } from '../../hooks/useAnalyticsTracker.jsx';
import axios from '../../hooks/axios/useAxios.js';
import CircularProgress from '@mui/material/CircularProgress';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Materail UI
import {
  Grid,
  Stack,
  FormControl,
  Button,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Container,
} from '@mui/material';
import { classes } from '../../settings/theme.js';

// Translator
import { useTranslation } from 'react-i18next';

// Response & Loading Handlers
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { useNavigate } from 'react-router-dom';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';

// Subscriber Context
const SubscriberContext = createContext();

// NEWSLETTER SUBSCRIBER FORM
const NewsletterSubscribeForm = () => {
  // Subscriber Context
  const {
    setIsLoading,
    mode,
    setMode,
    origin,
    navigate,
    host_url,
    handleErrorResponse,
    handleRegularResponse,
  } = useContext(SubscriberContext);

  // google page view tracking tracking
  const { gaPageViewTracker } = useAnalyticsPageViewTracker();
  gaPageViewTracker({
    type: 'pageview',
    page: document.location.pathname,
    name: 'Contact Us Page!',
  });

  // Translator
  const { t } = useTranslation();

  // Response & Process Handling
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    location: {},
    origin: '',
    sessionID: '',
    captcha: '',
  });
  const [successMessage, setSuccessMessage] = useState({
    status: false,
    title: '',
    text: '',
  });

  // User Information
  const { user } = useUser();

  // Setup Initial Values for Form Validation
  let validationSchema = Yup.object();
  let initialValues = {
    ...userInfo,
  };

  useEffect(() => {
    setUserInfo({
      ...userInfo,
      origin: origin,
      location: user.location,
    });
  }, [user.location]);

  // Validation Schema
  validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(/^(?=.*[a-z]).{2,50}$/, t('validation.name.first_matches'))
      .required(t('validation.name.first_required')),
    lastName: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(/^(?=.*[a-z]).{2,50}$/, t('validation.name.last_matches'))
      .required(t('validation.name.last_required')),
    email: Yup.string()
      .required(t('validation.email.required_alt'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('validation.email.invalid'),
      ),
  });

  // Set Up From refs
  const formRef = useRef(null);

  //handle form submission process
  async function submitForm(values, formik) {
    setIsLoading({
      status: true,
      type: 'spinner',
      text: t('form.message.add_sub_list'),
    });

    const first_name = values.firstName;
    const last_name = values.lastName;
    const email = values.email;
    const origin = values.origin;
    const location = values.location;

    const url = `/api/subscribers/signup`;
    const payload = {
      first_name,
      last_name,
      email,
      origin,
      location,
      host_url,
    };

    formik.isSubmitting = true;

    // Loading Button
    try {
      const response = await axios.post(url, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const { status, data } = response;
      //Handle Response
      if (status === 200) {
        //message sent
        formik.resetForm();
        formik.isSubmitting = false;
        handleRegularResponse({
          open: true,
          status: data.status,
          text: data.message,
        });
      } else if (status === 260) {
        navigate(data.navigate_to, { replace: true, mode: 'confirm' });
        setMode('confirm');
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      } else if (status === 261) {
        // Subscriber already exists
        if (mode === 'signup-component') {
          handleRegularResponse({
            open: true,
            status: 'success',
            message: t('form.message.sub_exists', { email: values.email }),
          });
        } else {
          setSuccessMessage({
            status: true,
            text: t('form.message.sub_exists', { email: values.email }),
          });
        }
      } else {
        // all other responses
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
    }
  }

  return (
    <Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, formik) => submitForm(values, formik)}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={true}
          innerRef={formRef}
        >
          {(formik) => {
            const {
              values,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              isValid,
              isSubmitting,
            } = formik;
            return (
              <>
                {mode !== 'signup-component' && (
                  <>
                    <Grid
                      item
                      className="title section__content_header"
                      xs={12}
                    >
                      <Typography align="center" variant="h1">
                        {t('context.newsletter.signup_title')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography align="center" variant="h5">
                        {t('context.newsletter.signup_content')}
                      </Typography>
                    </Grid>
                  </>
                )}
                {mode === 'signup-page' && successMessage.status === true ? (
                  <Grid container textAlign="center">
                    <Typography variant="body">
                      {successMessage.text}
                    </Typography>
                  </Grid>
                ) : (
                  <Form
                    className="form-horizontal"
                    role="form"
                    onSubmit={handleSubmit}
                  >
                    <FormControl fullWidth margin="dense">
                      {/* First & Last Name */}
                      <Stack spacing={2} sx={{ paddingBottom: '50px' }}>
                        <Stack
                          spacing={1}
                          direction={{ xs: 'column', sm: 'row' }}
                          style={classes.root}
                        >
                          <TextField
                            required
                            style={classes.root}
                            id="firstName"
                            name="firstName"
                            autoComplete="off"
                            className="form-select-field"
                            aria-invalid={errors.firstName ? 'true' : 'false'}
                            aria-describedby="uidnote"
                            variant="outlined"
                            label={t('form.first_name')}
                            type="text"
                            placeholder={t('form.first_name')}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value);
                            }}
                            InputLabelProps={{
                              style: {
                                color: mode === 'signup-component' && 'white',
                              },
                            }}
                            inputProps={{
                              style: {
                                color: mode === 'signup-component' && 'white',
                                textTransform: 'capitalize',
                              },
                              autoComplete: 'given-name',
                            }}
                            onBlur={handleBlur}
                            value={values?.firstName}
                            error={
                              errors?.firstName && touched?.firstName
                                ? true
                                : false
                            }
                            helperText={
                              errors?.firstName && touched?.firstName
                                ? errors?.firstName
                                : null
                            }
                          />
                          <TextField
                            required
                            id="lastName"
                            name="lastName"
                            autoComplete="off"
                            className="form-select-field"
                            aria-invalid={errors.lastName ? 'true' : 'false'}
                            aria-describedby="uidnote"
                            variant="outlined"
                            label={t('form.last_name')}
                            type="text"
                            placeholder={t('form.last_name')}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value);
                            }}
                            InputLabelProps={{
                              style: {
                                color: mode === 'signup-component' && 'white',
                              },
                            }}
                            inputProps={{
                              style: {
                                color: mode === 'signup-component' && 'white',
                                textTransform: 'capitalize',
                              },
                              autoComplete: 'family-name',
                            }}
                            onBlur={handleBlur}
                            value={values?.lastName}
                            error={
                              errors?.lastName && touched?.lastName
                                ? true
                                : false
                            }
                            helperText={
                              errors?.lastName && touched?.lastName
                                ? errors?.lastName
                                : null
                            }
                          />
                        </Stack>

                        {/* Email */}
                        <TextField
                          required
                          id="email"
                          name="email"
                          autoComplete="off"
                          className="form-select-field"
                          aria-invalid={errors.email ? 'true' : 'false'}
                          aria-describedby="uidnote"
                          variant="outlined"
                          label={t('form.email.label_alt')}
                          type="text"
                          placeholder={t('form.email.placeholder')}
                          onChange={(e) => {
                            setFieldValue(e.target.name, e.target.value);
                          }}
                          InputLabelProps={{
                            style: {
                              color: mode === 'signup-component' && 'white',
                            },
                          }}
                          inputProps={{
                            style: {
                              color: mode === 'signup-component' && 'white',
                            },
                            autoComplete: 'off',
                          }}
                          onBlur={handleBlur}
                          value={values?.email}
                          error={errors?.email && touched?.email ? true : false}
                          helperText={
                            errors?.email && touched?.email
                              ? errors?.email
                              : null
                          }
                        />
                      </Stack>

                      {/* CTA */}
                      <Stack
                        spacing={4}
                        direction="column"
                        style={classes.root}
                        sx={{ paddingBottom: '50px' }}
                      >
                        {/* CAPTCHA */}
                        {/* <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                                            onChange={(token) => {
                                                setFieldValue('captcha', token)
                                            }}
                                        /> */}

                        {/* Submit Button */}
                        {mode === 'signup-component' && !isValid ? null : (
                          <Button
                            variant="contained"
                            type="submit"
                            disabled={!isValid || isSubmitting}
                          >
                            {isSubmitting ? (
                              <CircularProgress size={24} color="inherit" />
                            ) : (
                              t('buttons.sign_up')
                            )}
                          </Button>
                        )}
                      </Stack>
                    </FormControl>
                  </Form>
                )}
              </>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

const ConfirmSubscriberform = () => {
  const params = new URLSearchParams(window.location.search);
  const email = params.get('userID');
  const otp = params.get('userOTP');

  // Get Context
  const {
    setIsLoading,
    setMode,
    host_url,
    gaEventTracker,
    navigate,
    handleErrorResponse,
    handleRegularResponse,
  } = useContext(SubscriberContext);

  const { t } = useTranslation();
  const { t: transDialog } = useTranslation('dialogs');

  let validationSchema = Yup.object();
  let initialValues = {
    otp,
    email,
  };

  validationSchema = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('validation.email.invalid'),
      ),
    otp: Yup.string()
      .required(t('validation.otp.required'))
      .min(4, t('validation.short'))
      .max(4, t('validation.long')),
  });

  // confirm email
  async function submitForm(values, formik) {
    setIsLoading({
      status: true,
      type: 'spinner',
      text: t('form.message.sub_confirm'),
    });

    const url = `/api/subscribers/confirm`;
    const payload = {
      email: values.email,
      otp: values.otp,
      host_url,
    };

    formik.isSubmitting = true;

    // Loading Button
    try {
      const request = await axios.post(url, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const response = request?.data;

      if (response) {
        //Handle Response
        if (request.status === 200) {
          //message sent
          formik.resetForm();
          formik.isSubmitting = false;
          handleRegularResponse({
            open: true,
            status: 'success',
            message: t('form.message.all_set'),
          });
          gaEventTracker({
            category: 'Newsletter',
            action: 'new_subscriber',
            label: 'New Subscriber',
            value: 2,
          });
          navigate(response.navigate_to, { replace: true, mode: 'profile' });
          setMode('profile');
        } else {
          // all other responses
          handleRegularResponse({
            open: true,
            status: response.status,
            message: response.message,
          });
        }
      }
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
    }
  }

  return (
    <Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, formik) => submitForm(values, formik)}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => {
            const {
              values,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              isValid,
            } = formik;
            return (
              <>
                <Grid item className="title section__content_header" xs={12}>
                  <Typography align="center" variant="h1">
                    {transDialog('ConfirmSubscriptionDialog.title')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" variant="h5">
                    {transDialog('ConfirmSubscriptionDialog.description')}
                  </Typography>
                </Grid>
                <Form
                  className="form-horizontal"
                  role="form"
                  onSubmit={handleSubmit}
                >
                  <FormControl fullWidth margin="dense">
                    {/* First & Last Name */}
                    <Stack spacing={2} sx={{ paddingBottom: '50px' }}>
                      <Stack
                        spacing={2}
                        direction={{ xs: 'column' }}
                        style={classes.root}
                      >
                        {/* User ID */}
                        <TextField
                          required
                          id="email"
                          name="email"
                          autoComplete="off"
                          className="form-select-field"
                          aria-invalid={errors.email ? 'true' : 'false'}
                          aria-describedby="uidnote"
                          variant="outlined"
                          label={t('form.email.label_alt')}
                          type="text"
                          placeholder={t('form.email.placeholder')}
                          onChange={(e) => {
                            setFieldValue(e.target.name, e.target.value);
                          }}
                          inputProps={{
                            autoComplete: 'off',
                          }}
                          onBlur={handleBlur}
                          value={values?.email}
                          error={errors?.email && touched?.email ? true : false}
                          helperText={
                            errors?.email && touched?.email
                              ? errors?.email
                              : null
                          }
                        />

                        {/* USER OTP */}
                        <TextField
                          required
                          style={classes.root}
                          id="otp"
                          name="otp"
                          className="form-select-field"
                          autoComplete="off"
                          aria-invalid={errors.otp ? 'true' : 'false'}
                          aria-describedby="uidnote"
                          variant="outlined"
                          label={t('form.otp.label')}
                          type="password"
                          placeholder={t('form.otp.placeholder_email')}
                          onChange={(e) => {
                            setFieldValue(e.target.name, e.target.value);
                          }}
                          inputProps={{
                            autoComplete: 'one-time-code',
                          }}
                          onBlur={handleBlur}
                          value={values?.otp}
                          error={errors?.otp && touched?.otp ? true : false}
                          helperText={
                            errors?.otp && touched?.otp ? errors?.otp : null
                          }
                        />
                      </Stack>
                    </Stack>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!isValid}
                    >
                      {t('buttons.confirm_sub')}
                    </Button>
                  </FormControl>
                </Form>
              </>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

const PreLoadContext = (props) => {
  return (
    <Container maxWidth="auto" className="intu__section">
      <Grid
        container
        className="intu__section intu__page_content"
        spacing={4}
        alignContent="center"
        direction="column"
        sx={{
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} textAlign="center">
          <Typography variant="h1">{props.text}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const SubscriberProfileForm = () => {
  const params = new URLSearchParams(window.location.search);
  const email = params.get('subscriberID');
  const otp = params.get('subscriberOTP');

  // Get Context
  const {
    setIsLoading,
    setMode,
    isLoading,
    host_url,
    gaEventTracker,
    navigate,
    handleErrorResponse,
    handleRegularResponse,
  } = useContext(SubscriberContext);

  // set subscriber info
  const [subscriberInfo, setSubscriberInfo] = useState({
    email: '',
    mailing_lists: [],
  });

  // Mailing List Options
  const [mailingListOptions, setMailingListOptions] = useState([]);

  let validationSchema = Yup.object();
  let initialValues = {
    ...subscriberInfo,
  };

  const { t } = useTranslation();

  validationSchema = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('validation.email.invalid'),
      ),
    first_name: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
        t('validation.name.first_matches'),
      )
      .required(t('validation.name.first_required')),
    last_name: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
        t('validation.name.last_matches'),
      )
      .required(t('validation.name.last_required')),
  });

  useEffect(() => {
    // Get Mailing list options
    async function getMailingLists() {
      const url = `/api/newsletters/list`;
      const controller = new AbortController();
      const signal = controller.signal;

      try {
        const request = await axios.get(url, { signal });

        const response = request?.data;

        if (request.status === 200) {
          if (response.data.length > 0) {
            setMailingListOptions(response.data);

            // Add lists to initial Values
            let listOptions = response.data;
            setSubscriberInfo({
              ...subscriberInfo,
              mailing_lists: listOptions,
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    // Get User Info
    async function getSubscriberInfo() {
      const url = `/api/subscribers/profile`;
      const payload = {
        email,
        otp,
        host_url,
      };

      try {
        const request = await axios.post(url, JSON.stringify(payload), {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        const response = request?.data;
        if (response) {
          //Handle Response
          if (request.status === 200) {
            setSubscriberInfo(response.data);
          } else if (request.status === 263) {
            setMode('confirm');
            navigate(response.navigate_to, { replace: true, mode: 'confirm' });
          } else if (request.status === 262) {
            setMode('signup-page');
            navigate(response.navigate_to, {
              replace: true,
              mode: 'signup-page',
            });
          } else {
            // all other responses
            handleRegularResponse({
              open: true,
              status: response.status,
              message: response.message,
            });
          }
        }
      } catch (err) {
        handleErrorResponse(err);
      }
    }

    async function handlePreLoadForm() {
      setIsLoading({
        status: true,
        type: 'spinner',
        text: t('form.message.load_sub_profile'),
      });
      await getMailingLists();
      await getSubscriberInfo();
      setIsLoading({ status: false, type: 'spinner', text: '' });
    }
    handlePreLoadForm();
  }, []);

  // update subscriber profile
  async function submitForm(values, formik) {
    setIsLoading({
      status: true,
      type: 'spinner',
      text: t('form.message.update_sub_profile'),
    });
    const url = `/api/subscribers/update`;
    const payload = values;

    formik.isSubmitting = true;

    // Loading Button
    try {
      const request = await axios.post(url, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const response = request?.data;

      if (response) {
        //Handle Response
        if (request.status === 200) {
          //message sent
          formik.isSubmitting = false;
          handleRegularResponse({
            open: true,
            status: response.status,
            message: response.message,
          });
          gaEventTracker({
            category: 'Newsletter',
            action: 'subscriber_update',
            label: 'Subscriber Setttings Updated',
            value: 1,
          });
        } else {
          // all other responses
          handleRegularResponse({
            open: true,
            status: response.status,
            message: response.message,
          });
        }
      }
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
    }
  }

  return (
    <>
      {isLoading.status === false ? (
        <Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, formik) => submitForm(values, formik)}
              validateOnMount={true}
              validateOnChange={true}
              enableReinitialize={true}
            >
              {(formik) => {
                const {
                  values,
                  setFieldValue,
                  handleSubmit,
                  errors,
                  touched,
                  handleBlur,
                  isValid,
                } = formik;
                return (
                  <>
                    <Grid
                      item
                      className="title section__content_header"
                      xs={12}
                    >
                      <Typography align="center" variant="h1">
                        {t('context.newsletter.profile_title')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography align="center" variant="h5">
                        {t('context.newsletter.profile_content')}
                      </Typography>
                    </Grid>
                    <Form
                      className="form-horizontal"
                      role="form"
                      onSubmit={handleSubmit}
                    >
                      <FormControl fullWidth margin="dense">
                        {/* Subscription Settings */}
                        <Stack spacing={2} sx={{ paddingBottom: '50px' }}>
                          <Stack
                            spacing={2}
                            direction={{ xs: 'column' }}
                            style={classes.root}
                          >
                            <Stack
                              spacing={1}
                              direction={{ xs: 'column', sm: 'row' }}
                              style={classes.root}
                            >
                              <TextField
                                required
                                style={classes.root}
                                id="first_name"
                                name="first_name"
                                autoComplete="off"
                                className="form-select-field"
                                aria-invalid={
                                  errors.first_name ? 'true' : 'false'
                                }
                                aria-describedby="uidnote"
                                variant="outlined"
                                label={t('form.first_name')}
                                type="text"
                                placeholder={t('form.first_name')}
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.value);
                                }}
                                inputProps={{
                                  autoComplete: 'given-name',
                                }}
                                onBlur={handleBlur}
                                value={values?.first_name}
                                error={
                                  errors?.first_name && touched?.first_name
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors?.first_name && touched?.first_name
                                    ? errors?.first_name
                                    : null
                                }
                              />
                              <TextField
                                required
                                id="last_name"
                                name="last_name"
                                autoComplete="off"
                                className="form-select-field"
                                aria-invalid={
                                  errors.lastName ? 'true' : 'false'
                                }
                                aria-describedby="uidnote"
                                variant="outlined"
                                label={t('form.last_name')}
                                type="text"
                                placeholder={t('form.last_name')}
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.value);
                                }}
                                inputProps={{
                                  autoComplete: 'family-name',
                                }}
                                onBlur={handleBlur}
                                value={values?.last_name}
                                error={
                                  errors?.last_name && touched?.last_name
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors?.last_name && touched?.last_name
                                    ? errors?.last_name
                                    : null
                                }
                              />
                            </Stack>

                            {/* User ID */}
                            <TextField
                              required
                              id="email"
                              name="email"
                              autoComplete="off"
                              className="form-select-field"
                              aria-invalid={errors.email ? 'true' : 'false'}
                              aria-describedby="uidnote"
                              variant="outlined"
                              label={t('form.email.label_alt')}
                              type="text"
                              placeholder={t('form.email.placeholder')}
                              onChange={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                              }}
                              inputProps={{
                                autoComplete: 'off',
                              }}
                              onBlur={handleBlur}
                              value={values?.email}
                              error={
                                errors?.email && touched?.email ? true : false
                              }
                              helperText={
                                errors?.email && touched?.email
                                  ? errors?.email
                                  : null
                              }
                            />

                            {/* Subscription Settings */}
                            <FormGroup>
                              {mailingListOptions.map((item, index) => (
                                <FormControlLabel
                                  id={item._id}
                                  key={item._id}
                                  control={
                                    <Checkbox
                                      id={item._id}
                                      name={item.name}
                                      checked={
                                        values.mailing_lists.find(
                                          (list) => list._id === item._id,
                                        )?.subscribed || false
                                      }
                                      onChange={(e) => {
                                        const updatedList =
                                          values.mailing_lists.map((list) => {
                                            // Check if the current list item is the one being updated
                                            if (list._id === e.target.id) {
                                              return {
                                                ...list,
                                                subscribed: e.target.checked,
                                              };
                                            }
                                            return list;
                                          });

                                        // Check if the updated list already contains the item being updated
                                        const isExisting =
                                          values.mailing_lists.some(
                                            (list) => list._id === e.target.id,
                                          );

                                        // If the item doesn't exist, add it to the array
                                        if (!isExisting) {
                                          updatedList.push({
                                            _id: e.target.id,
                                            name: e.target.name,
                                            subscribed: e.target.checked,
                                          });
                                        }

                                        setFieldValue(
                                          'mailing_lists',
                                          updatedList,
                                        );
                                      }}
                                    />
                                  }
                                  label={item.label}
                                />
                              ))}
                            </FormGroup>
                          </Stack>
                        </Stack>
                        <Grid>
                          <Button
                            variant="contained"
                            type="submit"
                            disabled={!isValid || !formik.dirty}
                          >
                            {t('buttons.update_sub_profile')}
                          </Button>
                        </Grid>
                      </FormControl>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      ) : (
        <PreLoadContext title={t('context.newsletter.load_profile')} />
      )}
    </>
  );
};

const SubscriberContent = (props) => {
  const [dialog, setDialog] = useState({ open: false, title: '', content: '' });
  const [mode, setMode] = useState(props?.mode || 'signup-component');
  const origin = props?.source || 'direct';

  // Get Host
  const host_url = window.location.host;

  // Navigate
  const navigate = useNavigate();

  // Get Response & Ststus Handler
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { LoadingSpinner, isLoading, setIsLoading } = useProcessingHandler();

  return (
    <SubscriberContext.Provider
      value={{
        mode,
        setMode,
        origin,
        dialog,
        setDialog,
        host_url,
        navigate,
        handleErrorResponse,
        handleRegularResponse,
        LoadingSpinner,
        isLoading,
        setIsLoading,
      }}
    >
      {mode === 'signup-component' || mode === 'signup-page' ? (
        <NewsletterSubscribeForm />
      ) : (
        <Grid component="main" sx={{ marginBottom: '50px' }}>
          {mode === 'signup-page' ? (
            <NewsletterSubscribeForm />
          ) : mode === 'confirm' ? (
            <ConfirmSubscriberform />
          ) : (
            mode === 'profile' && <SubscriberProfileForm />
          )}
        </Grid>
      )}
    </SubscriberContext.Provider>
  );
};

export { SubscriberContent };
