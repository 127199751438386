import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { StyledNumberField } from './ProductForm';

export const formatAttributeValue = (attributeValue) => {
  if (
    typeof attributeValue === 'string' ||
    typeof attributeValue === 'number'
  ) {
    return attributeValue;
  }

  if (Array.isArray(attributeValue)) {
    // let str = '';
    // attributeValue.forEach((v, i) => {
    //   const key = Object.keys(v)[0];
    //   const value = v[key];
    //   if (i === attributeValue.length - 1) {
    //     str += `${key}${value}`;
    //     return;
    //   }
    //   str += `${key} ${value}, `;
    // });
    // return str;
    const [key] = Object.keys(attributeValue[0]);
    const value = attributeValue[0][key];
    return `${key} - ${value}`;
  }
};

const AttributeValueBox = ({
  options,
  selectedAttributeType,
  selectedAttribute,
  handleAttributeValueSelect,
  selectedAttributeValue,
  handleNewAttributeConfirm,
}) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');

  const handleSelectAttributeValue = (attributeValue) => {
    handleAttributeValueSelect(attributeValue);
  };

  const handleConfirm = (newAttributeValue) => {
    handleNewAttributeConfirm(
      selectedAttributeType,
      selectedAttribute,
      newAttributeValue,
    );
  };

  const attributeValues = useMemo(() => {
    if (!options.length || !selectedAttributeType || !selectedAttribute)
      return [];

    return options
      .filter(
        (option) =>
          option.attribute_type === selectedAttributeType &&
          option.attribute === selectedAttribute,
      )
      .map((option) => option.value);
  }, [options, selectedAttributeType, selectedAttribute]);

  const isNew = useMemo(() => {
    if (options.length && options[options.length - 1].value === null)
      return true;
    return false;
  }, [options]);

  const searchedValues = useMemo(() => {
    if (!searchValue.length || isNew) return attributeValues;
    else return attributeValues.filter((a) => String(a).includes(searchValue));
  }, [attributeValues, searchValue, isNew]);

  // console.log('searched values', searchedValues);

  const handleNewAttribute = () => {
    const selectedAttribute = searchedValues.find(
      (value) => value === searchValue,
    );
    // console.log('setting it to', selectedAttribute || searchValue);
    handleAttributeValueSelect(selectedAttribute || searchValue);
    setSearchValue('');
  };

  useEffect(() => {
    setSearchValue('');
  }, [selectedAttribute]);

  const handleSearchInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleNewAttribute();
    }
  };

  const [measurementValue, setMeasurementValue] = useState(0);
  const [measurementUnitValue, setMeasurementUnitValue] = useState('');

  const handleNewMeasurement = () => {
    handleAttributeValueSelect([
      {
        [measurementUnitValue]: measurementValue,
      },
    ]);
  };

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {attributeValues.length ? (
        <>
          <Typography variant="h6" textTransform="none" p={0} mb={2}>
            Attribute Value
          </Typography>
          {selectedAttributeType === 'measurement' ? (
            <Box display="flex" flexDirection="column">
              <Box display="flex">
                <FormControl>
                  <StyledNumberField
                    id="new-product-list-price-field"
                    label="Attribute Value"
                    name="height"
                    type="number"
                    variant="outlined"
                    value={measurementValue}
                    onChange={(e) => setMeasurementValue(e.target.value)}
                    // onBlur={formik.handleBlur}
                    // error={formik.errors.list_price && formik.touched.list_price}
                    // helperText={
                    //   formik.errors.list_price && formik.touched.list_price
                    //     ? formik.errors.list_price
                    //     : ''
                    // }
                  />
                </FormControl>
                <FormControl sx={{ minWidth: 70 }}>
                  <InputLabel id="unit-select">Unit</InputLabel>
                  <Select
                    label="Unit"
                    labelId="unit-select"
                    id="select"
                    value={measurementUnitValue}
                    onChange={(e) => setMeasurementUnitValue(e.target.value)}
                    name="unit"
                    defaultValue="in"
                    sx={{ mb: 2 }}
                  >
                    <MenuItem value="in">in</MenuItem>
                    <MenuItem value="cm">cm</MenuItem>
                    <MenuItem value="lb">lb</MenuItem>
                    <MenuItem value="kg">kg</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <ListItem
                component="button"
                onClick={handleNewMeasurement}
                sx={{
                  // background: isSelected
                  //   ? theme.palette.secondary.main
                  //   : theme.palette.primary.main,
                  background: theme.palette.primary.main,
                  border: 'unset',
                  borderRadius: '10px',
                  boxShadow: theme.shadows[6],
                  margin: '10px 0',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  padding: '4px 0',
                  transform: 'translateY(-2px)',
                  transition: 'all 0.15s ease-in-out',
                  // '&:hover': {
                  //   background: theme.palette.secondary.main,
                  //   transform: isSelected
                  //     ? 'translateY(0px)'
                  //     : 'translateY(-4px)',
                  // },
                  '&:active': {
                    background: theme.palette.secondary.main,
                    transform: 'translateY(0px)',
                  },
                }}
              >
                <ListItemText primary={'Add New Value'} />
              </ListItem>
            </Box>
          ) : (
            <>
              <TextField
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={handleSearchInputKeyDown}
                placeholder={`${isNew ? 'Add' : 'Search for'} an attribute value`}
                tabIndex={0}
              />
              <List sx={{ flex: 1 }}>
                {!isNew &&
                  searchedValues.map((attributeValue) => {
                    const isSelected =
                      selectedAttributeValue === attributeValue;
                    return (
                      <ListItem
                        key={attributeValue}
                        component="button"
                        onClick={() =>
                          handleSelectAttributeValue(attributeValue)
                        }
                        // onMouseDown={() => handleClick(attributeType)}
                        sx={{
                          background: isSelected
                            ? theme.palette.secondary.main
                            : theme.palette.primary.main,
                          border: 'unset',
                          borderRadius: '10px',
                          boxShadow: theme.shadows[6],
                          margin: '10px 0',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          textAlign: 'center',
                          padding: '4px 0',
                          transform: isSelected
                            ? 'translateY(0px)'
                            : 'translateY(-2px)',
                          transition: 'all 0.15s ease-in-out',
                          '&:hover': {
                            background: theme.palette.secondary.main,
                            transform: isSelected
                              ? 'translateY(0px)'
                              : 'translateY(-4px)',
                          },
                          '&:active': {
                            background: theme.palette.secondary.main,
                            transform: 'translateY(0px)',
                          },
                        }}
                      >
                        <ListItemText
                          primary={formatAttributeValue(attributeValue)}
                        />
                      </ListItem>
                    );
                  })}
                {!searchedValues.length || isNew ? (
                  <ListItem
                    component="button"
                    onClick={handleNewAttribute}
                    sx={{
                      // background: isSelected
                      //   ? theme.palette.secondary.main
                      //   : theme.palette.primary.main,
                      background: theme.palette.primary.main,
                      border: 'unset',
                      borderRadius: '10px',
                      boxShadow: theme.shadows[6],
                      margin: '10px 0',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      padding: '4px 0',
                      transform: 'translateY(-2px)',
                      transition: 'all 0.15s ease-in-out',
                      // '&:hover': {
                      //   background: theme.palette.secondary.main,
                      //   transform: isSelected
                      //     ? 'translateY(0px)'
                      //     : 'translateY(-4px)',
                      // },
                      '&:active': {
                        background: theme.palette.secondary.main,
                        transform: 'translateY(0px)',
                      },
                    }}
                  >
                    <ListItemText primary={'Add New Value'} />
                  </ListItem>
                ) : null}
              </List>
            </>
          )}
        </>
      ) : null}
    </Box>
  );
};

export default AttributeValueBox;
