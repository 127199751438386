import { useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Select,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { StyledNumberField } from './ProductForm';

const FulfillmentBox = ({ product, setProduct }) => {
  const { dimensions, weight, lead_time } = product;
  const [isShowing, setIsShowing] = useState(false);
  const handleDimensionChange = (e) => {
    setProduct((prevState) => {
      return {
        ...prevState,
        dimensions: {
          ...prevState.dimensions,
          [e.target.name]: e.target.value,
        },
      };
    });
  };

  const handleWeightChange = (e) => {
    setProduct((prevState) => {
      return {
        ...prevState,
        weight: {
          ...prevState.weight,
          [e.target.name]: e.target.value,
        },
      };
    });
  };

  const handleLeadTimeChange = (e) => {
    setProduct((prevState) => {
      return {
        ...prevState,
        lead_time: Number(e.target.value),
      };
    });
  };

  const handleCheckboxChange = () => {
    setIsShowing(!isShowing);
    setProduct((prevState) => {
      return {
        ...prevState,
        stock_with: !prevState.stock_with,
      };
    });
  };

  return (
    <>
      <Typography variant="h4" textTransform="none">
        Fulfillment
      </Typography>
      <Box>
        <Box>
          <FormControlLabel
            control={<Checkbox />}
            label="Stock the Product with IN2TEC for just 0.048 US$ per month"
            onChange={handleCheckboxChange}
          />
        </Box>
        {isShowing ? (
          <>
            <Typography mb={2}>Packaging Dimensions</Typography>
            <FormControl sx={{ mr: 2 }}>
              <StyledNumberField
                id="new-product-list-price-field"
                label="Length"
                name="length"
                type="number"
                variant="outlined"
                onChange={handleDimensionChange}
                value={dimensions.length}
                // onBlur={formik.handleBlur}
                // error={formik.errors.list_price && formik.touched.list_price}
                // helperText={
                //   formik.errors.list_price && formik.touched.list_price
                //     ? formik.errors.list_price
                //     : ''
                // }
              />
            </FormControl>
            <FormControl sx={{ mr: 2 }}>
              <StyledNumberField
                id="new-product-list-price-field"
                label="Width"
                name="width"
                type="number"
                variant="outlined"
                onChange={handleDimensionChange}
                value={dimensions.width}
                // onBlur={formik.handleBlur}
                // error={formik.errors.list_price && formik.touched.list_price}
                // helperText={
                //   formik.errors.list_price && formik.touched.list_price
                //     ? formik.errors.list_price
                //     : ''
                // }
              />
            </FormControl>
            <FormControl sx={{ mr: 2 }}>
              <StyledNumberField
                id="new-product-list-price-field"
                label="Height"
                name="height"
                type="number"
                variant="outlined"
                onChange={handleDimensionChange}
                value={dimensions.height}
                // onBlur={formik.handleBlur}
                // error={formik.errors.list_price && formik.touched.list_price}
                // helperText={
                //   formik.errors.list_price && formik.touched.list_price
                //     ? formik.errors.list_price
                //     : ''
                // }
              />
            </FormControl>
            <FormControl sx={{ mr: 2 }}>
              <InputLabel id="unit-select">Unit</InputLabel>
              <Select
                label="Unit"
                labelId="unit-select"
                id="select"
                value={dimensions.unit}
                onChange={handleWeightChange}
                name="unit"
                // label="Type"
                defaultValue="in"
                sx={{ mb: 2 }}
              >
                <MenuItem value="in">
                  {/* {t('components.commission.tiered')} */}
                  in
                </MenuItem>
                <MenuItem value="cm">cm</MenuItem>
              </Select>
            </FormControl>
            <Box>
              <Typography mb={2}>Gross Weight of Product</Typography>
              <FormControl sx={{ mr: 2 }}>
                <StyledNumberField
                  id="new-product-list-price-field"
                  label="Weight"
                  name="weight"
                  type="number"
                  variant="outlined"
                  onChange={handleWeightChange}
                  value={weight.weight}
                  // onBlur={formik.handleBlur}
                  // error={formik.errors.list_price && formik.touched.list_price}
                  // helperText={
                  //   formik.errors.list_price && formik.touched.list_price
                  //     ? formik.errors.list_price
                  //     : ''
                  // }
                />
              </FormControl>
              <FormControl sx={{ mr: 2 }}>
                <InputLabel id="unit-select">Unit</InputLabel>
                <Select
                  label="Unit"
                  labelId="unit-select"
                  id="select"
                  value={weight.unit}
                  onChange={handleWeightChange}
                  name="weight-unit"
                  // label="Type"
                  defaultValue="lb"
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="lb">
                    {/* {t('components.commission.tiered')} */}
                    lb
                  </MenuItem>
                  <MenuItem value="kg">kg</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Typography>Lead Time</Typography>
              <FormControl sx={{ my: 2, mr: 2 }}>
                <StyledNumberField
                  id="new-product-list-price-field"
                  label="Enter number of days"
                  name="lead_time"
                  type="number"
                  variant="outlined"
                  onChange={handleLeadTimeChange}
                  value={lead_time}
                  // onBlur={formik.handleBlur}
                  // error={formik.errors.list_price && formik.touched.list_price}
                  // helperText={
                  //   formik.errors.list_price && formik.touched.list_price
                  //     ? formik.errors.list_price
                  //     : ''
                  // }
                />
              </FormControl>
              <FormControl sx={{ my: 2, mr: 2 }}>
                <InputLabel id="unit-select">Days</InputLabel>
                {/* <Select
                  label="Unit"
                  labelId="unit-select"
                  id="select"
                  value={weight.unit}
                  onChange={handleWeightChange}
                  name="weight-unit"
                  defaultValue="lb"
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="lb">lb</MenuItem>
                  <MenuItem value="kg">kg</MenuItem>
                </Select> */}
              </FormControl>
            </Box>
          </>
        ) : null}
      </Box>
    </>
  );
};

export default FulfillmentBox;
